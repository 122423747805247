import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import EAMInputNumber from "../eamInputNumber";
import styles from './styles.module.scss';
import PerformanceDatePicker from '../performanceDatePicker'
import { PerformanceInput } from '../PerformanceInput';
import { dateformatList } from '../../types/constants';
import { connect } from 'react-redux';
import KeyListenPure from '../KeyListener/index'
import classNames from 'classnames';
function DyanmicTypeInput({
    theme,
    dataType,
    onChange,
    onFocus,
    height,
    defaultValue,
    borderless
}, ref) {
    console.log(ref)
    const [dt, setDt] = useState(dataType?.toLowerCase());
    const inputRef = useRef(null);
    const isfocusedRef = useRef(false);
    const isFocused = useState(false);
    useEffect(() => {
        setDt(dataType?.toLowerCase());
    }, [dataType])
    useImperativeHandle(ref, () => ({
        changeDataType: (dataType) => {
            setDt(dataType?.toLowerCase());
        },
        focus: () => {
            isfocusedRef.current = true;
            isFocused[1](true);
            inputRef.current?.focus?.();
        },
        blur: () => {
            isfocusedRef.current = false;
            isFocused[1](false);
            inputRef.current?.focus?.();
        }
    }), []);
    const onKeydown = useCallback((e) => {
        e.stopPropagation();
    }, [])
    const onEnterCallback = (value) => {
        if (onChange instanceof Function)
            onChange(value)
    }

    return <div className={classNames(styles.dynamicInput)}>
        <KeyListenPure name={'dateInput'} {...(isfocusedRef.current ? {
            down: {
                downCallBack: onKeydown,
                exceptions: ['Tab', "Enter"],
            }
        } : {})} />
        {
            (dt === "number" ?
                <EAMInputNumber default={defaultValue} mode={'normal'} borderless={borderless} onPressEnter={onEnterCallback} height={height} onFocus={onFocus} ref={inputRef} theme={theme} />
                : (dt === 'date' ?
                    <PerformanceDatePicker defaultValue={defaultValue} onChange={onEnterCallback} format={dateformatList} borderless={borderless} height={height} onFocus={onFocus} ref={inputRef} theme={theme} />
                    : (dt === 'string' ?
                        < PerformanceInput defaultValue={defaultValue} onEnter={onEnterCallback} borderless={borderless} height={height} onFocus={onFocus} ref={inputRef} theme={theme} />
                        :
                        <span>{null}</span>)
                )
            )
        }
    </div>
}
const mapStateToProps = state => {
    return {
        theme: state.appState.theme,
    };
}
export default connect(mapStateToProps, null, null, {
    forwardRef: true
})(forwardRef(DyanmicTypeInput));
