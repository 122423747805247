import React from 'react';
import { Select } from 'antd';
import { uuidv4 } from '../types/helper';
import styles from './selectWrapper.module.scss';
import classNames from 'classnames';
import KeyListen from './KeyListener'
import { optionalCallExpression } from '@babel/types';
/**
 * @typedef ComponentProps
 * @prop {(value:string,option:Object)=>undefined} onSelect
 * @prop {Array.<SelectOptions>} options
 * @extends {React.Component<ComponentProps}
 */
class selectWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownState: false,
            options: this.props.options.filter(x => x),
            value: undefined
        };
        this.id = `nvtableSelect_${uuidv4()}`;
        this.selectRef = React.createRef();
        this.bindFunctions();
        this.KeyListenProps = {
            down: { exceptions: [], downCallBack: this.onKeyDown },
            up: { exceptions: [], upCallBack: this.onKeyDown },
        }
    }
    componentDidMount() {
        this.disableTabindex();
    }
    componentDidUpdate(prevProps) {
        if (this.props.options !== prevProps.options) {
            this.setState({
                options: this.props.options.filter(x => x)
            });
        }
    }
    disableTabindex() {
        let elem = document.querySelector(`#${this.id} .ant-select-selection-search-input`)
        if (elem)
            elem.setAttribute("tabindex", "-1");
    }
    bindFunctions() {
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.focus = this.focus.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.blur = this.blur.bind(this);
    }
    /**
     * @param {Array.<SelectOptions>} options
     */
    setOptions(options, defaultValue) {
        this.setState({
            options: options.filter(x => x),
            value: defaultValue
        })
    }
    onFocus() {
        this?.props?.onFocus instanceof Function && this?.props?.onFocus();
        this.focus();
    }
    onBlur() {
        this.blur();
    }
    focus() {
        this.setState({
            dropDownState: true
        })
        setTimeout(() => {
            this.selectRef?.current?.focus?.()
        });
    }
    blur() {
        this.setState({
            dropDownState: false
        })
        // setTimeout(() => 
        this.selectRef?.current?.blur?.()
        // );

    }
    onSelect(value, option) {
        this.setState({ value });
        this.props.onSelect instanceof Function && this.props.onSelect(value, option);
    }
    onKeyDown(e) {
        if (!this.state.dropDownState)
            return;
        if (e.key === "ArrowUp" || e.key === "ArrowDown"){
            e?.stopPropagation();
        }
        else if (e.key === 'Enter') {
            e?.stopPropagation();
            // this.blur();
        }
        else if (e.key === 'Escape') {
            this.blur();
            e?.stopPropagation();
        }
    }
    render() {
        return (
            <div id={this.id} className={classNames({
                [styles.selectWrapper]: true,
                [styles.selected]: this.state.dropDownState,
                [styles.darkTheme]: true
            })} tabIndex={"-1"}>
                <KeyListen down={this.state.dropDownState ? this.KeyListenProps.down : {}} up={this.state.dropDownState ? this.KeyListenProps.up : {}}/>
                <Select onSelect={this.onSelect} defaultValue={this.props.defaultValue} value={this.state.value} placeholder={this.props.placeholder} listItemHeight={this.props.listItemHeight} listHeight={this.props.listHeight} showArrow={false} bordered={this.props.bordered !== false} onFocus={this.onFocus} onBlur={this.onBlur} open={this.state.dropDownState} ref={this.selectRef} style={{ width: this.props.width, fontSize: this.props.fontSize }} dropdownStyle={{ width: this.props.width, fontSize: this.props.fontSize }} size={this.props.size}>
                    {
                        this.state.options.map((x, i) => {
                            return (
                                <Select.Option className={classNames({
                                    [styles.selectWrapperItem]: true,
                                    [styles.default]: this.props.listSize === undefined,
                                    [styles.smaller]: this.props.listSize === 'smaller',
                                })} key={x.key || i} value={x.label}>
                                    {
                                        (x.customRender instanceof Function
                                            ?
                                            x.customRender()
                                            :
                                            <div>{x.label || x.key}</div>
                                        )
                                    }
                                </Select.Option>
                            );
                        })
                    }
                </Select>
            </div>
        );
    }
}

export default selectWrapper;

/**
 * @typedef SelectOptions
 * @property {string} key
 * @property {string} label
 * @property {()=>React.ReactElement} [customRender]
 */