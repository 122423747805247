import React from 'react';
import { uuidv4 } from '../types/helper'

export class ReactMutaionWatcher extends React.Component {
    constructor(props) {
        super();
        this.guid = '_rmw' + uuidv4();
        this.node = undefined;
    }
    componentDidMount() {
        if (this.props.mutationObserver && this.props.connect) {
            this.node = document.getElementById(this.guid);
            if (this.node) {
                this.props.mutationObserver.observe(this.node, this.props.options);
            }
        }
    }
    componentDidUpdate() {
        if (this.props.mutationObserver && this.props.connect) {
            if (this.node) {
                this.props.mutationObserver.observe(this.node, this.props.options);
            }
        }
    }
    render() {
        return (<div id={this.guid}>
            {this.props.children}
        </div>);
    }
}