import { connect } from "react-redux";
import React from 'react';
import { Select, Spin, Divider, Button } from 'antd';
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons"
import { selectedSubCompanyUpdate } from '../redux/actions';
import { subCompanyStateFactory } from '../redux/reducer'
import Axios, { } from 'axios';
import error from '../assets/error.png';
import styles from './SubCompanySelector.module.css';
const classNames = require('classnames');
/**
 * 
 * @param {import('../redux/store').appMasterStare} state 
 * @returns {componentState}
 */
const mapStateToProps = state => {
    return {
        theme: state.appState.theme,
        locale: state.appState.locale,
        selectedCompany: state.masterState.cinfo.selectedCompany,
        subCompany: state.masterState.subCompany,
        apiHost: state.appState.apiHost,
        token: state.appState.userState.jwt
    };
}
const mapDispatchToProps = dispatch => {
    return {
        updateSelectedSubcompany: scinfo => dispatch(selectedSubCompanyUpdate(scinfo))
    };
}
/**
 * @typedef componentState
 * @prop {import('../types/theme').darkTheme} theme
 * @prop {string}  locale
 * @prop {string}  apiHost
 * @prop {import('../redux/reducer').subCompanyState} subCompany
 * @prop {import('../redux/reducer').EAMCompany} selectedCompany
 * @prop {function(import('../redux/reducer').subCompanyState):void} updateSelectedSubcompany
 * @prop {(string)=>void} [onSelect]
 * @prop {string} [header]
 * @prop {Left|Right|Center} [headerPosition]
 * @prop {boolean} [isHeaderHidden]
 * @extends {React.Component<componentState>}
 */
class SubcompanySelector extends React.Component {
    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.keyDown = this.keyDown.bind(this);
        this.SelectRef = React.createRef()
        this.state = { isOpen: false };
    }
    async componentDidUpdate(prevProps) {
        if (!this.props.subCompany.dataFetched && !this.props.subCompany.list) {
            await this.getData();
        }
        if (prevProps.defaultValue !== this.props.defaultValue) {

            console.log(this.SelectRef.current);
        }
    }

    async getData() {
        if (!this.props.selectedCompany)
            return;
        let scinfo = [];
        try {
            let resp = await Axios.get(`${this.props.apiHost}/collections/scinfo?cinfo=${this.props.selectedCompany.key}`, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            if (resp.status === 200)
                scinfo = resp.data.responseData.scinfo;
        } catch (err) {
            this.props.updateSelectedSubcompany(subCompanyStateFactory(undefined, true, true, err.response.status + ': ' + err.response.statusText));
            return;
        }
        this.props.updateSelectedSubcompany(subCompanyStateFactory(scinfo, true, false, ''));
    }
    getChildren() {
        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        if (this.props.subCompany.list || this.props.subCompany.dataFetched) {
            if (!this.props.subCompany.fetchError) {
                return this.props.subCompany.list.map(scinfo => (
                    <Select.Option key={scinfo.subCompanyCode} value={scinfo.subCompanyName}>
                        {scinfo.subCompanyName}
                    </Select.Option>));
            } else {
                return (
                    <Select.Option disabled key='error'>
                        <div style={
                            {
                                height: 'auto',
                                display: 'block',
                                textAlign: 'center'

                            }
                        } className={classNames(styles.eamCompanySpin, styles.ecsiFetching, styles[this.props.theme.className])}>
                            <img src={error} style={
                                {
                                    display: 'block',
                                    height: '100px',
                                    width: '100px',
                                    textAlign: 'center',
                                    margin: 'auto'
                                }
                            } />
                            {this.props.subCompany.fetchErrorMsg}
                        </div>
                    </Select.Option>
                );
            }
        } else if (!this.props.selectedCompany) {
            return (
                <Select.Option disabled key='error'>
                    <div style={
                        {
                            height: 'auto',
                            display: 'block',
                            textAlign: 'center'
                        }
                    } className={classNames(styles.eamCustomerSpin, styles.ecsiFetching, styles[this.props.theme.className])}>
                        <img src={error} style={
                            {
                                display: 'block',
                                height: '100px',
                                width: '100px',
                                textAlign: 'center',
                                margin: 'auto'
                            }
                        } />
                        Select a Company
                    </div>
                </Select.Option>);

        } else {
            return <Select.Option value='loading' disabled={true}>
                <div className={classNames(styles.eamCompanySpin, styles.ecsiFetching, styles[this.props.theme.className])}>
                    <Spin indicator={antIcon} />
                    <p>Fetching Sub Company List</p>
                </div>
            </Select.Option>
        }
    }
    getDropDown() {
        if (this.props.subCompany.list || this.props.subCompany.dataFetched) {
            return (menu => (
                <div className={classNames(styles.eamCompanySelectItem, styles[this.props.theme.className])}>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                        onMouseDown={e => e.preventDefault()}
                        onClick={this.addItem}
                    >
                        <Button onClick={this.getData} >
                            <ReloadOutlined />
                            Refresh Sub Company List
                        </Button>
                    </div>
                </div>
            ));
        } else
            return undefined;
    }
    focus() {
        if (this.SelectRef.current)
            this.SelectRef.current.focus();
    }
    DropDownOpen(isOpen) {
        this.setState({ isOpen }, () => {
            if (isOpen)
                setTimeout(() => this.focus());
        });
    }
    selectChange(selected, option) {
        if (this.props.onSelect) {
            this.props.onSelect({
                label: option.props.label,
                key: selected
            }, option);
        }
        this.DropDownOpen(false);
    }
    keyDown(e) {
        e.stopPropagation();
        if (e.key == 'Escape' || e.key == 'Tab')
            this.DropDownOpen(false);
        if (e.key == 'Enter' && !this.state.isOpen)
            this.DropDownOpen(true);
        if (this.props.onKeyPress) {
            this.props.onKeyPress(e);
        }
    }
    getheaderPositionClassName() {
        if (this.props.headerPosition === 'Right') {
            return "right";
        } else if (this.props.headerPosition === 'center') {
            return "center";
        } else {
            return "left";
        }

    }
    render() {
        return (
            <div onBlur={() => {

                this.DropDownOpen(false)
            }
            } style={
                {
                    textAlign: "left"
                }}>
                <div className={classNames(styles.title, this.getheaderPositionClassName(), this.props.isHeaderHidden ? 'hidden' : null)}>
                    <h3>{this.props.header || "Sub Company"}</h3>
                </div>
                <Select key={this.props.defaultValue || '1'} defaultValue={this.props.defaultValue && this.props.subCompany?.list?.find(x => x.subCompanyCode === this.props.defaultValue)?.subCompanyName} open={this.state.isOpen} ref={this.SelectRef} onKeyDown={this.keyDown} onFocus={() => this.DropDownOpen(true)} optionLabelProp='label' disabled={this.props.disabled} value={this.props.disabled ? this.props.defaultDispalyValue : undefined} onSelect={this.selectChange} dropdownClassName={classNames(styles.eamSelectItem, styles[this.props.theme.className])} className={classNames(styles.eamSelect, styles[this.props.theme.className])} placeholder='Select a Sub Company'
                    dropdownRender={
                        this.getDropDown()
                    }>
                    {
                        this.getChildren()
                    }
                </Select >
            </div>);
    }
}
const EAMSubcompanySelector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SubcompanySelector);

export default EAMSubcompanySelector;

