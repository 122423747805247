import React from 'react';
import { Input } from 'antd';
import { uuidv4 } from '../types/helper'
import styles from './PerformanceInput.module.css';

/**
 * @typedef componentProperty
 * @prop {string} theme
 * @prop {(value:string)=>void} [onChange]
 * @prop {(value:string)=>void} [onEnter]
 * @prop {string} [defaultValue]
 * @prop {boolean} [loading]
 * @prop {boolean | string} [enterbutton]
 * @prop {"Search"|"Normal"} [mode]
 * @prop {number} [width]
 * @prop {boolean} [disabled]
 * @prop {boolean} [readOnly]
 * @prop {(e:KeyboardEvent)=>void} [onKeyPress]
 * @extends {React.Component<componentProperty}
 */
export class PerformanceInput extends React.Component {
    constructor(props) {
        super(props);
        this.guid = 'id-'+uuidv4();
        this.onChange = this.onChange.bind(this);
        this.inputRef = React.createRef();
    }
    onChange(e) {
        e.stopPropagation();
        if (e.type === 'change') {
            if (this.props.onChange) {
                this.props.onChange(e.target.value);
            }
        }
        else {
            if (this.props.onEnter) {
                this.props.onEnter(e.target.value);
            }
        }
    }
    focus(select) {
        try {
            if (this.inputRef.current) {
                this.inputRef.current.focus();
                if (select) {
                    this.select();
                }
            }
        } catch {
        }
    }
    select() {
        let cssSelector = `#${this.guid} .ant-input-wrapper input`;
        let input = document.querySelector(cssSelector);
        if (input)
            input.select();
    }
    onKeyPress(e){
        if(this.props.onKeyPress){
            this.props.onKeyPress(e);
        }
    }
    render() {
        return (
            <div id={this.guid} className={styles[this.props.theme]} onKeyDown={(e)=>this.onKeyPress(e)}>
                {this.props.mode === "Search" ?
                    <Input.Search style={{
                        width: this.props.width || "30em"
                    }
                    } disabled={this.props.disabled} onFocus={this.props.onFocus} width={this.props.width} onPressEnter={this.onChange} ref={this.inputRef} loading={this.props.loading} enterButton={this.props.enterbutton} defaultValue={this.props.defaultValue} onChange={this.onChange} readOnly={this.props.readOnly} />
                    :
                    <Input onFocus={this.props.onFocus} disabled={this.props.disabled} width={this.props.width} onPressEnter={this.onChange} ref={this.inputRef} loading={this.props.loading} enterButton={this.props.enterbutton} defaultValue={this.props.defaultValue} onChange={this.onChange} readOnly={this.props.readOnly} />
                }
            </div>
        );
    }
}