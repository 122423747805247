export const actionsList = {
    apiServerStatusUpdate: 'SERVER_STATUS',
    userStateUpdate: 'USER_STATE_UPDATE',
    cinfoUpdate: 'CINFO_REFRESH',
    selectedCompanyUpdate: 'CINFO_SELECTED',
    selectedCustomerUpdate: 'CUSTOMER_SELECTOR_STATE_UPDATE',
    selectedSubcompanyUpdate: 'SCINFO_SELECTED',
    appDateUpdate: 'APP_DATE_UPDATE',
    appStartDateUpdate: 'APP_START_DATE_UPDATE',
    appStartDateAndAppDateUpdate: 'APP_START_END_DATE_UPDATE'
};
/**
 * @param {any} payload 
 * @returns {import('./reducer').action}
 */
export function apiServerStatusUpdate(payload) {
    return { type: actionsList.apiServerStatusUpdate, payload: payload };
}
/**
 * @param {any} payload 
 * @returns {import('./reducer').action}
 */
export function userStateUpdate(payload) {
    return { type: actionsList.userStateUpdate, payload: payload };
}
/**
 * @param {any} payload 
 * @returns {import('./reducer').action}
 */
export function cinfoUpdate(payload) {
    return { type: actionsList.cinfoUpdate, payload: payload };
}
/**
 * @param {any} payload 
 * @returns {import('./reducer').action}
 */
export function selectedCompanyUpdate(payload) {
    return {
        type: actionsList.selectedCompanyUpdate,
        payload
    };
}
/**
 * @param {any} payload 
 * @returns {import('./reducer').action}
 */
export function selectedSubCompanyUpdate(payload) {
    return {
        type: actionsList.selectedSubcompanyUpdate,
        payload
    };
}
/**
 * @param {any} payload 
 * @returns {import('./reducer').action}
 */
export function selectedCustomerUpdate(payload) {
    return {
        type: actionsList.selectedCustomerUpdate,
        payload
    };
}

/**
 * 
 * @param {Date} payload 
 *  @returns {import('./reducer').action}
 */
export function updateAppDateState(payload) {
    return {
        type: actionsList.appDateUpdate,
        payload
    };
}

/**
 * 
 * @param {Date} payload 
 *  @returns {import('./reducer').action}
 */
export function updateAppStartDateState(payload) {
    return {
        type: actionsList.appStartDateUpdate,
        payload
    };
}
/**
 * 
 * @param {Date} startDate 
 * @param {Date} appDate
 *  @returns {import('./reducer').action}
 */
export function updateAppStartAndAppDateState(startDate, appDate) {
    return {
        type: actionsList.appStartDateAndAppDateUpdate,
        payload: {
            startDate,
            appDate
        }
    };
}