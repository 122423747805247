import { Provider } from "react-redux";
import React from 'react';
import { Route, Switch, Redirect, withRouter, BrowserRouter } from 'react-router-dom';
import AppHeader from './components/appHeader';
import { ErrorBoundary } from "./components/pages/errorBoundry";
import EAMAuthentication from "./components/EAMAuthentication";
import { EAMPayment } from './components/EAMpayments.jsx';
import './App.scss';
import store from './redux/store';
import E404 from "./components/pages/e404";
import PaymentsDisplay from "./components/pages/paymentsdisplay";
import OutStandingsDisplay from "./components/pages/outstandings";
import KeyListenProvider from './components/KeyListener/Provider'
function App() {
  return (
    <Provider store={store}>
      <KeyListenProvider>
        <ErrorBoundary>
          <BrowserRouter>
            <div id='root' className={'darkTheme'}>
              <AppHeader />
              <EAMAuthentication />
              <div id='content'>
                <Switch>
                  <Route exact path='/' component={PaymentsDisplay} />
                  <Route exact path='/outstandings' component={OutStandingsDisplay} />
                  <Route exact path='/new' render={(props) => <EAMPayment new {...props} />} />
                  <Route path='/view' render={(props) => {
                    return (
                      <EAMPayment {...props} />);
                  }
                  }
                  />
                  <Route exact path='/404' component={E404} />
                  <Route path='*'>
                    <Redirect to='/404' />
                  </Route>
                </Switch>
              </div>
            </div>
          </BrowserRouter>
        </ErrorBoundary>
      </KeyListenProvider>
    </Provider>
  );
}

export default App;
