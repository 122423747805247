import React from 'react';
import { Modal } from 'antd'
import styles from './FilterInterface.module.scss';
import { getComputedStyle } from '../types/helper'
import classNames from 'classnames';
import SelectWrapper from './selectWrapper';
import Nt from './navigatableTable';
import { connect } from 'react-redux';
import * as Icons from '@ant-design/icons';
import KeyListenPure from './KeyListener';
import DyanmicTypeInput from './dynamicTypeInput';
import { ConsoleSqlOutlined } from '@ant-design/icons';
const mapStateToProps = state => {
    return {
        theme: state.appState.theme,
        locale: state.appState.locale,
    };
}
const ANTMODALPADDING = 24;
const ROW_HEIGHT = getComputedStyle("1.666rem").w;
const FILTER_CONDITIONS = {
    "string": {
        "Contains": undefined,
        "Not Contains": undefined,
        "Begins with": undefined,
        "Not begin with": undefined,
        "Ends with": undefined,
        "Not end with": undefined,
    },
    "number": {
        "Greater than": undefined,
        "Greater than or equal to": undefined,
        "Less than ": undefined,
        "Less than or equal to": undefined,
        "Equal to": undefined,
        "Not equal to": undefined,
    },
    "date": {
        "isAfter": undefined,
        "isSame or After": undefined,
        "isBefore": undefined,
        "isSame or Before": undefined,
        "isSame": undefined,
    }
}
class FilterInterface extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visibility: false,
            data: [{
                "emptyrow-nt": true
            }],
            height: 200,
            width: 800,
        };

        this.refCollection = {
            expressionRef: React.createRef(),
            tableRef: React.createRef()
        }
        this.numberRow = (
            <div className={classNames(styles.sortRow)}>
                <SelectWrapper bordered={false} innerRef={this.refCollection.expressionRef} width={200} options={[{ value: "Greater than >=", index: 0 }, { value: "Less than >=", index: 1 }, { value: "Not Equal >=", index: 2 }]} />
            </div>);
        this.bindFunctions();
        this.keyLisenProps = {
            down:
                { downKeys: ['f4', 'escape'], downCallBack: this.onKeyDown },
            up: {}
        }
    }
    bindFunctions() {
        this.getFilterColumns = this.getFilterColumns.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.getspin = this.getspin.bind(this);
    }
    addField(colName, record, value) {
        record[colName] = value;
        record["emptyrow-nt"] = false;
    }

    getFilterColumns() {
        return [
            {
                title: "Columns",
                render: (text, record, extra, refCollection, tableColumns) => {
                    refCollection.selectRef = React.createRef();
                    refCollection.innerRef = refCollection.selectRef;
                    return (<SelectWrapper
                        onFocus={() => {
                            refCollection.tableRef.selectCell(extra.rowindex, extra.columnIndex, false);
                        }}
                        onSelect={(value, selectoption) => {
                            let column = this.props.fields[selectoption.key];
                            if (column) {
                                this.addField("column", record, column)
                                this.addField("condition", record, undefined)
                                let conditions = FILTER_CONDITIONS[column?.dataType?.toLowerCase() || "string"];
                                let conditionCellRef = refCollection.tableRef?.getCellRefs(extra.rowindex, extra.columnIndex + 1);
                                let valueCellRef = refCollection.tableRef?.getCellRefs(extra.rowindex, extra.columnIndex + 2);
                                conditionCellRef.selectRef?.current?.setOptions(Object.keys(conditions).map((x, i) => ({
                                    label: x,
                                    key: i
                                }), undefined));
                                valueCellRef.dynamicInputRef?.current?.changeDataType(column?.dataType?.toLowerCase());
                                refCollection.tableRef.selection("RIGHT", false, true)
                            }
                        }} placeholder={<>Select a Column</>} bordered={false} ref={refCollection.selectRef} width={175} defaultValue={record.column?.title} size={'small'} listSize={'smaller'} uniqueKey={`${extra.rowIndex}-${extra.columnIndex}`} options={this.props.fields.map((x, i) => x.title && ({
                            label: x.title,
                            key: i
                        }))} />);
                },
                dataType: 'text',
                sorter: false,
                width: 200,
            },
            {
                title: "Condition",
                render: (text, record, extra, data, columns) => {
                    data.selectRef = React.createRef();
                    data.innerRef = data.selectRef;
                    let conditions = FILTER_CONDITIONS[record.column?.dataType?.toLowerCase() || "string"];
                    return (<SelectWrapper
                        onFocus={() => {
                            data.tableRef.selectCell(extra.rowindex, extra.columnIndex, false);
                        }}
                        onSelect={(value, selectoption) => {
                            let conditions = FILTER_CONDITIONS[record.column?.dataType?.toLowerCase() || "string"];
                            let condition = {
                                executor: conditions[value],
                                label: value
                            };
                            this.addField("condition", record, condition);
                            data.tableRef.selection("RIGHT", false, true)
                        }} placeholder={record?.column ? <>Select a Condition</> : null} bordered={false} defaultValue={record.condition?.label} ref={data.selectRef} width={175} size={'small'} listSize={'smaller'} uniqueKey={`${extra.rowIndex}-${extra.columnIndex}`} options={Object.keys(conditions).map((x, i) => ({
                            label: x,
                            key: i
                        }))} />);
                },
                dataType: 'text',
                sorter: false,
                width: 200,
            },
            {
                title: "Value",
                render: (text, record, extra, data, columns) => {
                    data.dynamicInputRef = React.createRef();
                    data.innerRef = data.dynamicInputRef;
                    const onChange = (value) => {
                        this.addField("value", record, value);
                        data.tableRef.selection("RIGHT", false, true)
                    };
                    return <DyanmicTypeInput
                        height={ROW_HEIGHT - 50}
                        onFocus={() => {
                            data.tableRef.selectCell(extra.rowindex, extra.columnIndex, false);
                        }} ref={data.dynamicInputRef} defaultValue={record?.value} borderless onChange={onChange} dataType={record?.column?.dataType} />;
                },
                dataType: 'text',
                sorter: false,
                width: 200,
            },
            {
                title: "And | Or",
                dataType: 'text',
                render: (text, record, extra, data, columns) => {
                    data.selectRef = React.createRef();
                    data.innerRef = data.selectRef;
                    let conditions = FILTER_CONDITIONS[record.column?.dataType?.toLowerCase() || "string"];
                    return (<SelectWrapper
                        onFocus={() => {
                            data.tableRef.selectCell(extra.rowindex, extra.columnIndex, false);
                        }}
                        onSelect={(value) => {
                            this.addField("grouping", record, value);
                            if (!this.state?.data[this.state.data?.length - 1]?.["emptyrow-nt"]) {
                                this.setState(s => {
                                    return {
                                        data: [...s.data, {
                                            "emptyrow-nt": true
                                        }]
                                    }
                                })
                            } else {
                                data?.innerRef?.current?.blur();
                                data.tableRef.deHighlightAll();
                                data.tableRef.selectCell((extra.rowindex ?? 0) + 1, 0, false);
                            }
                        }} placeholder={record?.column ? record.grouping : null} bordered={false} ref={data.selectRef} width={100} size={'small'} listSize={'smaller'} uniqueKey={`${extra.rowIndex}-${extra.columnIndex}`} options={["Or", "And"].map((x, i) => ({
                            label: x,
                            key: i
                        }))} />);
                },
                dataType: 'text',
                sorter: false,
            },
        ]
    }
    getspin() {
        let antIcon = <Icons.Loading3QuartersOutlined style={{ fontSize: '5em' }} spin />
        return {
            indicator: antIcon, style: {
                color: this.props.theme.primary1
            }
        };
    }
    get getVisibility() {
        return this.state.visibility;
    }
    makeVisible(isVisible) {
        if (isVisible === undefined || isVisible)
            this.setState({ visibility: true });
        else
            this.setState({ visibility: false });
    }
    handleFocus() {
        if (this.refCollection.expressionRef.current) {
            this.refCollection.expressionRef.current.focus();
        }
    }
    componentDidMount() {
        this.handleFocus();
    }
    componentDidUpdate() {
        this.handleFocus();
    }
    onKeyDown(e) {
        e.stopPropagation();
        if (e.key === 'F4') {
            e.preventDefault();
            this.makeVisible(!this.state.visibility);
        }
        else if (e.key === 'Escape') {
            e.preventDefault();
            this.makeVisible(false);
        }
    }
    render() {
        return (
            <>
                <KeyListenPure down={this.keyLisenProps.down} />
                <Modal bodyStyle={{
                    minHeight: "33.33vh",
                }} className={classNames(styles.root)} centered width={this.state.width} maskClosable={false} visible={this.state.visibility} footer={null} closeIcon={false} closable={false} destroyOnClose={true} keyboard={false}>
                    <Nt ref={this.refCollection.tableRef} columnSelectable loading={this.getspin()} filterInterface={false} scroll={{ y: this.state.height - (ANTMODALPADDING * 2) }} tabIndex={50} onEnter={undefined} onDelete={this.undefined} theme={this.props.theme.className} columns={this.getFilterColumns()} dataSource={this.state.data} pagination={false} rowHeight={ROW_HEIGHT} fontSize={"1.1rem"} columnSelectable={true} dataSource={this.state.data} />
                </Modal>
            </>
        );
    }
}

export default connect(mapStateToProps, undefined, undefined, { forwardRef: true })(FilterInterface);