import React from 'react';
import Pdp from './performanceDatePicker'
import { Modal } from 'antd'
import moment from 'moment';
import { dateformatList } from '../types/constants';
import KeyListenPure from './KeyListener';
import { getDayEnd, getDayStart } from '../types/helper';
/**
 * @typedef properties
 * @prop {string} theme
 * @prop {moment.Moment} maxDate
 * @prop {moment.Moment} minDate
 * @prop {boolean} [disableEndDate]
 * @prop {1|2} mode
 * @prop {()=>void} [onClose]
 * @prop {()=>{}} [getCurrentDate]
 * @prop {(e:React.KeyboardEvent.<any>)=>void} [onKeyDown]
 * @prop {(startDate: Date,endDate: Date )=>void} DateChangeDispatcher
 * @extends {React.Component<properties>}
 */
export default class PeriodSelector extends React.Component {
    constructor(props) {
        super(props);
        this.startRef = React.createRef();
        this.endRef = React.createRef();
        this.state = {
            visibility: false
        };
        this.onKeyDown = this.onKeyDown.bind(this);
        this.keyListenerProps = {
            down: {
                downKeys: ['enter'],
                downCallBack: this.onKeyDown
            },
            up: {
                upKeys: ['escape'],
                upCallBack: this.onKeyDown
            }
        };
    }
    /**
     * 
     * @param {booblean} state 
     * @param {()=>any} cb 
     */
    changeDateModalVisibility(state, cb) {
        if (this.state.visibility === state)
            return;
        this.startDate = this.props.getCurrentDate?.()?.startDate ?? new Date();
        this.startRef.current?.setDate(this.startDate);
        this.endDate = this.props.getCurrentDate?.()?.endDate ?? new Date();
        this.endRef.current?.setDate(this.endDate);
        this.setState({
            visibility: state
        }, () => {
            if (state) {
                if (this.props.mode === 1)
                    this.setfocustoStartDate();
                if (this.props.mode === 2)
                    this.setfocustoEndDate();
            }
            if (!state && this.props.onClose)
                this.props.onClose();
            if (cb)
                cb();
        });
    }
    get modalVisibility() {
        return this.state.visibility;
    }
    setfocustoStartDate = () => {
        if (this.startRef.current) {
            this.startRef.current.focus();
        }
        setTimeout(() => {
            if (this.startRef.current) {
                this.startRef.current.selectDateText();
            }
        }, 100);
    };
    setfocustoEndDate = () => {
        if (this.endRef.current) {
            this.endRef.current.focus();
        }
        setTimeout(() => {
            if (this.endRef.current) {
                this.endRef.current.selectDateText();
            }
        });
    }
    startDate = getDayStart();
    endDate = getDayEnd();
    /**
     * 
     * @param {Date} date 
     */
    selectDate(date) {
        this.endDate = getDayEnd(date);
        if (this.endDate > new Date())
            this.endDate = getDayEnd(new Date());
        if (isNaN(this.endDate))
            return;
        if (!isNaN(+this.startDate)) {
            this.props.DateChangeDispatcher(this.startDate, this.endDate);
            this.changeDateModalVisibility(false);
        }
    }
    selectStartDate(date) {
        this.startDate = getDayStart(date);
        if (isNaN(+date))
            return;
        this.setfocustoEndDate();
    }
    onKeyDown(e) {
        if (e.key.toLowerCase() === 'escape') {
            if (this.state.visibility) {
                this.changeDateModalVisibility(false);
                e.stopPropagation();
                return;
            }
        }
        if (this.props.onKeyDown)
            this.props.onKeyDown(e);
    }
    render() {
        return (<Modal maskClosable={true} visible={this.state.visibility} width={200} centered closeIcon={<div></div>} footer={null} defaultVisibility={false}  >
            <KeyListenPure {...this.keyListenerProps} />
            <div>
                {
                    this.props.mode === 1 ?
                        <div>
                            <Pdp type={'start'} referanceDate={new Date()} minDate={this.props.minDate} maxDate={this.props.maxDate} theme={this.props.theme} ref={this.startRef} className={"periodStartDate"} onChange={(date) => this.selectStartDate(date)} format={dateformatList} defaultValue={this.startDate} />
                            <p style={{
                                fontWeight: "bold",
                                margin: 'auto',
                                textAlign: 'center'

                            }}>
                                To
                            </p>
                        </div>
                        :
                        <p style={{
                            fontWeight: "bold",
                            margin: 'auto',
                            textAlign: 'center',
                            marginBottom: ".5em"
                        }}>As on</p>
                }
                <Pdp type={'end'} referanceDate={new Date()} minDate={this.props.minDate} maxDate={this.props.maxDate} theme={this.props.theme} ref={this.endRef} disabled={this.props.disableEndDate} className={"periodEndDate"} format={dateformatList} onChange={(date) => this.selectDate(date)} defaultValue={this.endDate} />
            </div>
        </Modal>);
    }
}
