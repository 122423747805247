import React from 'react';
import { Popconfirm } from 'antd';
/**
 * @typedef componentState
 * @prop {string} title
 * @prop {any} referanceValue
 * @prop {string} [theme]
 * @prop {callbackFn} [onCancel]
 * @prop {callbackFn} [onConfirm]
 * @prop {string} [okText]
 * @prop {string} [cancelText]
 * @prop {"left"|"right"|"top"|"bottom"} [placement]
 * @extends {React.Component<componentState>}
 */
class ExtendedPopConfirm extends React.Component {

    constructor(props) {
        super(props)
    }
    render() {
        return (<Popconfirm placement={this.props.placement || "top"} onCancel={this.props.onCancel && (() => this.props.onCancel(this.props.referanceValue))} onConfirm={this.props.onConfirm && (() => this.props.onConfirm(this.props.referanceValue))} title={this.props.title} okText={this.props.okText} cancelText={this.props.cancelText} >
            {this.props.children}
        </Popconfirm>);
    }
}

export default ExtendedPopConfirm

/**
 * Declare Callback function type for the Styled button
 * @callback callbackFn
 * @param {Object} [referanceValue]
 * @returns {Promise<void>}
 */
