// @ts-check
import { darkTheme } from '../types/theme';
import { actionsList } from './actions';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { getDayEnd, getDayStart } from '../types/helper';
const appName = 'EAM Books';
let apihost = "http://localhost:3002";
if (process.env.REACT_APP_ENV === 'UAT')
    apihost = "https://col-api-uat.eambusiness.in";
else if (process.env.REACT_APP_ENV === 'PROD')
    apihost = "https://api.sales.eambusiness.in";
export const apiHost = apihost;
const userProfilePicUrl = `${apiHost}/collections/user/photo`;
export const message = {
    APIoffline: appName + ' is Offline',
    loginMessage: 'Kindly login using your office 365 or active directory account to proceed',
    errorMessage: 'Internal Server Error',
    serverOnline: 'Online'
};
/**
 * @type {appState}
 */
const appState = {
    appName,
    apiHost,
    serverState: serverStateFactory(false, message.loginMessage, false),
    userState: userStateStateFactory(),
    locale: 'en-IN',
    theme: new darkTheme(),
};
/**
 * 
 * @param {appState} state 
 * @param {action} action 
 * @returns {appState}
 */
export function appStateReducer(state = appState, action) {
    if (actionsList.apiServerStatusUpdate === action.type) {
        return Object.assign({}, state, { serverState: action.payload });
    } else if (actionsList.userStateUpdate === action.type) {
        return Object.assign({}, state, { userState: action.payload });
    }
    return state;
};
/**
 * 
 * @param {masterState} state 
 * @param {action} action 
 * @returns {masterState}
 */
export function masterStateReducer(state = {
    cinfo: {
        list: [],
        selectedCompany: undefined,
        companySelectorState: {
            dataFetched: false,
            fetchError: false,
            fetchErrorMsg: ''
        },
    },
    customer: customerStateFactory(),
    subCompany: subCompanyStateFactory(),
    date: getDayEnd(new Date()),
    startDate: getDayStart(new Date()),
    maxDate: getMinMaxDate(undefined).max,
    minDate: getMinMaxDate(undefined).min,
}, action) {
    if (actionsList.cinfoUpdate === action.type) {
        let newArray = [...action.payload.cinfo];
        let sc = action.payload.selectedCompany;
        let cinfo = Object.assign({}, state.cinfo, { list: newArray, selectedCompany: sc, companySelectorState: action.payload.companySelectorState })
        let minMax = getMinMaxDate(sc);
        return Object.assign({}, { cinfo: cinfo, customer: customerStateFactory(), subCompany: subCompanyStateFactory(), date: checkDateValidityForComp(state.date, minMax) ? state.date : new Date(), startDate: checkDateValidityForComp(state.startDate, minMax) ? state.startDate : new Date(), maxDate: minMax.max, minDate: minMax.min });
    } else if (actionsList.selectedCompanyUpdate === action.type) {
        let sc = state.cinfo.list.find(x => x.key === action.payload);
        let minMax = getMinMaxDate(sc);
        if (sc) {
            let cinfo = Object.assign({}, state.cinfo, { selectedCompany: sc });
            return Object.assign({}, { cinfo: cinfo, customer: customerStateFactory(), subCompany: subCompanyStateFactory(), date: checkDateValidityForComp(state.date, minMax) ? state.date : new Date(), startDate: checkDateValidityForComp(state.startDate, minMax) ? state.startDate : new Date(), minDate: minMax.min, maxDate: minMax.max });
        }
    } else if (actionsList.selectedSubcompanyUpdate === action.type) {
        return Object.assign({}, state, { subCompany: action.payload });
    } else if (actionsList.selectedCustomerUpdate === action.type) {
        return Object.assign({}, state, { customer: action.payload });
    } else if (actionsList.appDateUpdate === action.type && checkDateValidityForComp(action.payload, {
        min: state.minDate,
        max: state.maxDate
    })) {
        return Object.assign({}, state, { date: action.payload });
    } else if (actionsList.appStartDateUpdate === action.type && checkDateValidityForComp(action.payload, {
        min: state.minDate,
        max: state.maxDate
    })) {
        return Object.assign({}, state, { startDate: action.payload });
    } else if (actionsList.appStartDateAndAppDateUpdate === action.type
        && checkDateValidityForComp(action.payload.startDate, {
            min: state.minDate,
            max: state.maxDate
        })
        && checkDateValidityForComp(action.payload.appDate, {
            min: state.minDate,
            max: state.maxDate
        })) {
        return Object.assign({}, state, { startDate: action.payload.startDate, date: action.payload.appDate });
    }
    return state;
}

/**
 * 
 * @param {Date} date 
 */
function checkDateValidityForComp(date, minMax) {
    return date && minMax && (isSameDay(date, minMax.min) || isAfter(date, minMax.min)) && (isBefore(date, minMax.max) || isSameDay(date, minMax.max));
}

function getMinMaxDate(sc) {
    let minMax = {
        min: new Date(),
        max: new Date(),
    };
    if (sc) {
        minMax.min = new Date(sc.companyDataYear, 3, 1);
        minMax.max = new Date(sc.companyDataYear + 1, 2, 31);

    }
    minMax = {
        max: new Date(2024, 2, 31),
        min: new Date(2023, 3, 1)
    }
    if (minMax.max > new Date())
        minMax.max = new Date()
    return minMax;
}


/**
 * @param {[]} _list customer list as Array
 * @param {boolean} _df Is data fetching ?
 * @param {boolean} _fe Is data fetch ended with error ?
 * @param {string} _ferr Error message received from server
 * @returns {customerState}
 */
export function customerStateFactory(_list = undefined, _df = false, _fe = false, _ferr = '') {
    return {
        list: _list,
        dataFetching: _df,
        fetchError: _fe,
        fetchErrorMsg: _ferr,
    };
}

/**
 * @param {[]} _list Sub Company List as Array
 * @param {boolean} _df Is data fetch process compeleted ?
 * @param {boolean} _fe Is data fetch ended with error ?
 * @param {string} _ferr Error message received from server
 * @param {string} _ssc Selected Sub Company Key
 * @return {subCompanyState}
 */
export function subCompanyStateFactory(_list = undefined, _df = false, _fe = false, _ferr = '') {
    return {
        list: _list,
        dataFetched: _df,
        fetchError: _fe,
        fetchErrorMsg: _ferr,
    };
}
/**
 * 
 * @param {boolean} _authReq 
 * @param {string} _serverStatusMessage
 * @param {boolean}  _reloadCompulsion
 * @returns {serverStatus}
 */
export function serverStateFactory(_authReq, _serverStatusMessage, _reloadCompulsion) {
    return {
        authRequired: _authReq,
        statusMessage: _serverStatusMessage,
        reloadCompulsion: _reloadCompulsion
    }
}
/**
 * 
 * @param {string} _userImageURL 
 * @param {string} _userDisplayName 
 * @param {string} _userEmail 
 * @param {boolean} _al
 * @param {string} jwt
 * @param {string} O365
 * @returns {userState}
 */
export function userStateStateFactory(jwt = "", O365 = "", _userDisplayName = '', _userEmail = '', _usrPhotoData = null, _al = undefined, _userImageURL = userProfilePicUrl) {
    return {
        userImageUrl: _userImageURL,
        userDisplayName: _userDisplayName,
        userEmail: _userEmail,
        userPhotoData: _usrPhotoData,
        userAdminLevel: _al,
        jwt: jwt,
        O365Token: O365
    }
}

/**
 * @typedef serverStatus
 * @property {Boolean} authRequired
 * @property {string} statusMessage
 * @property {Boolean} reloadCompulsion
 */

/**
 * @typedef userState
 * @property {string} userImageUrl
 * @property {string}  userDisplayName
 * @property {string} userEmail
 * @property  {any} userPhotoData
 * @property {boolean} userAdminLevel
 * @property {string} jwt
 * @property {string} O365Token
 *
 */

/**
 * @typedef companyState
 * @property {Array.<EAMCompany>} list
 * @property {EAMCompany} selectedCompany
 * @property {companySelectorState} companySelectorState
 */
/**
 * @typedef companySelectorState
 * @property {boolean} dataFetched
 * @property {boolean} fetchError
 * @property {string} fetchErrorMsg
 */
/**
 * @typedef customerState
 * @property {Array.<EAMCustomer>} list
 * @property {boolean} dataFetching
 * @property {boolean} fetchError
 * @property {string} fetchErrorMsg
 */


/**
 * @typedef subCompanyState
 * @property {Array.<EAMSubCompany>} list
 * @property {boolean} dataFetched
 * @property {boolean} fetchError
 * @property {string} fetchErrorMsg
 */

/**
 * @typedef appState
 * @property {string} appName
 * @property {string} apiHost
 * @property {serverStatus} serverState
 * @property {userState} userState
 * @property {string} locale
 * @property {import('../types/theme').darkTheme} theme
 * @property {string} [devAPIKey]
 */

/**
 * @typedef masterState
 * @property {companyState} cinfo
 * @property {customerState} customer
 * @property {subCompanyState} subCompany
 * @property {Date} date
 * @property {Date} startDate
 * @property {Date} maxDate
 * @property {Date} minDate
 */

/**
 * @typedef action
 * @property {string} type
 * @property {any} payload
 */

/**
 * @typedef EAMCompany
 * @property {String} companyCode
 * @property {Number} companyDataYear
 * @property {string} key
 */
/**
 * @typedef EAMCustomer
 * @property {string} cuscode
 * @property {string} place
 * @property {string} cusname
 */

/**
 * @typedef EAMSubCompany
 * @property {string} subCompanyCode
 * @property {string} subCompanyName
 */