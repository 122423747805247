import { createStore, combineReducers } from "redux";
import { appStateReducer, masterStateReducer } from "./reducer.js";
const store = createStore(combineReducers({
    appState: appStateReducer,
    masterState: masterStateReducer

}));

export default store;

/**
 * @typedef appMasterStare
 * @property {import('./reducer').appState} appState
 * @property {import('./reducer').masterState} masterState
 */