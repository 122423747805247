import React, { useDebugValue } from 'react';
import { FixedSizeList as List, VariableSizeGrid as Grid } from 'react-window';
import styles from './navigatableTable.module.css';
import classNames from 'classnames';
import { uuidv4, multiSortMax10 } from '../types/helper'
import { ReactMutaionWatcher } from './reactMutaionWather';
import { ClearOutlined } from '@ant-design/icons';
import FilterInterface from './FilterInterface.jsx'
import KeyListen from './KeyListener'
import { Table, Spin, Dropdown, Menu } from "antd";
const CSS_CLASSNAME_STRIKEOUT = 'strikeout';
const CSS_CLASSNAME_SELECTED = 'nv-table-selected';
class NavigatableTable extends React.Component {
    constructor(props) {
        super(props);
        this.styles = {
            style: styles,
            className_Strike: CSS_CLASSNAME_STRIKEOUT,
            className_Select: CSS_CLASSNAME_SELECTED
        }
        this.state = this.componentStateFactory();
        this.guid = '_nt' + uuidv4();
        this.rowCellRef = {};
        this.bindClassFunctions();
        this.KeyListenProps = {
            down: { downKeys: ['ArrowUp', 'ArrowDown', 'Tab', 'ArrowLeft', 'ArrowRight', 'PageDown', 'PageUp', 'Home', 'End', 'Enter', 'Delete'], downCallBack: this.onKeyDown },
            up: { upKeys: ['PageUp', 'PageDown'], upCallBack: this.onKeyUp }
        }
        this.sizeReset = () => this.setState({
            isHeightDirty: true,
            isWidthDirty: true,
        });
        this.ignoreKey = false;
        this.tableBody = {
            header: {
                height: 0
            },
            totalPage: 0,
            itemsPerPage: 0,
            selection: {
                defaultKeySelected: false,
                selectedindecies: [],
                index: 0,  //Last Keyboard or Mouse focused item's index
                mutationObserver: new MutationObserver(this.handleUnwantedClassChanges),
                mutaionWatchOptions: {
                    attributeFilter: ['class']
                }
            },
            scrolling: {
                barPosition: 1,
                threshold: 0,
                scrollIndexQueue: new Array(100),
                columnIndex: 0
            },
            render: {
                column: {
                    width: undefined
                },
                bottomBorderWidth: 0,// in pixel
            },
            sticky: {
                top: false,
                bottom: true
            },
            refCollection: {
                stickyTop: React.createRef(),
                stickyBottom: React.createRef(),
                gridRef: React.createRef(),
                footerGridRef: React.createRef(),
                filterRef: React.createRef(),

            },
        }
        matchMedia(`(resolution:${window.devicePixelRatio}dppx)`).addEventListener("change", () => {
            this.setState(Object.assign({}, this.state));
        })

        this.scrollIndexQueueTimer = setInterval(this.scrollIndexQueueHandler, this.tableBody.scrolling.threshold);
    }
    handleUnwantedClassChanges(mutationsList, observer) {
        for (const mutation of mutationsList) {
            let index = mutation.target.dataset.virtualTableRowIndex;
            if (index !== undefined && this.tableBody.selection.selectedindecies.find(x => x == index) !== undefined) {
                let classValue = mutation.target.className;
                if (!classValue.includes(this.styles.style[this.styles.className_Select]))
                    mutation.target.className += " " + this.styles.style[this.styles.className_Select];
                if (!classValue.includes(this.styles.style[this.props.theme]))
                    mutation.target.className += " " + this.styles.style[this.props.theme];
            }
        }
    }
    scrollIndexQueueHandler() {
        let ul = this.tableBody.scrolling.scrollIndexQueue.length;
        if (ul > 0) {
            let item = this.tableBody.scrolling.scrollIndexQueue[ul - 1];
            if (item) {
                let { index, pos } = item;
                this.tableBody.scrolling.scrollIndexQueue = [];
                if (index >= 0) {
                    this.drawItemintoWindow(index, pos);
                }
            }
        }
    }
    setFocus() {
        let element = document.getElementById(this.guid);
        if (element)
            element.focus();
    }
    componentStateFactory() {
        return {
            isVisible: false,
            loading: true,
            shouldCalcColSize: false,
            data: this.props.dataSource ? [...this.props.dataSource] : [],
            sortedInfo: [],
            autoWidth: this.scroll?.x != undefined,
            autoHeight: this.scroll?.y != undefined,
            height: undefined,
            width: undefined,
            isHeightDirty: true,
            isWidthDirty: true,
        }
    }
    bindClassFunctions() {
        this.scrollIndexQueueHandler = this.scrollIndexQueueHandler.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onContextClick = this.onContextClick.bind(this);
        // this.onTableBlur = this.onTableBlur.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.handleUnwantedClassChanges = this.handleUnwantedClassChanges.bind(this);
        this.contextMenu = this.contextMenu.bind(this);
        this.headerDropdown = this.headerDropdown.bind(this);
        this.colWidth = this.colWidth.bind(this);
        this.internalSorter = this.internalSorter.bind(this);
        this.clearSort = this.clearSort.bind(this);
        this.contextMenuHeader = this.contextMenuHeader.bind(this);
    }

    componentDidMount() {

        window.addEventListener('resize', this.sizeReset);
        this.setSize();
        this.setState({
            isVisible: true,
            loading: false,
        });
        this.measureRowDetails();
        this.setFocus();

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.sizeReset);
        clearInterval(this.scrollIndexQueueTimer);
        this.tableBody.selection.mutationObserver.disconnect();
    }
    get currentPageNo() {
        return Math.ceil((this.tableBody.selection.index + 1) / this.tableBody.itemsPerPage);
    }
    calculateRenderedcolWidth() {
        if (this.state.shouldCalcColSize) {
            let header = document.querySelector(`.${this.guid} .ant-table-header`)
            if (header) {
                this.tableBody.header.height = header.clientHeight;
                this.setState((ps) => {
                    return {
                        height: ps.height - header.clientHeight
                    }
                });
            }
            let cols = document.querySelector(`.${this.guid} .ant-table-header > table > thead > tr`);
            if (cols) {
                let colWidth = [];
                for (let c of cols.children) {
                    colWidth.push(c.clientWidth);
                }
                this.tableBody.render.column.width = colWidth;
            }
            if (header && cols)
                this.setState({ shouldCalcColSize: false });
        }
    }
    get tableWidth() {
        return this.state.width;
    }
    getSortOrder() {
        return this.state.sortedInfo?.reduce((newArray, val) => newArray.push(Object.assign({}, val)) && newArray, []);
    }
    setSortOrder(sortedInfo) {
        if (
            sortedInfo instanceof Array &&
            sortedInfo.find(x => x.name === undefined || x.order === undefined) === undefined
        ) {
            let si = sortedInfo?.reduce((newArray, val) => newArray.push(Object.assign({}, val)) && newArray, []);
            this.setState({
                data: si.length > 0 ? this.state.data.sort((a, b) => multiSortMax10(a, b, si)) : (this.props.dataSource ? [...this.props.dataSource] : []),
                sortedInfo: si
            });
        }
    }
    setScrollTipVisiblity() {
        let totItm = this.state.data.length;
        let itmInPage = this.tableBody.itemsPerPage;
    }
    setStrikeonMount() {
        if (!this.props.defaultStrikeControlKey)
            return;
        if (this.state.data && !Array.isArray(this.state.data))
            throw new Error('DataSource must be array or null, undefined');
        this.state.data.forEach((x) => {
            if (x[this.props.defaultStrikeControlKey]) {
                this.strikeRow(x[this.props.rowKey]);
            }
        });
    }
    setSize() {
        let h = 0; let w = 0;
        /** Getting height */
        if (this.props.scroll?.y) {
            h = this.props.scroll.y;
        } else {
            let elem = document.querySelector(`#${this.guid}`);
            if (elem) {
                h = window.innerHeight - elem.offsetTop;
            }
        }
        /** Getting width */
        if (this.props.scroll?.x) {
            w = this.props.scroll.x;
        } else {
            let elem = document.querySelector(`#${this.guid}`);
            if (elem) {
                w = elem.clientWidth;
            }
        }
        this.setState((ps) => ({
            width: w,
            height: h,
            isHeightDirty: false,
            isWidthDirty: false,
            shouldCalcColSize: (ps.w !== w)
        }), () => this.measureRowDetails());
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.isHeightDirty || this.state.isWidthDirty)
            this.setSize();
        if (this.props.scroll?.y !== prevProps.scroll?.y) {
            this.setState({
                isHeightDirty: true
            });
        }
        if (this.props.scroll?.x !== prevProps.scroll?.x) {
            this.setState({
                isWidthDirty: true
            });
        }
        if (this.props.rowHeight !== prevProps.rowHeight)
            this.measureRowDetails();
        if (!this.tableBody.selection.defaultKeySelected) {
            if (this.props.defaultSelectedBillKey)
                this.selectRow(this.props.defaultSelectedBillKey);
            else this.selection();
            this.tableBody.selection.defaultKeySelected = true;
        } else if ((this.state.data !== prevState.data
            ||
            this.state.loading < prevState.loading
            ||
            this.state.sortedInfo !== prevState.sortedInfo
        ) &&
            this.tableBody.selection.defaultKeySelected
        ) {
            if (this.state.data !== prevState.data) {
                let index = this.state.data.findIndex(x => x["emptyrow-nt"]);
                console.log("empty row index")
                console.log(index)
                console.log(this.state.data)
                if (index !== -1) {
                    this.selectEmptyRow(index);
                }
            }
            if (this.tableBody.selection.selectedindecies.length === 1)
                this.scrolltoSelection();
            else this.selection();

        }
        if (this.state.shouldCalcColSize)
            this.calculateRenderedcolWidth();
        this.setFocus();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.dataSource !== nextProps.dataSource) {
            console.log("updating data in state");
            console.log(nextProps.dataSource);
            this.setState({
                data: (nextProps.dataSource ? [...nextProps.dataSource] : [])
            });
            return false;
        }

        return true;
    }
    setNoofItemsinView() {
        let pIPC = Math.floor(this.tableBodyHeight / (this.props.rowHeight || 30));
        return this.tableBody.itemsPerPage = this.state.data.length > pIPC ? pIPC : this.state.data.length;
    }
    get getSelectedItems() {
        return this.state.data?.filter((x, i) => this.tableBody.selection.selectedindecies.includes(i));
    }
    applyStyletoRow(key, style) {
        let tr = this.getRowfromKey(key);
        for (let td of tr.children)
            for (let chil of td.children)
                chil.setAttribute('style', style);
    }
    scrolltoSelection() {
        for (let row of this.tableBody.selection.selectedindecies)
            this.highLightRow(row);
    }
    drawItemintoWindow(index, pos) {
        if (!pos)
            pos = 'smart';
        if (this.tableBody.refCollection.gridRef.current)
            this.tableBody.refCollection.gridRef.current.scrollToItem(index, pos);
    }
    /**
     * Function to de hightlight all the currently selected index
    ╩ */
    deHighlightAll() {
        this.tableBody.selection.mutationObserver.disconnect();
        for (let i of this.tableBody.selection.selectedindecies)
            this.deHighlightRow(i);
    }
    /**
     * @param {string} key row Key defined with rowKey property
     */
    selectRow(key) {
        let index = this.state.data.findIndex(x => x[this.props.rowKey] == key);
        if (index !== -1) {
            this.tableBody.selection.index = index;
            this.deHighlightAll();
            this.tableBody.selection.selectedindecies = [index];
            this.highLightRow(index);
        }
    }

    /** 
     *@param {number} index index to highlight
     *@param {scrollIntoViewOptions} scrollOptions scrollOptions as per standard
     */
    highLightRow(index, scroll = true) {
        let scrollOptions = {
            block: 'nearest',
        };
        let divCollections = this.getRowfromIndex(index);
        if (divCollections.length > 0) {
            let className = [styles[CSS_CLASSNAME_SELECTED], styles[this.props.theme], styles.loading, CSS_CLASSNAME_SELECTED];
            this.tableBody.scrolling.scrollIndexQueue = [];
            for (let div of divCollections) {
                if (!this.props.columnSelectable) {
                    div.classList.add(...className);
                    if (scroll)
                        div.scrollIntoView(scrollOptions);
                } else {
                    for (let colDiv of div.childNodes) {
                        if (colDiv.dataset.virtualTableColumnIndex == this.tableBody.scrolling.columnIndex)
                            colDiv.classList.add(...className);
                        if (scroll)
                            colDiv.scrollIntoView(scrollOptions);
                    }
                }
                this.tableBody.selection.mutationObserver.observe(div, this.tableBody.selection.mutaionWatchOptions);
            }
        } else if (scroll)
            this.tableBody.scrolling.scrollIndexQueue.push({ index });
    }
    deHighlightRow(index) {
        let divCollections = this.getRowfromIndex(index);
        if (divCollections.length > 0) {
            let className = [styles[CSS_CLASSNAME_SELECTED], styles[this.props.theme], styles.loading, CSS_CLASSNAME_SELECTED];
            for (let div of divCollections) {
                div.classList.remove(...className);
                div.childNodes[this.tableBody.scrolling.columnIndex]?.classList.remove(...className);
                // for (let colDiv of div.childNodes) {
                //     colDiv.classList.remove(...className);
                // }
            }
        }
    }
    /**
     *@param {number|string} key Key of the table Row to de-highLight
     */
    deselectRow(key) {
        return;
    }
    strikeRow(key) {
        let strikeClassName = ' ' + styles[CSS_CLASSNAME_STRIKEOUT];
        let divCollections = this.getRowfromKey(key);
        for (let div of divCollections) {
            if (!div.className.includes(strikeClassName)) {
                div.className += strikeClassName;
            }
        }
    }
    unStrikeRow(key) {
        let strikeClassName = ' ' + styles[CSS_CLASSNAME_STRIKEOUT];
        let divCollections = this.getRowfromKey(key);
        for (let div of divCollections) {
            div.className = div.className.replace(strikeClassName, '');
        }
    }

    /**
    * Handles unselection if any when the cursor moves forward or backward
    * while the user holds the shift key
    *
    * Should be called only when user holding the shift key 
    * @param {'UP'|'DOWN'} mode
    * @returns {boolean} 
    */
    handleMultipleUnselection(mode) {
        let cursorMvmnt = mode === 'DOWN' ? 1 : -1;
        let lookupVal = this.tableBody.selection.index + cursorMvmnt;
        let valtoRej = this.tableBody.selection.index;
        if ((lookupVal < 0) || (lookupVal > (this.state.data.length - 1)))
            return undefined;
        let returnValue = false;
        this.tableBody.selection.selectedindecies = this.tableBody.selection.selectedindecies.filter(x => {
            let ret = x === lookupVal;
            if (ret)
                returnValue = ret;
            return x !== valtoRej || !returnValue;
        });
        if (returnValue)
            this.deHighlightRow(valtoRej);
        return returnValue;
    }
    selectEmptyRow(rowIndex, defaultColumn) {
        if (rowIndex !== undefined && rowIndex !== -1) {
            this.deHighlightAll();
            this.tableBody.selection.index = rowIndex;
            this.tableBody.selection.selectedindecies = [rowIndex];
            this.tableBody.scrolling.columnIndex = defaultColumn ?? 0;
            this.highLightRow();
        }
    }
    /**
    * @param {'UP'|'DOWN'|'HOME'|'END'|'CLICK'|'PAGEUP'|'PAGEDOWN'} [action] 
    * @param {boolean} [shiftKey] 
    * @param {boolean} [shouldFocus] 
    */

    selection(action, shiftKey, shouldFocus) {
        if (!this.state.data || this.state.data.length <= 0)
            return;
        if (!action) {
            this.deHighlightAll();
            this.tableBody.selection.index = 0;
            this.tableBody.selection.selectedindecies = [0];
            this.tableBody.refCollection.footerGridRef?.current?.resetAfterRowIndex({
                index: 0,
                shouldForceUpdate: true
            });
        } else if (action === 'UP') {
            let indextoSelect = this.tableBody.selection.index - 1;
            if (indextoSelect <= 0 && this.tableBody.selection.index === 0)
                return; // Returns if the new index is less than 0 and old index is already zero
            if (shiftKey) {
                if (this.handleMultipleUnselection('UP') === false) {
                    this.tableBody.selection.selectedindecies.push(indextoSelect);
                    this.tableBody.selection.index = indextoSelect;
                    this.highLightRow(indextoSelect);
                    this.tableBody.refCollection.footerGridRef?.current?.resetAfterRowIndex({
                        index: 0,
                        shouldForceUpdate: true
                    });
                    return;
                }
                else {
                    this.tableBody.selection.index = indextoSelect;
                    this.tableBody.refCollection.footerGridRef?.current?.resetAfterRowIndex({
                        index: 0,
                        shouldForceUpdate: true
                    });
                }
            }
            else {
                this.deHighlightAll();
                let updateFooter = false
                if (this.tableBody.selection.selectedindecies?.length > 1)
                    updateFooter = true;
                this.tableBody.selection.index = indextoSelect;
                this.tableBody.selection.selectedindecies = [indextoSelect];
                if (updateFooter)
                    this.tableBody.refCollection.footerGridRef?.current?.resetAfterRowIndex({
                        index: 0,
                        shouldForceUpdate: true
                    });
            }
        } else if (action === 'DOWN') {
            let indextoSelect = this.tableBody.selection.index + 1;
            if (indextoSelect >= (this.state.data.length - 1) && this.tableBody.selection.index === (this.state.data.length - 1))
                return; // Returns if the new index is greater than the last one and old index is already the last one
            if (shiftKey) {
                if (this.handleMultipleUnselection('DOWN') === false) {
                    this.tableBody.selection.selectedindecies.push(indextoSelect);
                    this.highLightRow(indextoSelect);
                    this.tableBody.selection.index = indextoSelect;
                    this.tableBody.refCollection.footerGridRef?.current?.resetAfterRowIndex({
                        index: 0,
                        shouldForceUpdate: true
                    });
                    return;
                }
                else {
                    this.tableBody.selection.index = indextoSelect;
                    this.tableBody.refCollection.footerGridRef?.current?.resetAfterRowIndex({
                        index: 0,
                        shouldForceUpdate: true
                    });
                }
            }
            else {
                let updateFooter = false
                if (this.tableBody.selection.selectedindecies?.length > 1)
                    updateFooter = true;
                this.deHighlightAll();
                this.tableBody.selection.index = indextoSelect;
                this.tableBody.selection.selectedindecies = [indextoSelect];
                if (updateFooter)
                    this.tableBody.refCollection.footerGridRef?.current?.resetAfterRowIndex({
                        index: 0,
                        shouldForceUpdate: true
                    });
            }
        }
        else if (action === 'RIGHT' && this.props.columnSelectable) {
            let colSelected = this.tableBody.scrolling.columnIndex;
            colSelected++;
            if (colSelected >= this.props.columns.length)
                return;
            this.deHighlightAll();
            if (shouldFocus) {
                let refCollection = this.getCellRef(this.state.data[this.tableBody.selection.index][this.props.rowKey] || this.tableBody.selection.index, this.tableBody.scrolling.columnIndex)
                if (refCollection?.colInnerRef?.innerRef?.current?.blur) {
                    refCollection?.colInnerRef?.innerRef?.current?.blur();
                } else
                    refCollection?.colRef?.current.blur();
            }
            this.tableBody.scrolling.columnIndex = colSelected;
        }
        else if (action === 'LEFT' && this.props.columnSelectable) {
            let colSelected = this.tableBody.scrolling.columnIndex;
            colSelected--;
            if (colSelected < 0)
                return;
            this.deHighlightAll();
            if (shouldFocus) {
                let refCollection = this.getCellRef(this.state.data[this.tableBody.selection.index][this.props.rowKey] || this.tableBody.selection.index, this.tableBody.scrolling.columnIndex)
                if (refCollection?.colInnerRef?.innerRef?.current?.blur) {
                    refCollection?.colInnerRef?.innerRef?.current?.blur();
                } else
                    refCollection?.colRef?.current.blur();
            }
            this.tableBody.scrolling.columnIndex = colSelected;
        }
        else if (action === 'HOME') {
            let updateFooter = false
            if (this.tableBody.selection.selectedindecies?.length > 1)
                updateFooter = true;
            this.deHighlightAll();
            this.tableBody.selection.selectedindecies = [0];
            this.tableBody.selection.index = 0;
            if (updateFooter)
                this.tableBody.refCollection.footerGridRef?.current?.resetAfterRowIndex({
                    index: 0,
                    shouldForceUpdate: true
                });
        }
        else if (action === 'END') {
            let updateFooter = false
            if (this.tableBody.selection.selectedindecies?.length > 1)
                updateFooter = true;
            this.deHighlightAll();
            this.tableBody.selection.index = this.state.data.length - 1;
            this.tableBody.selection.selectedindecies = [this.state.data.length - 1];
            if (updateFooter)
                this.tableBody.refCollection.footerGridRef?.current?.resetAfterRowIndex({
                    index: 0,
                    shouldForceUpdate: true
                });
        } else if (action === 'PAGEUP') {
            let updateFooter = false
            if (this.tableBody.selection.selectedindecies?.length > 1)
                updateFooter = true;
            let ipc = this.tableBody.itemsPerPage;
            let currentPage = this.currentPageNo;
            let indextoSelect = (((currentPage - 1) * ipc) - ipc);
            indextoSelect = (indextoSelect < 0) ? 0 : indextoSelect;
            if (indextoSelect === 0 && this.tableBody.selection.index === 0)
                return; // Returns if the new index is less than 0 and old index is already zero
            this.deHighlightAll();
            this.tableBody.selection.index = indextoSelect;
            this.tableBody.selection.selectedindecies = [indextoSelect];
            this.tableBody.scrolling.scrollIndexQueue.push({ index: indextoSelect, pos: 'start' });
            if (updateFooter)
                this.tableBody.refCollection.footerGridRef?.current?.resetAfterRowIndex({
                    index: 0,
                    shouldForceUpdate: true
                });
            return;
        }
        else if (action === 'PAGEDOWN') {
            let updateFooter = false
            if (this.tableBody.selection.selectedindecies?.length > 1)
                updateFooter = true;
            let ipc = this.tableBody.itemsPerPage;
            let currentPage = this.currentPageNo;
            let indextoSelect = ((currentPage + 1) * ipc) - 1;
            indextoSelect = (indextoSelect > (this.state.data.length - 1)) ? (this.state.data.length - 1) : indextoSelect;
            if (indextoSelect === (this.state.data.length - 1) && this.tableBody.selection.index === (this.state.data.length - 1))
                return; // Returns if the new index is greater than the last one and old index is already the last one
            this.deHighlightAll();
            this.tableBody.selection.index = indextoSelect
            this.tableBody.selection.selectedindecies = [indextoSelect];
            this.tableBody.scrolling.scrollIndexQueue.push({ index: indextoSelect, pos: 'end' });
            if (updateFooter)
                this.tableBody.refCollection.footerGridRef?.current?.resetAfterRowIndex({
                    index: 0,
                    shouldForceUpdate: true
                });
            return;
        }
        this.highLightRow(this.tableBody.selection.index);
        if (this.props.columnSelectable && shouldFocus) {
            this.focusCell();
        }
    }
    selectCell(row, column, shouldFocus) {
        this.tableBody.selection.selectedindecies = [row];
        this.tableBody.selection.index = row;
        this.tableBody.refCollection.footerGridRef?.current?.resetAfterRowIndex({
            index: 0,
            shouldForceUpdate: true
        });
        if (shouldFocus) {
            let refCollection = this.getCellRef(this.state.data[this.tableBody.selection.index][this.props.rowKey] || this.tableBody.selection.index, this.tableBody.scrolling.columnIndex)
            if (refCollection?.colInnerRef?.innerRef?.current?.blur) {
                refCollection?.colInnerRef?.innerRef?.current?.blur();
            } else
                refCollection?.colRef?.current.blur();
        }
        this.tableBody.scrolling.columnIndex = column;
        this.deHighlightAll();
        this.highLightRow(this.tableBody.selection.index);
        if (this.props.columnSelectable && shouldFocus) {
            this.focusCell();
        }
    }
    focusCell() {
        let refCollection = this.getCellRef(this.state.data[this.tableBody.selection.index][this.props.rowKey] || this.tableBody.selection.index, this.tableBody.scrolling.columnIndex)
        if (refCollection?.colInnerRef?.innerRef?.current?.focus instanceof Function) {
            refCollection?.colInnerRef?.innerRef?.current?.focus();
        } else {
            refCollection?.colRef?.current.focus();
        }
    }
    measureRowDetails() {
        this.setNoofItemsinView();
        this.tableBody.totalPage = Math.ceil(this.state.data.length / this.tableBody.itemsPerPage);
        let pno = this.currentPageNo;
        this.tableBody.sticky.top = pno !== 1;
        this.tableBody.sticky.bottom = pno < this.totalPage;
    }

    /**
     * 
     * @param {string|number} key 
     * @returns {NodeList}
     */
    getRowfromKey(key) {
        return document.querySelectorAll(`#${this.guid} [data-virtual-table-row-key="${key}"]`);
    }
    getRowfromIndex(index) {
        return document.querySelectorAll(`#${this.guid} [data-virtual-table-row-index="${index}"]`);
    }
    onRowClick(e) {
        if (this.props.columnSelectable)
            return;
        let index = parseInt(e.target.parentElement.dataset.virtualTableRowIndex);
        if (isNaN(index))
            index = parseInt(e.target.parentElement.parentElement.dataset.virtualTableRowIndex);
        if (isNaN(index))
            index = parseInt(e.target.dataset.virtualTableRowIndex);
        if (isNaN(index) || index < 0 || index >= this.state.data.length) {
            return;
        }
        if (this.tableBody.selection.selectedindecies.findIndex(x => x === index) === -1) {
            if (!e.shiftKey) {
                this.deHighlightAll();
                this.tableBody.selection.index = index;
                this.tableBody.selection.selectedindecies = [index];
            } else {
                this.tableBody.selection.selectedindecies.push(index);
                this.tableBody.selection.index = index;
            }
            this.highLightRow(index);
        } else {
            if (e.button === 2) {
                setImmediate(() => this.highLightRow(index));
                return;
            }
            if (!e.shiftKey || this.tableBody.selection.selectedindecies.length <= 1) {
                this.deHighlightAll();
                this.tableBody.selection.index = index;
                this.tableBody.selection.selectedindecies = [index];
                this.highLightRow(index);
            } else {
                this.tableBody.selection.selectedindecies = this.tableBody.selection.selectedindecies.filter(x => x !== index);
                this.tableBody.selection.index = this.tableBody.selection.selectedindecies[this.tableBody.selection.selectedindecies.length - 1];
                this.deHighlightRow(index);
            }
        }
    }
    onRowDoubleClick() {
        if (this.props.onRowDoubleClick instanceof Function)
            this.props.onRowDoubleClick(this.state.data[this.tableBody.selection.index]);
    }
    onKeyDown(e) {
        console.log("Key pressed --> ");
        console.log(e.key);
        if (this.ignoreKey)
            return;
        if (e.key === 'ArrowUp') {
            this.selection("UP", e.shiftKey);
            e.stopPropagation();
        } else if (e.key === 'ArrowDown') {
            this.selection('DOWN', e.shiftKey);
            e.stopPropagation();
        }
        else if (e.key === 'ArrowLeft') {
            this.selection('LEFT', false);
            e.stopPropagation();
        }
        else if (e.key === 'Tab' && e.shiftKey) {
            this.selection('LEFT', false);
            e.stopPropagation();
            e.preventDefault();
        }
        else if (e.key === 'ArrowRight') {
            this.selection('RIGHT', false);
            e.stopPropagation();
        }
        else if (e.key === 'Tab') {
            this.selection('RIGHT', false);
            e.stopPropagation();
            e.preventDefault();
        }
        else if (e.key === 'PageDown') {
            this.selection('PAGEDOWN');
            e.stopPropagation();
        }
        else if (e.key === 'PageUp') {
            this.selection('PAGEUP');
            e.stopPropagation();
        }
        else if (e.key === 'Home') {
            this.selection('HOME');
            e.stopPropagation();
        }
        else if (e.key === 'End') {
            this.selection('END');
            e.stopPropagation();
        } else if (e.key === 'Enter') {
            if (this.props.columnSelectable) {
                this.focusCell();
            }
            if (this.props.onEnter instanceof Function)
                this.props.onEnter(this.getSelectedItems, this);

            e.stopPropagation();
        } else if (e.key === 'Delete') {
            e.stopPropagation();
            if (this.props.onDelete instanceof Function)
                this.props.onDelete(this.getSelectedItems, this);
        } else if (e.key === 'v' && e.altKey) {
            e.stopPropagation();
        } else if (e.key === 'F2') {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
        return true;

    }
    reRenderSelection() {
        this.forceUpdate();
    }
    getCellRef(rowKey, c) {
        if (typeof c === 'string') {
            c = this.props.columns.findIndex(x => x.name?.toLowerCase() === c.toLowerCase());
        }
        const rowRef = this.rowCellRef[`row_${rowKey}`];
        const colRef = this.rowCellRef[`row_${rowKey}_column_${c}`];
        const colInnerRef = this.rowCellRef[`row_${rowKey}_column_${c}_inner${c}`];
        return {
            rowRef,
            colRef,
            colInnerRef
        };
    }
    onContextClick({ key }) {
        let menu = this.props.contextMenu[key];
        if (menu && menu.callback instanceof Function) {
            menu.callback(this.getSelectedItems, this);
        }
    }
    contextMenu() {
        return <Menu onClick={this.onContextClick} selectable={true}>
            {
                this.props.contextMenu.map((menu, index) => {
                    return <Menu.Item disabled={this.tableBody.selection.selectedindecies.length > 1 && menu.disbleonMultiSelect} key={index} icon={menu.icon}>{menu.title}</Menu.Item>
                })
            }
        </Menu>
    }
    contextMenuHeader() {
        return <Menu >
            <Menu.Item onClick={this.clearSort} key={0} icon={<ClearOutlined />}>Clear Sort</Menu.Item>
        </Menu>
    }
    onKeyUp(e) {
        if (e.key === 'PageUp' || e.key === 'PageDown') {
            this.tableBody.selection.mutationObserver.disconnect();
            let ul = this.tableBody.scrolling.scrollIndexQueue.length;
            if (ul > 0) {
                let item = this.tableBody.scrolling.scrollIndexQueue[ul - 1];
                if (item) {
                    let { index, pos } = item;
                    this.tableBody.scrolling.scrollIndexQueue = [];
                    if (index !== -1)
                        this.drawItemintoWindow(index, pos);
                }
            }
        }
    }
    get tableBodyHeight() {
        return ((this.state.height - (this.props.footer ? ((this.props.rowHeight || 30) * 1.2) : 0)) - this.tableBody.render.bottomBorderWidth);
    }
    colWidth(index, cols, scrollbarSize) {
        const totalHeight = this.state.data.length * (this.props.rowHeight || 30);
        const width = this.tableBody.render.column.width[index];
        return totalHeight > this.tableBodyHeight && index === cols.length - 1
            ? width - scrollbarSize - 1
            : width;
    }
    renderFooter(mergedColumns, scrollbarSize) {
        return this.props.columns.map((col, i) => {
            if (col.footer)
                return (<div style={{
                    width: this.colWidth(i, mergedColumns, scrollbarSize)
                }}>
                    {
                        col.footer instanceof Function ?
                            col.footer()
                            :
                            col.footer
                    }
                </div>);
            else
                return null;
        });
    }
    internalSorter(pagination, filters, sorter, extra) {
        if (extra.action === 'sort') {
            let fields = [];
            if (sorter instanceof Array) {
                for (let col of sorter) {
                    fields.push({
                        name: col.field,
                        value: col.column.sortValue,
                        order: col.order
                    });
                }
            } else if (sorter.column !== undefined) {
                fields.push({
                    name: sorter.field,
                    value: sorter.column.sortValue,
                    order: sorter.order
                });
            }
            let data = undefined;
            if (this.tableBody.selection.selectedindecies.length === 1) {
                data = (this.props.dataSource ? [...this.props.dataSource] : []);
                if (fields.length > 0)
                    data.sort((a, b) => multiSortMax10(a, b, fields));
                let selectedIndecies = [];
                for (let index of this.tableBody.selection.selectedindecies) {
                    let obj = this.state.data[index];
                    let newIndex = data.findIndex((oldObj) => oldObj === obj);
                    if (newIndex !== -1)
                        selectedIndecies.push(newIndex);
                }
                this.deHighlightAll();
                this.tableBody.selection.selectedindecies = selectedIndecies;
            }
            else
                data = fields.length > 0 ? this.state.data.sort((a, b) => multiSortMax10(a, b, fields)) : (this.props.dataSource ? [...this.props.dataSource] : []);
            this.setState({
                data: data,
                sortedInfo: fields
            });

        }
    }
    headerDropdown(props) {
        let { style, children, remaining } = props;
        let newstyle = Object.assign({}, style);
        newstyle.fontSize = "1.1rem";
        newstyle.display = "table-row-group";
        newstyle.borderBottom = 'solid black 2px'
        return (<Dropdown overlay={this.contextMenuHeader} trigger={['contextMenu']} >
            <thead className={`${this.guid}_header _nt_header`} style={newstyle} {...remaining}>
                {children}
            </thead>
        </Dropdown>);
    }
    clearSort() {
        this.internalSorter({}, {}, {}, { action: 'sort' });
    }
    /**
     * 
     * @param {number} row 
     * @param {number} column 
     * @returns {{}}
     */
    getCellRefs(row, column) {
        const rowPimaryKey = this.state.data[row][this.props.rowKey] || row;
        return this.rowCellRef[`row_${rowPimaryKey}_column_${column}_inner${column}`];
    }
    render() {
        const data = this.state.data;
        const tablewidth = this.tableWidth;
        const widthColumnCount = this.props.columns.filter(({ width }) => !width).length;
        let explicitWidth = 0;
        this.props.columns.forEach(i => {
            if (i.width) {
                if (i.columnType === "responsive")
                    explicitWidth += ((tablewidth * i.width) / 100);
                else
                    explicitWidth += i.width;
            }
        });
        const mergedColumns = this.props.columns.map((column, i) => {
            if (column.width) {
                let wid = column.columnType == "responsive" ? ((tablewidth * column.width) / 100) : column.width;
                return {
                    ...column,
                    sorter: column.sorter ? {
                        multiple: 1,
                    } : false,
                    sortOrder: this.state.sortedInfo.find(x => x.name === column.key)?.order,
                    width: wid,
                    onHeaderCell: column => ({
                        style: {
                            ...column.style,
                            width: `max(${column.width}px, ${column.minWidth || '0px'})`,
                            padding: column.sorter ? undefined : !this.props.filterInterface ? "1em" : "1em",
                        }
                    })
                };
            }
            return {
                ...column,
                sorter: column.sorter ? {
                    multiple: 1,
                } : false,
                sortOrder: this.state.sortedInfo.find(x => x.name === column.key) && this.state.sortedInfo.find(x => x.name === column.key).order,
                width: ((tablewidth - explicitWidth) / widthColumnCount),
                onHeaderCell: column => ({
                    style: {
                        width: `max(${column.width}px, ${column.minWidth || '0px'})`,
                        padding: column.sorter ? undefined : !this.props.filterInterface ? "1em" : "1em",
                    }
                })

            };
        });
        // let onRow = (record, rowindex) => {
        //     return {
        //         onClick: e => this.getSelectedRowKey('CLICK', rowindex),
        //         onDoubleClick: this.props.onRowDoubleClick ? (e => this.props.onRowDoubleClick(record)) : undefined
        //     }
        // };
        return (
            <>
                <KeyListen down={this.KeyListenProps.down} up={this.KeyListenProps.up} />
                <div id={this.guid} className={classNames(styles.disableSelect, this.guid, styles.root)} tabIndex={this.props.tabIndex || 0}>
                    {
                        this.props.filterInterface !== false ?
                            <FilterInterface ref={this.tableBody.refCollection.filterRef} fields={this.props.columns} />
                            :
                            null
                    }
                    {
                        this.state.isHeightDirty || this.state.isWidthDirty
                            ?
                            null :
                            (<Table className={this.guid} summary={this.props.summary} rowKey={this.props.rowKey} columns={mergedColumns} dataSource={data} pagination={this.props.pagination}
                                scroll={{ x: this.state.width, y: this.state.height }}
                                onChange={this.internalSorter}
                                components={{
                                    header: {
                                        wrapper: this.headerDropdown
                                    },
                                    body: this.state.loading && this.props.loading && this.props.loading.isloading ? () => {
                                        return (
                                            <div style={{ height: this.tableBodyHeight }}>
                                                <div style={{ position: 'relative', top: (this.tableBodyHeight - 250) / 2 }} className={this.styles.style.antBodyLoading} >
                                                    <Spin style={this.props.loading.style} indicator={this.props.loading.indicator} />
                                                    <div style={{ paddingTop: '20px' }}>
                                                        Rendering your data...
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                        :
                                        (rawData, { scrollbarSize }) => {
                                            if (this.state.shouldCalcColSize)
                                                return null;
                                            else
                                                return (<>
                                                    <List style={
                                                        {
                                                            borderBottomWidth: this.tableBody.render.bottomBorderWidth + "px"
                                                        }
                                                    } ref={this.tableBody.refCollection.gridRef}
                                                        width={this.tableWidth}
                                                        height={this.tableBodyHeight}
                                                        //itemSize={() => (this.props.rowHeight || 30)}
                                                        itemSize={(this.props.rowHeight || 30)}
                                                        itemCount={rawData.length}
                                                        overscanRowCount={2}
                                                        className={classNames(styles.navTable, "virtual-grid")}
                                                    >
                                                        {({ index, style }) => {
                                                            let newstyle = Object.assign({}, style);
                                                            newstyle.display = "flex";
                                                            newstyle.fontSize = this.props.fontSize || "1rem";
                                                            let isSelected = this.tableBody.selection.selectedindecies.find(x => x === index) !== undefined;
                                                            let className = {
                                                                'virtual-table-row': true,
                                                                'virtual-table-row-last': index === rawData.length - 1,
                                                                [styles[CSS_CLASSNAME_SELECTED]]: isSelected && !this.props.columnSelectable,
                                                                [styles[this.props.theme]]: isSelected && !this.props.columnSelectable,
                                                                [styles.loading]: isSelected && !this.props.columnSelectable,
                                                                [styles[CSS_CLASSNAME_STRIKEOUT]]: (rawData?.[index]?.[this.props.defaultStrikeControlKey]),
                                                                [CSS_CLASSNAME_SELECTED]: isSelected && !this.props.columnSelectable
                                                            };
                                                            newstyle.overflow = "hidden";
                                                            delete newstyle.width;
                                                            let children = new Array(mergedColumns.length);
                                                            const rowRef = React.createRef();
                                                            const rowPimaryKey = rawData[index][this.props.rowKey] || index;
                                                            this.rowCellRef[`row_${rowPimaryKey}`] = rowRef;
                                                            for (let i = 0; i < mergedColumns.length; i++) {
                                                                let col = mergedColumns[i];
                                                                let rowData = this.state.data[index];
                                                                const colRef = React.createRef();
                                                                const colInnerRef = React.createRef();
                                                                this.rowCellRef[`row_${rowPimaryKey}_column_${i}`] = colRef;
                                                                this.rowCellRef[`row_${rowPimaryKey}_column_${i}_inner${i}`] = {
                                                                    tableRef: this
                                                                };
                                                                let colstyle = {
                                                                    paddingLeft: col.style?.textAlign !== 'right' ? (col.sorter ? "1.2em" : '1em') : undefined,
                                                                    paddingRight: col.style?.textAlign === 'right' ? (col.sorter ? "2.2em" : '1.2em') : undefined,
                                                                }
                                                                if (col.style) {
                                                                    Object.assign(colstyle, col.style);
                                                                }
                                                                colstyle.width = this.tableBody.render.column.width[i];
                                                                children.push(
                                                                    <div className={classNames({
                                                                        [styles[CSS_CLASSNAME_SELECTED]]: isSelected && this.props.columnSelectable && this.tableBody.scrolling.columnIndex == i,
                                                                        [styles[this.props.theme]]: isSelected && this.props.columnSelectable && this.tableBody.scrolling.columnIndex == i,
                                                                        [CSS_CLASSNAME_SELECTED]: isSelected && this.props.columnSelectable && this.tableBody.scrolling.columnIndex == i
                                                                    })} style={colstyle} data-virtual-table-column-index={i} ref={colRef} tabIndex={0}>
                                                                        {mergedColumns[i].render(rowData[col["dataIndex"]], rowData, { rowindex: index, columnIndex: i }, this.rowCellRef[`row_${rowPimaryKey}_column_${i}_inner${i}`], mergedColumns)}
                                                                    </div>
                                                                );
                                                            }
                                                            const row = <div onDoubleClick={this.onRowDoubleClick} onContextMenu={this.onRowClick} onClick={this.onRowClick}
                                                                className={classNames(className)}
                                                                data-virtual-table-row-key={rowPimaryKey} data-virtual-table-row-index={index}
                                                                style={newstyle} ref={rowRef}>
                                                                {children}
                                                            </div>;
                                                            return (
                                                                <ReactMutaionWatcher connect={isSelected} options={this.tableBody.selection.mutaionWatchOptions} mutationObserver={this.tableBody.selection.mutationObserver} onChange={this.handleUnwantedClassChanges}>
                                                                    <Dropdown overlay={!this.props.contextMenu ? <div></div> : this.contextMenu} onVisibleChange={this.onDropDownChange} trigger={['contextMenu']}>
                                                                        {row}
                                                                    </Dropdown>
                                                                </ReactMutaionWatcher>
                                                            )
                                                        }}
                                                    </List>
                                                    <Grid ref={this.tableBody.refCollection.footerGridRef} className={classNames({
                                                        [styles.navTable]: true, ["virtual-grid-footer"]: true, [styles.hidden]: !this.props.footer
                                                    })}
                                                        columnCount={mergedColumns.length}
                                                        columnWidth={(i) => this.colWidth(i, mergedColumns, scrollbarSize)}
                                                        height={(this.props.rowHeight || 30) * 1.2}
                                                        rowHeight={() => ((this.props.rowHeight || 30) * 1.2)}
                                                        rowCount={1}
                                                        width={tablewidth}>
                                                        {
                                                            ({ columnIndex, style }) => {
                                                                let newstyle = Object.assign({}, style);
                                                                newstyle.paddingLeft = mergedColumns[columnIndex].sorter ? "1em" : "1em";
                                                                newstyle.paddingRight = mergedColumns[columnIndex].sorter ? "1em" : "1em";
                                                                newstyle.paddingBottom = "1em"
                                                                newstyle.overflow = "hidden";
                                                                newstyle.fontWeight = "bold"
                                                                newstyle.fontSize = this.props.fontSize || "1rem"
                                                                if (this.tableBody.selection?.selectedindecies?.length > 1 && this.props.selectionFooter) {
                                                                    newstyle.color = "white"
                                                                    newstyle.backgroundColor = "#333333";
                                                                }

                                                                if (mergedColumns[columnIndex].style)
                                                                    newstyle = Object.assign(newstyle, mergedColumns[columnIndex].style);
                                                                return (
                                                                    <div style={newstyle}>
                                                                        {
                                                                            mergedColumns[columnIndex].footer instanceof Function ?
                                                                                mergedColumns[columnIndex].footer(this.tableBody.selection.selectedindecies, this.state.data)
                                                                                :
                                                                                mergedColumns[columnIndex].footer
                                                                        }
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                    </Grid></>);
                                        }
                                }} />)
                    }
                </div>
            </>);
    }
}

export default NavigatableTable;