import React, { conte, useCallback, useEffect, useRef, useState } from 'react'
import { ReactReduxContext } from 'react-redux';
import Context from './context';
function Provider({
    children
}) {

    let listenersRef = useRef({
        onDown: {
        },
        onUp: {

        },
        modePropMap: {
            'keydown': 'onDown',
            'keyup': 'onUp'
        }
    });
    useEffect(() => {
        // let windowListener = (e) => {
        //     let key = e.key.toLowerCase();
        //     let eventListener = listenersRef.current[listenersRef.current.modePropMap[e.type]];
        //     if (eventListener) {
        //         let lastKeyId = getRecentProperty(eventListener[key]);
        //         let lastkeyListener = eventListener[key]?.[lastKeyId];
        //         let lastAllId = getRecentProperty(eventListener.all, key);
        //         let lastAllListener = eventListener.all?.[lastAllId];
        //         if (lastkeyListener && lastAllListener) {
        //             if (lastkeyListener.timeStamp > lastAllListener.timeStamp)
        //                 lastkeyListener.callBack(e);
        //             else if (lastkeyListener.timeStamp < lastAllListener.timeStamp)
        //                 lastAllListener.callBack(e);
        //             else {
        //                 lastkeyListener.callBack(e);
        //                 lastAllListener.callBack(e);
        //             }
        //         } else {
        //             lastkeyListener && lastkeyListener.callBack(e);
        //             lastAllListener && lastAllListener.callBack(e);
        //         }
        //     }
        // }
        let windowListener = async (e) => {
            let key = e.key.toLowerCase();
            let eventListener = listenersRef.current[listenersRef.current.modePropMap[e.type]];
            if (eventListener) {
                let keyListeners = getListeners(eventListener[key], key);
                let allListeners = getListeners(eventListener.all, key);
                let listeners = [...keyListeners, ...allListeners];
                listeners.sort((x, y) => {
                    if (x.timeStamp < y.timeStamp) return 1;
                    else if (x.timeStamp > y.timeStamp) return -1;
                    else return 0;
                });
                // if (e.key.toLowerCase() === 'escape')
                // debugger;
                for (let listener of listeners) {
                    let resp = listener?.callBack(e);
                    console.log(listener);
                    if (resp instanceof Promise)
                        await resp;
                    if (e.cancelBubble)
                        break;
                }
            }
        }
        window.addEventListener('keydown', windowListener);
        window.addEventListener('keyup', windowListener);
        return () => {
            window.removeEventListener('keydown', windowListener);
            window.addEventListener('keyup', windowListener);
        }
    }, []);
    let state = useState(() => ({
        addOrUpdate: (options) => {
            let listener = listenersRef.current;
            if (options instanceof Array) {
                options.forEach(x => {
                    let eventListener = listener[listener.modePropMap[x.event]];
                    if (x.keys instanceof Array) {
                        x.keys.forEach(key => {
                            key = key.toLowerCase();
                            (eventListener[key]
                                &&
                                (
                                    (Object.keys(eventListener[key]).find(objKey => objKey === x.id)
                                        &&
                                        Object.defineProperty(eventListener[key][x.id], 'callBack', {
                                            configurable: true,
                                            enumerable: true,
                                            value: x.listener,
                                            writable: false
                                        })
                                        &&
                                        Object.defineProperty(eventListener[key][x.id], 'exceptions', {
                                            configurable: true,
                                            enumerable: true,
                                            value: x.exceptions,
                                            writable: false
                                        }))
                                    ||
                                    Object.defineProperty(eventListener[key], x.id, {
                                        configurable: true,
                                        enumerable: true,
                                        value: {
                                            callBack: x.listener,
                                            timeStamp: (new Date()).getTime(),
                                            exceptions: x.exceptions
                                        },
                                        writable: false
                                    })
                                )
                            )
                                ||
                                Object.defineProperty(eventListener, key, {
                                    configurable: true,
                                    enumerable: true,
                                    value: {
                                        [x.id]: {
                                            callBack: x.listener,
                                            timeStamp: (new Date()).getTime(),
                                            exceptions: x.exceptions
                                        }
                                    },
                                    writable: false
                                })
                        });
                    } else {
                        (eventListener.all
                            &&
                            (
                                (Object.keys(eventListener.all).find(key => key === x.id)
                                    &&
                                    Object.defineProperty(eventListener.all[x.id], 'callBack', {
                                        configurable: true,
                                        enumerable: true,
                                        value: x.listener,
                                        writable: false
                                    })
                                    &&
                                    Object.defineProperty(eventListener.all[x.id], 'exceptions', {
                                        configurable: true,
                                        enumerable: true,
                                        value: x.exceptions,
                                        writable: false
                                    }))
                                ||
                                Object.defineProperty(eventListener.all, x.id, {
                                    configurable: true,
                                    enumerable: true,
                                    value: {
                                        callBack: x.listener,
                                        timeStamp: (new Date()).getTime(),
                                        exceptions: x.exceptions
                                    },
                                    writable: false
                                })
                            )
                        )
                            ||
                            Object.defineProperty(eventListener, 'all', {
                                configurable: true,
                                enumerable: true,
                                value: {
                                    [x.id]: {
                                        callBack: x.listener,
                                        timeStamp: (new Date()).getTime(),
                                        exceptions: x.exceptions
                                    }
                                },
                                writable: false
                            })
                    }
                });
            }
        },
        remove: (id, event) => {
            let listener = listenersRef.current;
            if (event === undefined || event === 'keydown') {
                Object.keys(listener.onDown).forEach(key => {
                    listener.onDown[key][id] && delete listener.onDown[key][id];
                })
            }
            if (event === undefined || event === 'keyup') {
                Object.keys(listener.onUp).forEach(key => {
                    listener.onUp[key][id] && delete listener.onUp[key][id];
                })
            }
        }
    }));
    return (<div>
        <Context.Provider value={state[0]}>
            {children}
        </Context.Provider>
    </div>);
}

export default Provider;

function getRecentProperty(obj, key) {
    return (
        obj
        &&
        Object.keys(obj)
            .reduce(
                (recentProperty, currentProperty) =>
                (
                    (
                        obj[recentProperty]?.timeStamp > obj[currentProperty]?.timeStamp
                        &&
                        obj[recentProperty]?.exceptions?.find(x => x.toLowerCase() === key) === undefined
                    )
                        ?
                        recentProperty
                        :
                        (
                            obj[currentProperty]?.exceptions?.find(x => x.toLowerCase() === key) === undefined
                                ?
                                currentProperty
                                :
                                undefined
                        )
                )
                , undefined)
    );
}

function getListeners(obj, key) {
    if (!obj)
        return [];
    let Listeners = [];
    Object.keys(obj).forEach(x => {
        if (obj[x]?.exceptions?.find(x => x.toLowerCase() === key) === undefined)
            Listeners.push(obj[x]);
    });
    // console.log(Listeners);
    // console.log(obj);
    // console.log(key);
    return Listeners;
}