
// @ts-check
import React from 'react';
import { Spin, Card, message, Table, Empty, Switch, } from 'antd';
import * as Icons from '@ant-design/icons';
import styles from './paymentsdisplay.module.scss';
import Axios from 'axios';
import { connect } from 'react-redux';
import Nt from '../navigatableTable'
import classNames from 'classnames';
import EPC from '../extendedPopConfirm';
import { formatAsCurrency, getComputedStyle, onPaymentDelete, toTitleCase, wait } from '../../types/helper';
import StyledBtn from '../styledButton';
import { updateAppStartAndAppDateState } from '../../redux/actions';
import PeriodSelector from '../PeriodSelector'
import { BooksTitleBar } from '../titleBar';
import { deletePayment } from '../../apiTalk/apActions.js';
import KeyListen from '../KeyListener'
import { format, isSameDay, parse } from 'date-fns';
import nextWednesday from 'date-fns/fp/nextWednesday/index';
// import FocusTrap from '../FocusTrap';

const mapStateToProps = state => {
    return {
        theme: state.appState.theme,
        locale: state.appState.locale,
        selectedCompany: state.masterState.cinfo.selectedCompany,
        apiHost: state.appState.apiHost,
        token: state.appState.userState.jwt,
        userName: state.appState.userState.userEmail,
        adminLevel: state.appState.userState.userAdminLevel,
        appDate: state.masterState.date,
        appStartDate: state.masterState.startDate,
        maxDate: state.masterState.maxDate,
        minDate: state.masterState.minDate
    };
}
const columns = [
    { title: 'A', dataIndex: 'key', width: 150 },
    { title: 'B', dataIndex: 'key' },
    { title: 'C', dataIndex: 'key' },
    { title: 'D', dataIndex: 'key' },
    { title: 'E', dataIndex: 'key', width: 200 },
    { title: 'F', dataIndex: 'key', width: 100 },
];

const data = Array.from({ length: 100000 }, (_, key) => ({ key }));
const mapDispatchtoProps = dispatch => {
    return {
        updateStartAndAppDate: (startDate, appDate) => dispatch(updateAppStartAndAppDateState(startDate, appDate))
    }
}
class PaymentsDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.componentStateFactory();
        this.bindfunctions();
        this.keyListenProps = {
            down: { downKeys: ['v', 'g', 'o', 'f2', 'insert'], downCallBack: this.keyPressHandler }
        }
        this.NavTableRef = React.createRef();
        this.dateModalRef = React.createRef();
        this.paymentsTableContextMenu = [{
            title: 'Open',
            callback: obj => {
                const state = this.state;
                state.view.table.selectedKey = obj.paymentNo;
                this.props.history.push('/view?pno=' + obj.paymentNo, {
                    path: this.props.location,
                    state: state
                })
            },
            icon: <Icons.FolderOpenOutlined />,
            disbleonMultiSelect: true
        },
        {
            title: 'Delete',
            callback: this.onPaymentDelete,
            icon: <Icons.DeleteOutlined />
        }];
    }
    bindfunctions() {
        this.setFocus = this.setFocus.bind(this);
        this.onPaymentClick = this.onPaymentClick.bind(this);
        this.onPaymentDelete = onPaymentDelete.bind(this, this);
        this.keyPressHandler = this.keyPressHandler.bind(this);
    }
    componentStateFactory(_fetching = true, _payments = null, _error = false, _errorMessage = '', _isList = true, _isGrouped = false) {
        return {
            data: {
                loading: _fetching,
                payments: _payments,
                error: _error,
                errorMessage: _errorMessage
            },
            view: {
                isList: _isList,
                groupDisplay: _isGrouped,
                groupKeys: ['date'],
                table: {
                    height: 0,
                    width: 0,
                    selectedKey: undefined
                }
            }
        };
    }
    componentDidMount() {
        if (this.props.location?.state?.data?.payments) {
            this.setState(this.props.location.state);
            this.props.history.replace({ ...this.props.history.location, state: {} });

        }
        else
            this.refreshBills();
        this.props.location.state = undefined;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedCompany != this.props.selectedCompany) {
            this.refreshBills();
        }
        if (prevProps.appDate != this.props.appDate || prevProps.appStartDate != this.props.appStartDate) {
            this.refreshBills();
        }
        this.setFocus();
    }
    refreshBills() {
        if (this.state.view.groupDisplay)
            this.fetchGroupedData();
        else
            this.fetchPaymentsSkeleton();
    }
    async fetchGroupedData() {
        if (!this.props.selectedCompany) {
            return;
        }
        try {
            this.setState({
                data: {
                    loading: true,
                },
                view: {
                    isList: this.state.view.isList,
                    groupDisplay: true,
                    groupKeys: this.state.view.groupKeys,
                    table: {
                        height: this.state.view.table.height,

                        width: this.state.view.table.width
                    }
                }
            });
            let groupFields = this.state.view.groupKeys;
            let resp = await Axios.get(`${this.props.apiHost}/collections/payment?cinfo=${this.props.selectedCompany.key}&startDate=${this.props.appStartDate.toISOString()}&endDate=${this.props.appDate.toISOString()}&group&groupFields=${JSON.stringify(groupFields)}`, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            resp.data.responseData.sort((a, b) => {
                if (a.date < b.date) return 1;
                else if (a.date > b.date) return -1;
                return 0;
            });
            let i = 0;
            resp.data.responseData.forEach(x => x.primaryKey = i++);
            this.setState({
                data: {
                    payments: resp.data.responseData,
                    loading: false
                },
                view: {
                    isList: this.state.view.isList,
                    groupDisplay: true,
                    groupKeys: this.state.view.groupKeys,
                    table: {
                        height: this.state.view.table.height,
                        width: this.state.view.table.width
                    }
                }
            });
        } catch (err) {
            if (err.response && err.response.status === 404) {
                this.setState({
                    data: {
                        payments: null,
                        loading: false
                    },
                    view: {
                        isList: this.state.view.isList,
                        groupDisplay: true,
                        groupKeys: this.state.view.groupKeys,
                        table: {
                            height: this.state.view.table.height,
                            width: this.state.view.table.width
                        }
                    }
                });
                return;
            }
            this.setState({
                data: {
                    payments: null,
                    loading: false,
                    error: true,
                    errorMessage: err.message
                },
                view: {
                    isList: this.state.view.isList,
                    groupDisplay: true,
                    groupKeys: this.state.view.groupKeys,
                    table: {
                        height: this.state.view.table.height,
                        width: this.state.view.table.width
                    }
                }
            });
        }
    }
    setFocus() {
        if ((this.dateModalRef.current && this.dateModalRef.current.modalVisibility) || (this.state.view.isList && this.state.data.payments))
            return;
        let nodelist = document.getElementsByClassName(styles.displayWrapper)
        let wrapper = nodelist.length > 0 ? nodelist[0] : undefined;
        if (wrapper) {
            // @ts-ignore
            wrapper.focus();
        }
    }
    configMessagePopup() {
        message.config({
            top: 10,
            duration: 2,
            maxCount: 1,
        });
    }
    async fetchPaymentsSkeleton() {
        if (!this.props.selectedCompany) {
            return;
        }
        try {
            this.setState({
                data: {
                    loading: true,
                },
                view: {
                    isList: this.state.view.isList,
                    groupDisplay: false,
                    groupKeys: this.state.view.groupKeys,
                    table: {
                        height: this.state.view.table.height,
                        width: this.state.view.table.width
                    }
                }
            });
            let resp = await Axios.get(`${this.props.apiHost}/collections/payment?cinfo=${this.props.selectedCompany.key}&startDate=${this.props.appStartDate.toISOString()}&endDate=${this.props.appDate.toISOString()}`, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            for (let pmt of resp.data.responseData.payment) {
                pmt.cusname_clnt = pmt.customer.cusname;
                pmt.paymentDate_clnt = format(new Date(pmt.paymentDate), 'dd-MM-yyyy');
                pmt.subCompany_clnt = pmt.subCompany.subCompanyName;
                pmt.user_clnt = pmt.editUserDisplayName || pmt.userDisplayName;
            }
            this.setState(this.componentStateFactory(false, resp.data.responseData.payment, false, '', this.state.view.isList));
        } catch (err) {
            if (err.response && err.response.status === 404) {
                this.setState(this.componentStateFactory(false, null, false, '', this.state.view.isList));
                return;
            }
            this.setState(this.componentStateFactory(false, null, true, err.message, this.state.view.isList));
        }
    }
    /**
     * 
     * @param {EPCReferanceValue} refValue 
     */
    async deletePayment(refValue) {
        try {
            if (!refValue)
                return;
            refValue.ref?.current?.manualStateSet?.(true);
            try {
                await deletePayment(this.props.selectedCompany.key, refValue.paymentNo, this.props.token);
            } catch (err) {
                throw err;
            }
            let deletedPayment = this.state.data.payments.find(x => x.paymentNo == refValue.paymentNo);
            if (deletedPayment) {
                deletedPayment.status = 0;
            }
            if (refValue.ref && refValue.ref.current)
                refValue.ref.current.manualStateSet(false);
            let state = Object.assign({}, this.state);
            this.setState(state);
        } catch (err) {
            message.error('Error deleting.');
        }
    }
    getGroupList() {
        if (this.state.data.loading)
            return <Table loading={this.getSpin()} />;
        else
            return <Nt theme={this.props.theme.className} ref={this.NavTableRef} rowKey={'primaryKey'} columns={this.getGroupColumns()} dataSource={this.state.data.payments} pagination={false} rowHeight={20} />;
    }
    getGroupCards() {
        let cardArr = [];

        if (this.state.data.payments) {
            cardArr.push(this.state.data.payments.map(x => {
                return (<Card className={classNames(styles.cardPayment)} title={x.methodName} >
                    <div className={classNames(styles.wrapper, styles[this.props.theme.className])}>
                        <p>Date: {format(x.date, 'dd-MM-yyyy')}</p>
                        <p>Sub-Company: {x.subCompany}</p>
                        <p>Amount Collected: {formatAsCurrency(x.collectedAmount, this.props.locale)}</p>
                        <p>Cash Discount: {formatAsCurrency(x.cd, this.props.locale)}</p>
                        <p>Total Amount: {formatAsCurrency(x.TotalAmount, this.props.locale)}</p>
                        <p>User: {x.user}</p>
                        <p>Status: {x.status == 0 ? 'Deleted' : x.status == 1 ? 'Uploaded' : x.status == 2 ? 'Ok' : 'Needs Approval'}</p>
                    </div>

                </Card>);
            }));
        }
        return cardArr;
    }
    addRemoveGroupColumn(value, addRemove) {
        let gkeys = this.state.view.groupKeys;
        let view = Object.assign({}, this.state.view);
        if (addRemove) {
            let e = gkeys.find(x => x === value);
            if (!e) {
                let nwarr = [...gkeys, value];
                view.groupKeys = nwarr;
                this.setState({ view }, () => this.fetchGroupedData());
            }
        } else {
            let nwArr = gkeys.filter(x => x !== value);
            view.groupKeys = nwArr;
            this.setState({ view }, () => this.fetchGroupedData());
        }
    }
    getGroupDisplay() {
        return (<div>
            <div className={classNames(styles.filterBar, styles[this.props.theme.className])}>
                <div className={classNames(styles.filterLabel, styles[this.props.theme.className])}>
                    <div className={classNames(styles.filterLabelInner, styles[this.props.theme.className])}>
                        <Icons.FilterOutlined />
                        <h3>Group Options</h3>
                    </div>
                </div>
                <div className={classNames(styles.filterInner, styles[this.props.theme.className])}>
                    <div className={classNames(styles.filterItem, styles[this.props.theme.className])}>
                        <h4>Sub Company </h4>
                        <Switch disabled={!this.state.data.payments} defaultChecked={this.state.view.groupKeys.find(x => x === 'sccode') ? true : false} onChange={c => this.addRemoveGroupColumn('sccode', c)} />
                    </div>
                    <div className={classNames(styles.filterItem, styles[this.props.theme.className])}>
                        <h4>User </h4>
                        <Switch disabled={!this.state.data.payments} defaultChecked={this.state.view.groupKeys.find(x => x === 'user') ? true : false} onChange={c => this.addRemoveGroupColumn('user', c)} />
                    </div>
                    <div className={classNames(styles.filterItem, styles[this.props.theme.className])}>
                        <h4>Payment Method </h4>
                        <Switch disabled={!this.state.data.payments} defaultChecked={this.state.view.groupKeys.find(x => x === 'bank') ? true : false} onChange={c => this.addRemoveGroupColumn('bank', c)} />
                    </div>
                    <div className={classNames(styles.filterItem, styles[this.props.theme.className])}>
                        <h4>Approval Status</h4>
                        <Switch disabled={!this.state.data.payments} defaultChecked={this.state.view.groupKeys.find(x => x === 'status') ? true : false} onChange={c => this.addRemoveGroupColumn('status', c)} />
                    </div>
                </div>
            </div>
            {
                /*this.state.view.isList ? this.getGroupList() : this.getGroupCards()*/
                this.getGroupList()
            }
        </div>);

    }
    getPaymentsDisplay() {
        return this.state.data.payments ? (this.state.view.isList ? this.getPaymentsList() : this.getPaymentCards()) : this.getNoData();
    }
    getGroupColumns() {
        let colarr = [
            {
                title: "Date",
                dataIndex: "date",
                key: "date",
                render: text => {
                    return <span>{format(new Date(text), 'dd-MM-yyyy')}</span>
                },
                sorter: true,
                width: 5,
                minWidth: '15ch'
            }
        ];
        if (this.state.view.groupKeys.find(x => x === 'sccode'))
            colarr.push(
                {
                    title: "Sub Company",
                    dataIndex: "subCompany",
                    key: "subCompany",
                    render: text => <span>{text}</span>,
                    sorter: true,
                    width: 20,
                    minWidth: '25ch'
                });
        if (this.state.view.groupKeys.find(x => x === 'user'))
            colarr.push(
                {
                    title: "User",
                    dataIndex: "user",
                    key: "user",
                    render: text => <span>{text}</span>,
                    sorter: true,
                    width: 20,
                    minWidth: '25ch'
                });
        if (this.state.view.groupKeys.find(x => x === 'status'))
            colarr.push(
                {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: text => <span>{text == 0 ? 'Deleted' : text == 1 ? 'Uploaded' : text == 2 ? 'Ok' : 'Needs Approval'}</span>,
                    sorter: true,
                    width: 20,
                    minWidth: '25ch'
                });
        if (this.state.view.groupKeys.find(x => x === 'bank'))
            colarr.push(
                {
                    title: "Method",
                    dataIndex: "methodName",
                    key: "methodName",
                    render: text => <span>{toTitleCase(text)}</span>,
                    sorter: true,
                    width: 20,
                    minWidth: '25ch'
                });
        // @ts-ignore
        let amtCols = [{
            title: "Amount Received",
            dataIndex: "collectedAmount",
            key: "collectedAmount",
            render: text => <span>{formatAsCurrency(text, this.props.locale)}</span>,
            sorter: true,
            width: 5,
            minWidth: '25ch',
            style: {
                textAlign: 'right'
            }
        },
        {

            title: "Cash Discount",
            dataIndex: "cd",
            key: "cd",
            render: text => <span>{formatAsCurrency(text, this.props.locale)}</span>,
            sorter: true,
            width: 5,
            minWidth: '25ch',
            style: {
                textAlign: 'right'
            }

        },
        {
            title: "Total Amount",
            dataIndex: "TotalAmount",
            key: "TotalAmount",
            render: text => <span>{formatAsCurrency(text, this.props.locale)}</span>,
            sorter: true,
            width: 5,
            minWidth: '25ch',
            style: {
                textAlign: 'right'
            }
        }];
        return [...colarr, ...amtCols, {
            title: "",
            dataIndex: "TotalAmount",
            key: "TotalAmount",
            render: text => null,
            style: {
                textAlign: 'right'
            }
        }];
    }
    getActions(x) {
        let link = `/view?pno=${x.paymentNo}`;
        // @ts-ignore
        let func = async () => await this.deletePayment(x.number);
        let buttonref = React.createRef();
        return (
            <div className={classNames(styles.actionsButton)}>
                <a href={link}>
                    <StyledBtn icon={(x.status == 0 ? Icons.FolderOpenOutlined : Icons.EditOutlined)} theme={this.props.theme.className} mode='onDark' />
                </a>
                <EPC placement="left" title='Are you sure?' referanceValue={{ ref: buttonref, paymentNo: x.paymentNo }} onConfirm={this.deletePayment} >
                    <StyledBtn ref={buttonref} icon={Icons.DeleteOutlined} referenceValue={x.paymentNo} disabled={x.status == 0} theme={this.props.theme.className} mode='onDark' />
                </EPC>
            </div>);
    }
    getPaymentCards() {
        let cardArr = [];
        if (this.state.data.payments) {
            cardArr.push(this.state.data.payments.map(x => {
                return (<Card className={classNames(styles.cardPayment)} title={x.customer.cusname} key={x.paymentNo} extra=
                    {
                        this.getActions(x)
                    }>
                    <div className={classNames(styles.wrapper, x.status == 0 ? styles.deleted : undefined, styles[this.props.theme.className])}>
                        <div className={classNames(styles.statusWrapper, x.status != 0 ? styles.hidden : undefined, styles[this.props.theme.className])}>
                            <h1>Deleted</h1>
                        </div>
                        <p>Payment No: {x.paymentNo} | Date: {format(x.paymentDate, 'dd-MM-yyyy')}</p>
                        <p>Amount Collected: {formatAsCurrency(x.amountActual, this.props.locale)}</p>
                        <p>Cash Discount: {formatAsCurrency(x.amountDiscount, this.props.locale)}</p>
                        <p>Total Amount: {formatAsCurrency(x.amountTotal, this.props.locale)}</p>
                        <p>Company: {x.subCompany.subCompanyName}</p>
                        {x.editUserDisplayName ?
                            <p>Last Edited by: {x.editUserDisplayName}</p>
                            :
                            <p>Created By: {x.userDisplayName}</p>}
                        <p>Status: {x.status == 0 ? 'Deleted' : x.status == 1 ? 'Uploaded' : x.status == 2 ? 'Ok' : 'Needs Approval'}</p>
                    </div>

                </Card>);
            }));
        }
        return cardArr;
    }
    getPaymentColumns() {
        return [
            {
                title: "Payment No",
                dataIndex: "paymentNo",
                key: "paymentNo",
                // @ts-ignore
                render: (text, record) => {
                    return (<span>{text}</span>);
                },
                columnType: "responsive",
                dataType: 'number',
                sorter: true,
                width: 7,
                minWidth: '11ch',
            },
            {
                title: "Date",
                dataIndex: "paymentDate_clnt",
                key: "paymentDate_clnt",
                // @ts-ignore
                render: (text, record) => { return (<span>{text}</span>); },
                sorter: true,
                dataType: 'date',
                sortValue: 'paymentDate',
                columnType: "responsive",
                width: 5,
                minWidth: '12ch',
            },
            {
                title: "Customer",
                dataIndex: "cusname_clnt",
                key: "cusname_clnt",
                // @ts-ignore
                render: (text, record) => { return (<span>{record.customer.cusname + ', ' + record.customer.place}</span>); },
                dataType: 'string',
                columnType: "responsive",
                footer: "Total",
                sorter: true,
                width: 22,
                minWidth: '25ch',
            },
            {
                title: "Amount Received",
                dataIndex: "amountActual",
                key: "amountActual",
                // @ts-ignore
                render: (text, record) => { return (<span>{formatAsCurrency(text, this.props.locale)}</span>); },
                dataType: 'number',
                columnType: "responsive",
                width: 9,
                minWidth: '15ch',
                align: 'right',
                style: {
                    textAlign: 'right'
                },
                sorter: true,
                footer: (selectedItems, sortedData) => {
                    let val = 0;
                    if (!selectedItems?.length || selectedItems?.length <= 1)
                        val = this.state.data.payments.reduce((acc, val, i, a) => acc + val.amountActual, 0);
                    else
                        val = selectedItems.reduce((acc, val, i, a) => acc + (sortedData[val]?.amountActual || 0), 0);
                    return formatAsCurrency(val, this.props.locale)
                },
            },
            {

                title: "Cash Discount",
                dataIndex: "amountDiscount",
                key: "amountDiscount",
                // @ts-ignore
                render: (text, record) => { return (<span>{formatAsCurrency(text, this.props.locale)}</span>); },
                dataType: 'number',
                columnType: "responsive",
                width: 8,
                minWidth: '14ch',
                align: 'right',
                style: {
                    textAlign: 'right'
                },
                sorter: true,
                footer: (selectedItems, sortedData) => {
                    let val = 0;
                    if (!selectedItems?.length || selectedItems?.length <= 1)
                        val = this.state.data.payments.reduce((acc, val, i, a) => acc + val.amountDiscount, 0);
                    else
                        val = selectedItems.reduce((acc, val, i, a) => acc + (sortedData[val]?.amountDiscount || 0), 0);
                    return formatAsCurrency(val, this.props.locale)
                }
            },
            {
                title: "Total Amount",
                dataIndex: "amountTotal",
                key: "amountTotal",
                // @ts-ignore
                render: (text, record) => { return (<span>{formatAsCurrency(text, this.props.locale)}</span>); },
                dataType: 'number',
                columnType: "responsive",
                width: 8,
                minWidth: '15ch',
                align: 'right',
                style: {
                    textAlign: 'right'
                },
                sorter: true,
                footer: (selectedItems, sortedData) => {
                    let val = 0;
                    if (!selectedItems?.length || selectedItems?.length <= 1)
                        val = this.state.data.payments.reduce((acc, val, i, a) => acc + val.amountTotal, 0);
                    else
                        val = selectedItems.reduce((acc, val, i, a) => acc + (sortedData[val]?.amountTotal || 0), 0);

                    return formatAsCurrency(val, this.props.locale)
                }
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                // @ts-ignore
                sorter: true,
                dataType: 'string',
                render: (text, record) => { return (<span>{text == 0 ? 'Deleted' : text == 1 ? 'Uploaded' : text == 2 ? 'Ok' : 'Needs Approval'}</span>); },
                columnType: "responsive",
                width: 8,
                minWidth: '14ch',
                align: 'left',
            },
            {
                title: "Sub Company",
                dataIndex: "subCompany_clnt",
                key: "subCompany_clnt",
                // @ts-ignore
                dataType: 'string',
                minWidth: '25ch',
                sorter: true,
                render: (text, record) => { return (<span> {text} </span>); },
                columnType: "responsive",
                width: 10,
            },
            {
                title: "Last Edit by Or Created by",
                dataIndex: "user_clnt",
                key: "user_clnt",
                dataType: 'string',
                // @ts-ignore
                sorter: true,
                render: (text, record) => {
                    return (<span>{text}</span>);
                },
                columnType: "responsive",
                width: 12,
                minWidth: "20ch",
            }, {
                name: "loading",
                title: "",
                dataIndex: "user_clnt",
                key: "user_clnt",
                dataType: 'string',
                // @ts-ignore
                sorter: false,
                render: (text, record, indexOpt, ref) => {
                    let loadingRef = React.createRef();
                    ref.loadingRef = loadingRef;
                    return <StyledBtn ref={loadingRef} theme={this.props.theme.className} mode={'onDark'} borderLess={true} />;
                },
                columnType: "responsive",
            }
        ]
    }
    getSpin() {
        let antIcon = <Icons.Loading3QuartersOutlined style={{ fontSize: '5em' }} spin />
        return {
            indicator: antIcon, style: {
                color: this.props.theme.primary1,
            },
            isloading: this.state.data.loading
        };
    }
    onPaymentClick(paymentObjs) {
        if (paymentObjs?.length !== 1)
            return;
        const paymentObj = paymentObjs[0];
        if (!paymentObj)
            return;
        const state = Object.assign({}, this.state);;
        const str = JSON.stringify(state)
        state.view.table.selectedKey = paymentObj.paymentNo;

        this.props.history.push(`/view?pno=${paymentObj.paymentNo}`,
            {
                path: this.props.location,
                state: state
            });
    }
    getPaymentsList() {
        // @ts-ignore
        let table = (<Nt defaultSelectedBillKey={this.state.view.table.selectedKey} loading={this.getSpin()} footer contextMenu={this.paymentsTableContextMenu} tabIndex={100} onEnter={this.onPaymentClick} onDelete={this.onPaymentDelete} onRowDoubleClick={this.onPaymentClick} theme={this.props.theme.className} ref={this.NavTableRef} rowKey={'paymentNo'} columns={this.getPaymentColumns()} dataSource={this.state.data.payments} pagination={false} fontSize={"1rem"} rowHeight={Math.round(getComputedStyle("1.666rem").w)} selectionFooter />);
        return table;
    }
    /**
     * 
     * @param {Date} sd 
     * @param {Date} ed 
     */
    handleDateChange(sd, ed) {
        if (!isSameDay(sd, this.props.appStartDate) || !isSameDay(ed, this.props.appDate)) {
            this.props.updateStartAndAppDate(sd, ed);
        }
    }
    isDateModalOpen = false;
    changeView() {
        let state = {
            ...this.state
        };
        state.view = {
            isList: !this.state.view.isList,
            groupDisplay: state.view.groupDisplay,
            groupKeys: this.state.view.groupKeys,
            table: {
                height: this.state.view.table.height,
                width: this.state.view.table.width
            }
        }
        this.setState(state);
    }
    groupUngroup() {
        if (this.state.view.groupDisplay)
            this.fetchPaymentsSkeleton();
        else
            this.fetchGroupedData();
    }
    keyPressHandler(e) {
        if (e.key === 'Insert') {
            this.props.history.push('/new', {
                path: this.props.location,
                state: this.state
            });
        }
        if (e.key.toLowerCase() === 'v' && e.altKey) {
            this.changeView();
        }
        if (e.key.toLowerCase() === 'g' && e.altKey) {
            this.groupUngroup();
        }
        if (e.key.toLowerCase() === 'o' && e.altKey) {
            this.props.history.push('outstandings');
        }
        if (e.key.toLowerCase() === 'f2') {
            console.log('Trapped Upper');
            if (this.dateModalRef.current)
                this.dateModalRef.current.changeDateModalVisibility(true);
        }
    }
    getNoData() {
        return (
            <div className={styles.empty}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>);
    }
    /**
     * @type {Array.<import('../titleBar').TilebarShortCut>}
     */
    get shortCuts() {
        return [{
            Key: 'Insert',
            ActionName: 'New Payment',
            Action: () => this.props.history.push('/new', {
                path: this.props.location,
                state: this.state
            })
        },
        // this.state.view.groupDisplay ? undefined :
        //     {
        //         Key: 'Alt+V',
        //         ActionName: 'Change View',
        //         Action: () => this.changeView()
        //     },
        {
            Key: 'Alt+G',
            ActionName: 'Group / Ungroup',
            Action: () => this.groupUngroup()
        },
        {
            Key: 'Alt+O',
            ActionName: 'Outstandings',
            Action: () => this.props.history.push('/outstandings')
        }
        ];
    }
    render() {
        if (this.state.data.loading) {
            let component = (<div className={classNames(styles.spinWrapper, styles[this.props.theme.className])}>
                <Spin size='large' />
                <h3 style={{ paddingTop: '1.875rem' }}>Fetching your bills  ...</h3>
            </div>);
            return component;
        }
        else {
            let component = (
                <>
                    <KeyListen down={this.keyListenProps.down} />
                    {/* <FocusTrap classtoFocus={styles.root}/> */}
                    <div tabIndex={0} className={classNames(styles.root, styles[this.props.theme.className])} >
                        <BooksTitleBar shortCuts={[...this.shortCuts, ...(this.props.adminLevel ? [] : [])]} theme={this.props.theme.className} titleName="Payments Display" titleLeft={`Payments by All Users`}
                            titleRight=
                            {this.props.appStartDate.toDateString() === this.props.appDate.toDateString() ? `Date: ${format(this.props.appDate, 'dd-MM-yyyy')}` :
                                `Period: ${format(this.props.appStartDate, 'dd-MM-yyyy')} - ${format(this.props.appDate, 'dd-MM-yyyy')}`}
                        />
                        <div tabIndex={200} className={classNames(styles.displayWrapper, this.state.view.isList || this.state.view.groupDisplay || !this.state.data.payments ? null : styles.grid, styles[this.props.theme.className])}>
                            {this.state.view.groupDisplay ? this.getGroupDisplay() : this.getPaymentsDisplay()}
                        </div>
                        <PeriodSelector getCurrentDate={() => ({ startDate: this.props.appStartDate, endDate: this.props.appDate })} mode={1} maxDate={this.props.maxDate} minDate={this.props.minDate} onClose={() => this.setFocus()} ref={this.dateModalRef} DateChangeDispatcher={(sd, ed) => this.handleDateChange(sd, ed)} theme={this.props.theme.className} />
                    </div>
                </>);
            return component;
        }
    }
}
const EAMpaymentDisplay = connect(mapStateToProps, mapDispatchtoProps)(PaymentsDisplay);
export default EAMpaymentDisplay;

/**
 * @typedef EPCReferanceValue
 * @property {React.RefObject<any>} [ref]
 * @property {number} paymentNo
 */