// @ts-check
import React from 'react';
import { SaveOutlined, Loading3QuartersOutlined, PlusOutlined, ReloadOutlined, DeleteOutlined } from "@ant-design/icons"
import EAMCustomerSelect from "./customerSelector.jsx";
import EAMSubcompanySelector from "./SubCompanySelector";
import EAMInputNumber from './eamInputNumber'
import { withRouter } from "react-router-dom";
import styles from './EAMpayments.module.css';
import classNames from 'classnames';
import { Table, Spin, Input, message, notification } from 'antd';
import EPC from './extendedPopConfirm'
import EAMAccountSelector from './accountSelector';
import { formatAsCurrency, uuidv4 } from '../types/helper';
import Axios from 'axios';
import UpdataleStat from './EAMStatistic';
import { connect } from 'react-redux';
import StyledBtn from './styledButton'
import { PerformanceInput as Pi } from './PerformanceInput';
import { dateformatList } from '../types/constants.js';
import Pdp from './performanceDatePicker';
import * as helper from '../types/helper.jsx';
import KeyListenPure from './KeyListener/index.jsx';
import { format, isSameDay, isAfter, isBefore } from 'date-fns';

/** 
 * 
 * handle payment methods chamnge if any
 * implement query string parsing
 * 
 * 
*/

/**
 * @param {import('../redux/store').appMasterStare} state 
 */

const mapStateToProps = state => {
    return {
        theme: state.appState.theme,
        selectedCompany: state.masterState.cinfo.selectedCompany,
        locale: state.appState.locale,
        apiHost: state.appState.apiHost,
        token: state.appState.userState.jwt,
        minDate: state.masterState.minDate,
        maxDate: state.masterState.maxDate,
        userAdminLevel: state.appState.userState.userAdminLevel
    };
}
let locale = 'en-IN';

/**
 * @typedef componentState
 * @prop {import('../types/theme').darkTheme} theme
 * @prop {string}  locale
 * @prop {string} apiHost
 * @prop {string} devAPIKey
 * @prop {import('../redux/reducer').subCompanyState} subCompanyState
 * @prop {import('../redux/reducer').customerState} customerState
 * @prop {import('../redux/reducer').EAMCompany} selectedCompany
 * @prop {string} token
 * @prop {boolean} [new]
 * @prop {boolean} [userAdminLevel]
 * @prop {function(import('../redux/reducer').action):void} updateCustomerState
 * @prop {function(import('../redux/reducer').action):void} updateSubCompanyState
 * @prop {import('history').History} history
 * @prop {import('history').Location} location
 * @extends {React.Component<componentState>}
 */
class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = Payment.compStateFactory(false, false, '', [], this.props.maxDate);
        locale = this.props.locale;
        this.amountUpdate = undefined;
        this.balanceUpdate = undefined;
        this.getBalanceValue = undefined;
        this.styles = styles;
        this.firstBillRef = undefined;
        this.lastBillRef = undefined;
        this.guid = uuidv4();
        this.bindHelperFunctions();
        this.createRefs();
        this.shouldFocusinBill = false;
        this.detailsTableRef = React.createRef();
        this.keyListenerProps = { name: 'paymentForm', down: { downCallBack: this.keyPresshandler, downKeys: ['home', 's', 'n', 'escape'] } };
    }


    bindHelperFunctions() {
        this.keyPresshandler = this.keyPresshandler.bind(this);
        this.fetchBills = this.fetchBills.bind(this);
        this.onAmountChange = this.onAmountChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.refreshBills = this.refreshBills.bind(this);
        this.expandableRowRender = this.expandableRowRender.bind(this);
        this.deletePayment = this.deletePayment.bind(this);
        this.onDateSelect = this.onDateSelect.bind(this);
        this.setTableHeight = this.setTableHeight.bind(this);
        this.dateValidator = this.dateValidator.bind(this);
    }
    async createRefs() {
        this.CustomerUIRef = React.createRef();
        this.SubcompanyRef = React.createRef();
        this.newbuttonRef = React.createRef();
        this.saveButtonRef = React.createRef();
        this.PaymentDateRef = React.createRef();
    }
    static rowRefFactory() {
        return {
            AmountInputRef: React.createRef(),
            CDInputRef: React.createRef(),
            PaymentMethodSelectRef: React.createRef(),
            RemarksInputRef: React.createRef()
        }
    }
    async displayPayment() {
        try {
            await this.getPayment();
        } catch (err) {
            this.showNotFoundMessage();
        }
    }
    /**
     * 
     * @param {boolean} _fb 
     * @param {boolean} _fe 
     * @param {string} _fem 
     * @param {Array.<import('../types/types').EAMBill>} _bills 
     * @return {PaymentComponentState}
     */
    static compStateFactory(_fb = false, _fe = false, _fem = '', _bills = [], maxDate = new Date()) {
        return {
            fetchingBill: _fb,
            bills: _bills,
            fetchError: _fe,
            fetchErrorMsg: _fem,
            paymentNo: undefined,
            paymentDate: maxDate.toISOString(),
            paymentStatus: undefined,
            selectedCustomer: undefined,
            selectedCustomerName: undefined,
            selectedSubCompany: undefined,
            selectedSubCompanyName: undefined,
            paymentHostStatus: undefined,
            fetchingPayment: undefined,
            tableHeight: undefined
        };
    }
    getColumns() {
        return [
            {
                title: 'Bill Details',
                children: [
                    {
                        title: "No",
                        dataIndex: "companyBillNo",
                        key: "companyBillNo",
                        width: '10%',
                        align: 'left'
                    },
                    {

                        title: "Date",
                        dataIndex: "Billdate",
                        key: "Billdate",
                        render: text => { return (new Date(text)).toLocaleDateString(locale, { month: '2-digit', day: '2-digit', year: 'numeric' }); },
                        width: '10%',
                        align: 'left'
                    },
                ]
            },
            {
                title: 'Payment Details',
                children: [
                    {
                        title: "Balance",
                        dataIndex: "CollectionBalance",
                        key: "CollectionBalance",
                        render: (text, record) => { return (<span id={'ganeshunq'} ref={record.newBalanceRef}>{formatAsCurrency(text, locale)}</span>); },
                        width: '12%',
                        align: 'right'
                    },
                    {
                        title: "Amount",
                        dataIndex: "paymentAmount",
                        key: "paymentAmount",
                        // @ts-ignore
                        render: (text, record) => <div className={styles.eamTableAmount} onKeyDown={((e) => this.onKeyPress('billPayment', e, record.ref))}><EAMInputNumber ref={record.ref.AmountInputRef} min={0} max={record.CollectionBalance} default={record.amountCollectedP} precision={2} onChange={[record.collect, this.onAmountChange]} theme={this.props.theme} locale={this.props.locale} /></div>,
                        width: '15%',
                        align: 'right'
                    }, {
                        title: "Cash Discount",
                        dataIndex: "paymentCD",
                        key: "paymentCD",
                        render: (text, record) =>
                            // @ts-ignore
                            <div className={styles.eamTableAmount} onKeyDown={((e) => this.onKeyPress('cdInput', e, record.ref))}><EAMInputNumber ref={record.ref.CDInputRef} validator={record.validateCD} default={record.CashDiscountP} precision={2} onChange={[record.discount, this.onAmountChange]} theme={this.props.theme} locale={this.props.locale} /></div>,
                        width: '12%',
                        align: 'right'
                    },
                    {

                        title: "Mode",
                        dataIndex: "paymentModeCodeP",
                        key: "paymentModeCodeP",
                        render: (text, record) => {
                            return <EAMAccountSelector onKeyPress={(e) =>
                                this.onKeyPress('paymentMethodSelect', e, record.ref)} innerRef={record.ref.PaymentMethodSelectRef} onchange={(v) => {
                                    record.paymentModeCodeP = v.key;
                                    record.paymentModeNameP = v.label;
                                    this.onAmountChange();
                                }} />
                        },
                        align: 'left',
                        width: '25%'

                    },
                    {
                        title: "Remarks",
                        dataIndex: "paymentRemarksP",
                        key: "paymentRemarkP",
                        render: (text, record) => {
                            return (<Pi theme={this.props.theme.className} onKeyPress={(e) =>
                                this.onKeyPress('paymentRemark', e, record.ref)} ref={record.ref.RemarksInputRef} onChange={(v) => record.paymentRemarksP = v} defaultValue={record.paymentRemarksP} />);
                        },
                        align: 'left'
                    }]
            }
        ];
    }
    expandableRowRender(record) {
        const childColumns = [{
            title: 'Value',
            dataIndex: 'TotalAmount',
            key: 'TotalAmount',
            render: text => formatAsCurrency(text, locale),
            width: '12%'
        }, {
            title: 'Paid',
            dataIndex: 'CollectionAmount',
            key: 'CollectionAmount',
            render: (text, record) => formatAsCurrency(record.CollectionAmount + record.CollectionAmountTemp, locale),
        }];
        const data = [{
            key: record.key,
            TotalAmount: record.TotalAmount,
            CollectionAmount: record.CollectionAmount,
            CollectionAmountTemp: record.CollectionAmountTemp
        }]
        return <Table dataSource={data} columns={childColumns} pagination={false} />
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.new !== prevProps.new) {
            if (this.props.new) {
                this.guid = uuidv4();
                this.setState(Object.assign({ }, Payment.compStateFactory(), { tableHeight: this.state.tableHeight }));
            } else
                this.setPaymentNo();
            return;
        }
        if (this.props.selectedCompany !== prevProps.selectedCompany && !this.props.new) {
            this.setPaymentNo();
            return;
        }
        if (this.state.bills !== prevState.bills && this.state.bills.length > 0 && this.shouldFocusinBill)
            this.setFocustoFirstBill();
    }
    setPaymentNo() {
        let query = helper.QueryParser(this.props.location.search);
        let pno = parseInt(query["pno"]);
        this.changePayment(pno);
    }
    async setFocustoFirstBill() {
        if (this.firstBillRef && this.firstBillRef.AmountInputRef.current) {
            this.firstBillRef.AmountInputRef.current.focus();
            this.firstBillRef.AmountInputRef.current && this.firstBillRef.AmountInputRef.current.select();
        }
    }
    componentDidMount() {
        setTimeout(() => {
            if (!this.props.selectedCompany) {
                this.showCompanySelectMessage();
                return;
            }
        }, 1000);

        if (this.PaymentDateRef.current)
            this.PaymentDateRef.current.focus();
        if (!this.props.new) {
            let query = helper.QueryParser(this.props.location.search);
            let pno = parseInt(query["pno"]);
            this.changePayment(pno);
        }
        window.addEventListener('resize', this.setTableHeight);
        this.setTableHeight();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.setTableHeight);
    }
    changePayment(pno) {
        let state = Object.assign({ }, Payment.compStateFactory(), { tableHeight: this.state.tableHeight })
        state.paymentNo = pno;
        this.guid = uuidv4();
        this.setState(state, () => this.displayPayment());

    }
    static calculateTotalPayment(bills) {
        let amount = 0;
        bills.forEach(element => {
            if (element.amountCollectedP)
                amount += element.amountCollectedP;
            if (element.CashDiscountP)
                amount += element.CashDiscountP;
        });
        return amount;
    }
    onAmountChange() {
        this.amountUpdate({
            title: 'Amount',
            value: formatAsCurrency(Payment.calculateTotalPayment(this.state.bills), locale)
        });

    }
    static billprototype = {
        collect: function (colAmount) {
            this.amountCollectedP = colAmount;
            this.CashDiscountP = 0;
            this.amountCollectedTotalP = this.amountCollectedP + this.CashDiscountP;
            // @ts-ignore
            this.newBalance = this.CollectionBalance - this.amountCollectedTotalP;
            // @ts-ignore
            if (this.ref.CDInputRef.current) {
                // @ts-ignore
                this.ref.CDInputRef.current.changeValue(0);
            }
            //this.newBalanceRef.current.innerHTML = formatAsCurrency(this.newBalance);
        },
        discount: function (disAmount) {
            this.CashDiscountP = disAmount;
            // @ts-ignore
            this.amountCollectedTotalP = this.amountCollectedP + this.CashDiscountP;
            // @ts-ignore
            this.newBalance = this.CollectionBalance - this.amountCollectedTotalP;
        },
        validateCD: function (cd) {
            return ((cd <= Math.round(this.TotalAmount * .01) && cd > 0 && (this.CollectionBalance - (this.amountCollectedP + cd) == 0)) || cd == 0);
        }
    };

    async fetchBills() {
        if (!this.state.selectedCustomer || !this.state.selectedSubCompany)
            return;
        /**@type {Array.<import('../types/types').EAMBill>} */
        let bills = [];
        let state = Object.assign({ }, this.state);
        state.fetchingBill = true;
        state.bills = [];
        this.setState(state);
        try {
            let resp = await Axios.get(`${this.props.apiHost}/collections/bills?cinfo=${this.props.selectedCompany.key}&cus=${this.state.selectedCustomer}&scinfo=${this.state.selectedSubCompany}&outstanding=yes`, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            bills = resp.data.responseData.billdis;
            if (this.state.bills) {
                for (let bill of bills) {
                    let oldbill = this.state.bills.find(x => x.Billno === bill.Billno && x.Billdate === bill.Billdate);
                    if (oldbill) {
                        bill.amountCollectedP = oldbill.amountCollectedP;
                        bill.CashDiscountP = oldbill.CashDiscountP;
                        bill.amountCollectedTotalP = oldbill.amountCollectedP + oldbill.CashDiscountP;
                        bill.paymentRemarksP = oldbill.paymentRemarksP;
                        bill.paymentModeCodeP = oldbill.paymentModeCodeP;
                        bill.paymentModeNameP = oldbill.paymentModeNameP;
                    }
                }
            }
            Payment.setPrototypeandPropertiesforBills(bills);
            this.balanceUpdate({
                title: 'Balance',
                value: formatAsCurrency(Payment.getTotalOustandingfromBills(bills), locale)
            });
        } catch (err) {
            let state = Object.assign({ }, this.state);
            state.fetchingBill = false;
            state.fetchError = true;
            state.fetchErrorMsg = err.response.status + ': ' + err.response.statusText;
            this.setState(state);
            return;
        }
        this.changeFocustoBill = true;
        state = Object.assign({ }, this.state);
        state.fetchingBill = false;
        state.fetchError = false;
        state.bills = bills;
        this.setState(state);
    }
    async refreshBills() {
        await this.fetchBills();
    }
    static getTotalOustandingfromBills(bills) {
        let bal = 0;
        bills.forEach(element => bal += element.CollectionBalance);
        return bal;
    }
    showCompanySelectMessage() {
        this.configMessagePopup();
        message.info('Select a company')
    }
    setTableHeight() {
        let tableHeaderHeight = document.querySelector(`.${this.styles.detailsTable} .ant-table-header`)?.clientHeight;
        let padding = 15;
        let tableHeighttoBottom = window.innerHeight - tableHeaderHeight - this.detailsTableRef.current?.getBoundingClientRect()?.top - padding;
        this.setState({
            tableHeight: isNaN(tableHeighttoBottom) ? undefined : Math.round(tableHeighttoBottom)
        })
    }
    async getPayment() {
        if (!this.props.selectedCompany) {
            return;
        }
        try {
            this.setState({
                fetchingPayment: true
            });
            let resp = await Axios.get(`${this.props.apiHost}/collections/payment?cinfo=${this.props.selectedCompany.key}&scinfo=${this.state.selectedSubCompany}&paymentno=${this.state.paymentNo}`, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            let payment = resp.data.responseData[0];
            let state = Object.assign({ }, this.state);
            state.fetchingBill = false;
            state.paymentNo = payment.paymentNo;
            state.paymentDate = payment.paymentDate;
            state.paymentStatus = payment.status;
            state.paymentHostStatus = payment.hostStatus;
            state.selectedCustomerName = payment.customer.cusname;
            state.selectedSubCompanyName = payment.subCompany.subCompanyName;
            state.bills = payment.bills;
            state.fetchingPayment = false;
            Payment.setPrototypeandPropertiesforBills(state.bills);
            this.balanceUpdate({
                title: 'Balance',
                value: formatAsCurrency(Payment.getTotalOustandingfromBills(state.bills), locale)
            });
            this.amountUpdate({
                title: 'Amount',
                value: formatAsCurrency(Payment.calculateTotalPayment(state.bills), locale)
            });
            this.setState(state);
        } catch (err) {
            if (err.response.status == 404) {
                this.props.history.push('/404');
            }
            throw err;
        }
    }
    static setPrototypeandPropertiesforBills(bills) {
        bills.forEach(element => {
            element.ref = Payment.rowRefFactory();
            element.newBalance = element.CollectionBalance - element.amountCollectedP;
            element.newBalanceRef = React.createRef();
            Object.setPrototypeOf(element, Payment.billprototype);
            element.collect = element.collect.bind(element);
            element.discount = element.discount.bind(element);
            element.validateCD = element.validateCD.bind(element);
        });

    }
    getTable() {
        if (this.state.bills) {
            let [first] = this.state.bills;
            let last = this.state.bills.slice(-1)[0];
            if (first)
                this.firstBillRef = first.ref;
            if (last)
                this.lastBillRef = last.ref;
            return <Table expandedRowRender={this.expandableRowRender} dataSource={this.state.bills} columns={this.getColumns()} scroll={{ y: this.state.tableHeight || 400 }} pagination={false} />;
        }
        else if (this.state.fetchingBill)
            return <Table loading={this.getSpin()} columns={this.getColumns()} />;
        else
            return <Table columns={this.getColumns()} />
    }
    async onSaveClick() {
        if (this.props.new)
            await this.postNewPayment();
        else
            await this.postPaymentUpdate();
    }
    configMessagePopup() {
        message.config({
            top: 10,
            duration: 2,
            maxCount: 1,
        });
    }

    showNotFoundMessage() {
        this.configMessagePopup();
        message.error('Not Found');
    }
    async deletePayment() {
        this.configMessagePopup();
        if (this.state.paymentHostStatus && !this.props.userAdminLevel) {
            message.info('Payment already synced');
            return;
        }
        let loadingNotification = message.loading('Deleting....', 0);;
        try {
            if (!this.state.bills || !this.props.selectedCompany)
                return;
            let resp = await Axios.delete(`${this.props.apiHost}/collections/payment/${this.props.selectedCompany.key}/${this.state.paymentNo}`, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            this.setState({ paymentStatus: 0 });
            message.success('Deleted Successfully');
            this.props.history.push(`/view?pno=${this.state.paymentNo}`, this.props.location.state);
        } catch (err) {
            message.error('Error deleting. Refresh bills');
        }
    }
    async postPaymentUpdate() {
        this.configMessagePopup();
        if (this.state.paymentHostStatus && !this.props.userAdminLevel) {
            message.info('Payment already synced');
            return;
        }
        try {
            if (!this.state.bills || !this.props.selectedCompany)
                return;
            let validity = this.state.bills.every(x => (x.amountCollectedP > 0 && x.paymentModeCodeP) || x.amountCollectedP <= 0);
            if (!validity) {
                message.info('Enter Payment methods');
                return;
            }
            if (!this.state.paymentDate || this.state.paymentDate === 'error') {
                message.info('Wrong payment Date');
                return;
            }
            let nwbills = this.state.bills.filter(x => x.amountCollectedP > 0 && x.paymentModeCodeP).map(x => {
                return {
                    Billdate: format(new Date(x.Billdate), 'yyyy-MM-dd'),
                    Billno: x.Billno,
                    amountCollectedP: x.amountCollectedP,
                    CashDiscountP: x.CashDiscountP,
                    amountCollectedTotalP: x.amountCollectedTotalP,
                    paymentModeCodeP: x.paymentModeCodeP,
                    paymentRemarksP: x.paymentRemarksP
                };
            });
            if (nwbills.length <= 0) {
                message.info('Use Delete Option');
                return;
            }
            let payment = {
                guid: this.guid,
                paymentNo: this.state.paymentNo,
                ccode: this.props.selectedCompany.key,
                date: format(new Date(this.state.paymentDate), 'yyyy-MM-dd'),
                bills: nwbills
            };
            let resp = await Axios.put(`${this.props.apiHost}/collections/payment`, payment, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            if (!resp.data)
                message.success('Updated Successfully');
            else
                notification.info({
                    message: resp.data.responseData.msg,
                    description: 'This Payment Needs Approval because of cash discount'
                }
                );
            this.props.history.push(`/view?pno=${this.state.paymentNo}`, this.props.location.state);
        } catch (err) {
            message.success('Error updating. Refresh bills');
        }
    }
    async postNewPayment() {
        this.configMessagePopup();
        try {
            if (!this.state.bills || !this.state.selectedCustomer || !this.state.selectedSubCompany || !this.props.selectedCompany) {
                message.info('Nothing to sve');
                return;
            }
            let validity = this.state.bills.every(x => (x.amountCollectedP > 0 && x.paymentModeCodeP) || x.amountCollectedP <= 0);
            if (!validity) {
                message.info('Enter Payment methods');
                return;
            }
            if (!this.state.paymentDate || this.state.paymentDate === 'error') {
                message.info('Wrong payment Date');
                return;
            }
            let nwbills = this.state.bills.filter(x => x.amountCollectedP > 0 && x.paymentModeCodeP).map(x => {
                return {
                    Billdate: format(new Date(x.Billdate), 'yyyy-MM-dd'),
                    Billno: x.Billno,
                    amountCollectedP: x.amountCollectedP,
                    CashDiscountP: x.CashDiscountP,
                    amountCollectedTotalP: x.amountCollectedTotalP,
                    paymentModeCodeP: x.paymentModeCodeP,
                    paymentRemarksP: x.paymentRemarksP
                };
            });
            if (nwbills.length <= 0) {
                message.info('Nothing to save');
                return;
            }
            let payment = {
                guid: this.guid,
                ccode: this.props.selectedCompany.key,
                sccode: this.state.selectedSubCompany,
                cuscode: this.state.selectedCustomer,
                date: format(new Date(this.state.paymentDate), 'yyyy-MM-dd'),
                bills: nwbills
            };
            let resp = await Axios.post(`${this.props.apiHost}/collections/payment`, payment, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            let paymentNo = resp.data.responseData.paymentNo;
            if (!resp.data.responseData.msg)
                message.success('Saved Successfully');
            else
                notification.info({
                    message: resp.data.responseData.msg,
                    description: 'This Payment Needs Approval because of cash discount'
                }
                );
            this.amountUpdate({
                title: 'Amount',
                value: formatAsCurrency(0, locale)
            });
            this.balanceUpdate({
                title: 'Balance',
                value: formatAsCurrency(0, locale)
            });
            let state = Object.assign({ }, Payment.compStateFactory(), { tableHeight: this.state.tableHeight });
            state.paymentDate = this.state.paymentDate;
            this.setState(state);
            this.refreshBills();
            this.guid = uuidv4();
            if (this.CustomerUIRef.current)
                this.CustomerUIRef.current.focus();
        } catch (err) {
            message.error('Cannot save. Refresh bills');
        }
    }
    /**
     * 
     * @param {Date} date
     */
    onDateSelect(date) {
        let state = Object.assign({ }, this.state);
        if (isNaN(+date))
            state.paymentDate = "error"
        else {
            state.paymentDate = date.toISOString();
            if (this.CustomerUIRef.current) {
                this.CustomerUIRef.current.focus();
            }
        }
        this.setState(state);
    }
    /**
    * 
    * @param {Date} date 
    */
    dateValidator(date) {
        return date && (isSameDay(date, this.props.minDate) || isAfter(date, this.props.minDate)) && (isBefore(date, this.props.maxDate) || isSameDay(date, this.props.maxDate));
    }
    getPaymentNoInput() {
        if (this.props.new) {
            return (<Input className={classNames(styles.paymentNo, styles[this.props.theme.className])} style={
                {
                    color: this.props.theme._backgroundColor
                }
            } disabled={true} value='New' />);
        } else {
            return (
                <div className={styles.paymentNoWrapper}>
                    <Input className={classNames(styles.paymentNo, styles[this.props.theme.className])} style={
                        {
                            color: this.props.theme._backgroundColor
                        }} disabled={true} value={this.state.paymentNo} />
                    <StyledBtn tabIndex={1} icon={PlusOutlined} value={'New'} mode='onDark' theme={this.props.theme.className} onClick={() => this.props.history.push('/new', this.props.location.state)} />
                </div>);
        }
    }
    getSpin() {
        let antIcon = <Loading3QuartersOutlined style={{ fontSize: '5em' }} spin />
        return {
            indicator: antIcon, style: {
                color: this.props.theme.primary1
            }
        };
    }
    /**
     * 
     * @param {string} source 
     * @param {KeyboardEvent} e 
     * @param {Object} [dataParent]
     */
    onKeyPress(source, e, dataParent) {
        try {
            switch (e.key.toLowerCase()) {
                case 'enter': {
                    switch (source) {
                        case 'cselect': {
                            if (this.SubcompanyRef.current) {
                                this.SubcompanyRef.current.DropDownOpen(true);
                                this.SubcompanyRef.current.focus();
                            }
                            break;
                        }
                        case 'billPayment': {
                            e.stopPropagation();
                            if (dataParent.CDInputRef.current) {
                                dataParent.CDInputRef.current.focus();
                                setTimeout(() => dataParent.CDInputRef.current.select());
                            }
                            break;
                        }
                        case 'cdInput': {
                            e.stopPropagation();
                            if (dataParent.PaymentMethodSelectRef.current)
                                dataParent.PaymentMethodSelectRef.current.focus();
                            break;
                        }
                        case 'paymentMethodSelect': {
                            if (dataParent.RemarksInputRef.current) {
                                dataParent.RemarksInputRef.current.focus();
                                setTimeout(() => dataParent.RemarksInputRef.current.select());
                            }
                            break;
                        }
                    }
                    break;
                }
                case 'escape': {
                    switch (source) {
                        case 'date': {
                            e.stopPropagation();
                            if (this.PaymentDateRef.current && this.PaymentDateRef.current.dropDownVisibility) {
                                this.PaymentDateRef.current.changeDropDownVisibility(false);
                            }
                            else if (this.props.location.state) {
                                this.props.history.push(this.props.location.state.path.pathname, this.props.location.state.state || undefined)
                            }
                            break;
                        }
                        case 'cselect': {
                            e.stopPropagation();
                            if (this.PaymentDateRef.current)
                                this.PaymentDateRef.current.focus();
                            break;
                        }
                        case 'subcselect': {
                            e.stopPropagation();
                            if (this.CustomerUIRef.current)
                                this.CustomerUIRef.current.focus();
                            break;
                        }
                        case 'billPayment': {
                            e.stopPropagation();
                            if (this.SubcompanyRef.current && this.props.new)
                                this.SubcompanyRef.current.focus();
                            else if (this.PaymentDateRef.current)
                                this.PaymentDateRef.current.focus();
                            break;

                        }
                        case 'cdInput': {
                            e.stopPropagation();
                            if (this.firstBillRef && this.firstBillRef.AmountInputRef.current) {
                                this.firstBillRef.AmountInputRef.current.focus();
                                setTimeout(() => this.firstBillRef.AmountInputRef.current.select());
                            }
                            break;
                        }
                        case 'paymentMethodSelect': {
                            e.stopPropagation();
                            if (dataParent.CDInputRef.current) {
                                dataParent.CDInputRef.current.focus();
                                setTimeout(() => dataParent.CDInputRef.current.select());
                            }
                            break;
                        }
                        case 'paymentRemark': {
                            e.stopPropagation();
                            if (dataParent.PaymentMethodSelectRef.current)
                                dataParent.PaymentMethodSelectRef.current.focus();
                            break;
                        }
                    }
                    break;
                }
            }
        } catch (err) {
        }
    }
    getPaymentDate() {
        return (
            // @ts-ignore
            <div onKeyDown={(e) => this.onKeyPress('date', e)}>
                <Pdp maxDate={this.props.maxDate} minDate={this.props.minDate} border defaultValue={this.state.paymentDate === 'error' ? undefined : new Date(this.state.paymentDate)} onChange={this.onDateSelect} ref={this.PaymentDateRef} format={dateformatList} theme={this.props.theme.className} disabledDate={(date) => { return this.dateValidator(date); }} />
            </div>);
    }
    onCustomerSelect(val, item) {
        this.shouldFocusinBill = false;
        let state = Object.assign({ }, this.state);
        state.selectedCustomer = val.key;
        state.selectedCustomerName = val.label;
        this.setState(state, () => this.refreshBills());
    }
    getPaymentCustomerSelector() {
        if (this.props.new)
            // @ts-ignore
            return <EAMCustomerSelect onKeyPress={(e) =>
                this.onKeyPress('cselect', e)} innerRef={this.CustomerUIRef} onSelect={(v) => this.onCustomerSelect(v)} />
        else
            // @ts-ignore
            return <EAMCustomerSelect onSelect={(v) => this.onCustomerSelect(v)} disabled={true} defaultDispalyValue={this.state.selectedCustomerName} />
    }
    onSubcompanySelect(val, item) {
        this.shouldFocusinBill = true;
        let state = Object.assign({ }, this.state);
        state.selectedSubCompany = item.key;
        state.selectedSubCompanyName = val.key;
        this.setState(state, () => this.refreshBills());
    }
    getPaymentSubCompanySelector() {
        if (this.props.new)
            // @ts-ignore
            return <EAMSubcompanySelector onKeyPress={(e) =>
                this.onKeyPress('subcselect', e)} onSelect={(v, i) => this.onSubcompanySelect(v, i)} ref={this.SubcompanyRef} />
        else
            // @ts-ignore
            return <EAMSubcompanySelector onSelect={(v, i) => this.onSubcompanySelect(v, i)} disabled={true} defaultDispalyValue={this.state.selectedSubCompanyName} />
    }
    getActionButtons() {
        if (this.props.new) {
            return (<div className={classNames(styles.actionButtons, styles[this.props.theme.className])}>
                <StyledBtn icon={ReloadOutlined} theme={this.props.theme.className} mode='onDark' onClick={this.refreshBills} value={'Refresh Bills'} disabled={this.props.new} />
                <StyledBtn ref={this.saveButtonRef} width='9.22em' icon={SaveOutlined} theme={this.props.theme.className} mode='onDark' onClick={this.onSaveClick} value={'Save'} />
            </div>);
        } else {
            return (<div className={classNames(styles.actionButtons, styles[this.props.theme.className])}>
                <EPC title='Are you sure?' okText='Yes' cancelText='No' onConfirm={this.deletePayment}>
                    <StyledBtn icon={DeleteOutlined} theme={this.props.theme.className} mode='onDark' value={'Delete'} />
                </EPC>
                <StyledBtn icon={ReloadOutlined} theme={this.props.theme.className} mode='onDark' onClick={this.refreshBills} value={'Refresh Bills'} />
                <StyledBtn ref={this.saveButtonRef} width='9.22em' icon={SaveOutlined} theme={this.props.theme.className} mode='onDark' onClick={this.onSaveClick} value={'Save'} />
            </div>);
        }
    }
    getstatusWraper() {
        if (this.state.paymentStatus === 0 && !this.props.new) {
            return (
                <div className={classNames(styles.watermark, styles[this.props.theme.className])}>
                    <div className={styles.h1Wrapper}>
                        <h1 className={styles.h1}>Deleted</h1>
                    </div>
                </div>
            );
        } else return null;

    }
    render() {
        return (
            // @ts-ignore
            <div tabIndex={0} className={classNames(styles.paymentRoot, styles[this.props.theme.className])} >
                <KeyListenPure {...this.keyListenerProps} />
                <div className={classNames(styles.paymentVoucherHeaderdiv, styles[this.props.theme.className])}>
                    <h2 className={classNames(styles.paymentHeader, styles[this.props.theme.className])}>New Payment Creation </h2>
                </div>
                <div className={classNames(styles.paymentVoucherHeader, styles[this.props.theme.className])}>
                    <h3>Voucher Details</h3>
                </div>
                <div className={styles.detailsDiv}>
                    <div className={classNames(styles.firstColumn, styles[this.props.theme.className])}>
                        <div className={classNames(styles.firstColumnSecondRow)}>
                            <div className={classNames(styles.paymentVoucherNo)}>
                                <h3>Payment Voucher No.</h3>
                                <div style={{
                                    width: '100%',
                                    borderWidth: '1em'
                                }}>
                                    {this.getPaymentNoInput()}
                                </div>
                            </div>
                            <div className={classNames(styles.paymentDate, styles[this.props.theme.className])}>
                                <h3>Date</h3>
                                {this.getPaymentDate()}
                            </div>
                        </div>
                        <div className={classNames(styles.paymentCustomer, (!this.props.new ? styles.disabled : null), styles[this.props.theme.className])}>
                            {this.getPaymentCustomerSelector()}
                        </div>
                    </div>
                    <div className={styles.secondColumn}>
                        <div className={classNames(styles.secondColumnSecondRow)}>
                            <div className={styles.amountDisplay}>
                                <UpdataleStat title={'Balance'} initialValue={formatAsCurrency(0, locale)} getValueCb={cb => this.getBalanceValue = cb} getUpdateStateCb={cb => this.balanceUpdate = cb} />
                            </div>
                            <div className={classNames(styles.amountDisplay, styles.right)}>
                                <UpdataleStat title={'Amount'} initialValue={formatAsCurrency(0, locale)} getValueCb={cb => this.getTotalValue = cb} getUpdateStateCb={cb => this.amountUpdate = cb} />
                            </div>
                        </div>
                        <div className={classNames(styles.paymentSubCompany, (!this.props.new ? styles.disabled : null), styles[this.props.theme.className])}>
                            {this.getPaymentSubCompanySelector()}
                        </div>
                    </div>
                </div>
                <div className={classNames(styles.paymentDetailsHeader, styles[this.props.theme.className])}>
                    <h3>Payment Details</h3>
                    {this.getActionButtons()}
                </div>
                <div ref={this.detailsTableRef} className={classNames(styles.detailsTable, styles[this.props.theme.className])}>
                    {this.getTable()}
                </div>
                {this.getstatusWraper()}
                {
                    this.state.fetchingPayment ?
                        <div className={classNames(styles.loading, styles[this.props.theme.className])}>
                            <Spin indicator={<Loading3QuartersOutlined spin />} />
                        </div>
                        :
                        null
                }
            </div>
        );
    }
    /**
     * 
     * @param {KeyboardEvent} e 
     */
    keyPresshandler(e) {
        if (e.key.toLowerCase() === 's' && (e.altKey || e.ctrlKey)) {
            e.stopPropagation();
            e.preventDefault();
            if (this.saveButtonRef.current)
                this.saveButtonRef.current.onClick();
            return;
        }
        if (e.key.toLowerCase() === 'escape') {
            if (this.props.location.state) {
                e.stopPropagation();
                e.preventDefault();
                this.props.history.push(this.props.location.state.path.pathname, this.props.location.state.state || undefined)
            }
            return;
        }
        if (e.key.toLowerCase() == 'home') {
            if (e.shiftKey && e.ctrlKey) {
                if (this.props.location.state) {
                    this.props.history.push(this.props.location.state.path.pathname, this.props.location.state.state || undefined)
                    e.stopPropagation();
                    e.preventDefault();
                    return;
                }
            }
        }
        if (e.key.toLowerCase() === 'n' && (e.ctrlKey || e.altKey)) {
            e.stopPropagation();
            e.preventDefault();
            this.props.history.push('/new', this.props.location.state);
        }
    }
}
export const EAMPayment = connect(mapStateToProps)(withRouter(Payment));

/**
 * @typedef PaymentComponentState
 * @property {boolean} fetchingBill
 * @property {boolean} fetchError
 * @property {string} fetchErrorMsg
 * @property {string} paymentDate
 * @property {number} paymentStatus
 * @property {boolean} paymentHostStatus
 * @property {number} paymentNo
 * @property {object} selectedCustomer
 * @property {object} selectedCustomerName
 * @property {object} selectedSubCompany
 * @property {object} selectedSubCompanyName
 * @property {boolean} fetchingPayment
 * @property {number} [tableHeight]
 * @property {Array.<import('../types/types').EAMBill>} bills
 */
