// @ts-check
import { connect } from "react-redux";
import React from 'react';
import { Spin, Dropdown, Menu, Avatar, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'
import Axios, { } from 'axios';
import { apiServerStatusUpdate, userStateUpdate } from '../redux/actions';
import styles from './EAMidentity.module.css';
const classNames = require('classnames');
const mapStateToProps = state => {
    return {
        userState: state.appState.userState,
        serverState: state.appState.serverState,
        theme: state.appState.theme,
        apiHost: state.appState.apiHost,
    };
}
const mapDispatchToProps = ((dispatch) => {
    return {
        serverStateUpdate: (status) => dispatch(apiServerStatusUpdate(status)),
        userStateUpdate: (userState) => dispatch(userStateUpdate(userState))
    };
});
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class Identity extends React.Component {
    constructor(props) {
        super(props);
    }

    logout = (async () => {
        try {
            let resp = await Axios.get(`${this.props.apiHost}/logout`, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${this.props.userState.jwt}`
                }
            });
            localStorage.clear();
            notification.success({ message: 'Logged Out..' });
            window.setTimeout(() => window.location.reload(), 1000);
        }
        catch (err) {
            notification.error({ message: 'Cannot Log out. Try again later' });
        }
    });
    render() {
        if (this.props.userState.userDisplayName) {
            return (
                <Dropdown placement='bottomRight' overlay={
                    <Menu className={classNames(styles.identityMenu, styles[this.props.theme.className])}>
                        <Menu.Item className={classNames(styles.identityMenuItem, styles[this.props.theme.className])} onClick={this.logout} >Logout</Menu.Item>
                    </Menu>}>
                    <div className={styles.EAMIdentity}>
                        <p>{this.props.userState.userDisplayName}</p>
                        <   Avatar size='large' src={this.props.userState.userPhotoData ? this.props.userState.userPhotoData : ''} alt={''} />
                    </div>
                </Dropdown>);
        } else {
            return (
                <div className={classNames(styles.EAMIdentity, styles.EAMIdentityLd, styles[this.props.theme.className])}>
                    <p>Loading</p>
                    <Spin indicator={antIcon} style={{
                        color: this.props.theme.secondryTextColor
                    }} />
                </div>);
        }
    }
}
const EAMIdentity = connect(mapStateToProps, mapDispatchToProps)(Identity);
export default EAMIdentity;