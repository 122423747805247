import React from 'react';
import { InputNumber, Tooltip } from 'antd'
import styles from './eamInputNumber.module.css';
import classNames from 'classnames';
import { formatAsCurrency } from '../types/helper'
import { ca } from 'date-fns/locale';
class StyledInputNumber extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.onLeave = this.onLeave.bind(this);
        this.tryCallingOnPressEnter = this.tryCallingOnPressEnter.bind(this);
        this.state = { type: 'valid' };
        this.value = 0;
        this.ValuetoShow = 0;
        this.inputRef = React.createRef();
        if (this.props.default) {
            let defaultValue = parseFloat(this.props.default);
            if (!isNaN(defaultValue)) {
                this.value = defaultValue;
                this.ValuetoShow = defaultValue;
            }
        }
    }
    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }
    blur() {
        if (this.inputRef.current) {
            this.inputRef.current.blur();
        }
    }
    select() {
        if (this.inputRef.current)
            this.inputRef.current.select();
    }
    onLeave() {
        if (this.state.type === 'error') {
            this.ValuetoShow = 0;
            this.value = 0;
            this.setState({
                type: 'valid',
            });
            this.tryCallingOnChange(this.value);
        }
    }
    changeValue(value) {
        this.onChange(value);
        this.render();
    }
    onChange(newValue) {
        this.ValuetoShow = newValue;
        let isValid = false;
        if (!this.props.validator)
            isValid = !(newValue > this.props.max || newValue < this.props.min)
        else
            isValid = this.props.validator(newValue);
        if (!isValid)
            this.setState({
                type: 'error'
            });
        else {
            this.setState({
                type: 'valid'
            });
            this.value = newValue;
            this.tryCallingOnChange();
        }
    }
    tryCallingOnChange() {
        if (this.props.onChange) {
            if (Array.isArray(this.props.onChange)) {
                for (let fn of this.props.onChange) {
                    fn(this.value);
                }
            } else this.props.onChange(this.value);
        }
    }
    tryCallingOnPressEnter() {
        if (this.props.onPressEnter) {
            if (Array.isArray(this.props.onPressEnter)) {
                for (let fn of this.props.onPressEnter) {
                    fn(this.value);
                }
            } else {
                this.props.onPressEnter(this.value);
            }
        }
    }
    /**
     * 
     * @param {string} value 
     */
    parseValue(value) {
        let regex = new RegExp(`(([\\d\,]*)\\.?[\\d]*)`, 'g');
        /**@type {Array.<string>} */
        let matchArray = value.match(regex);
        let retval = '0';
        for (let str of matchArray) {
            let val = str.replace(new RegExp(',', 'g'), '',);
            if (val.length > 0) {
                retval = val;
                break;
            }
        }
        return parseFloat(retval);
    }
    render() {
        switch (this.state.type) {
            case 'error': {
                var buttonType = 'errorInput';
                break;
            }
            default: {
                var buttonType = '';
            }
        }
        if (this.props.mode === 'normal') {
            return (
                <div onBlur={this.onLeave} className={classNames(styles.eamInput, styles[this.props.theme.className], styles[buttonType], { [styles.borderless]: this.props.borderless })} >
                    <InputNumber style={{ height: this.props.height }} onFocus={this.props.onFocus} disabled={this.props.disabled} ref={this.inputRef} value={this.ValuetoShow} inputMode={'decimal'} precision={this.props.precision} onChange={this.onChange} onPressEnter={this.tryCallingOnPressEnter} />
                </div>);
        } else {
            return (<div onBlur={this.onLeave} className={classNames(styles.eamInput, styles[this.props.theme.className], styles[buttonType], {
                [styles.borderless]: this.props.borderless
            })} >
                <Tooltip overlayClassName={classNames(styles.eamInputTooltip, styles[buttonType])} visible={this.state.type === 'error'} title={`Incorrect Value.\nCurrent Value:${formatAsCurrency(this.value, this.props.locale)}`} defaultVisible={false} autoAdjustOverflow={false}>
                    <InputNumber style={{ height: this.props.height }} onFocus={this.props.onFocus} disabled={this.props.disabled} ref={this.inputRef} width={this.props.width} value={this.ValuetoShow} inputMode={'decimal'} precision={this.props.precision} onChange={this.onChange} />
                </Tooltip>
            </div>);
        }
    }
}
const EAMInputNumber = StyledInputNumber;
export default EAMInputNumber;