import React from "react";
import Context from './context';
import { uuidv4 } from '../../types/helper'
class KeyListenPure extends React.PureComponent {
    constructor(props) {
        super(props);
        this.id = '_kl_' + uuidv4() + '_' + (this.props.name || '');
    }
    componentWillUnmount() {
        if (this.remove instanceof Function) {
            this.remove(this.id);
        }
    }
    componentDidUpdate() {
        if (this.remove instanceof Function) {
            this.props.down === undefined && this.remove(this.id, 'keydown')
            this.props.up === undefined && this.remove(this.id, 'keyup')
        }
    }
    render() {
        return (<Context.Consumer>
            {({ addOrUpdate, remove }) => {
                let arg = [];
                this.addOrUpdate = addOrUpdate;
                this.remove = remove;
                if (this.props.down?.downCallBack instanceof Function) {
                    arg.push({
                        listener: this.props.down.downCallBack,
                        keys: this.props.down.downKeys,
                        exceptions: this.props.down.exceptions,
                        event: 'keydown',
                        id: this.id
                    });
                }
                if (this.props.up?.upCallBack instanceof Function) {
                    arg.push({
                        listener: this.props.up.upCallBack,
                        keys: this.props.up.upKeys,
                        exceptions: this.props.up.exceptions,
                        event: 'keyup',
                        id: this.id
                    });
                }
                this.addOrUpdate(arg);
                return null;
            }}
        </Context.Consumer>);
    }
}
// function KeyListen() {
//     useEffect(() => {
//         let listenDown = (e) => {
//             if (downKeys === undefined || downKeys.find(x => e.key?.toLowerCase() === x?.toLowerCase()))
//                 downCallBack(e);
//         }
//         let listenUp = (e) => {
//             if (upKeys === undefined || upKeys.find(x => e.key?.toLowerCase() === x?.toLowerCase()))
//                 upCallBack(e);
//         }
//         downCallBack instanceof Function && window.addEventListener('keydown', listenDown);
//         upCallBack instanceof Function && window.addEventListener('keyup', listenUp);
//         return () => {
//             window.removeEventListener('keydown', listenDown);
//             window.removeEventListener('keyup', listenUp);
//         }
//     }, [downKeys, upKeys, upCallBack, downCallBack]);

// }
// KeyListen.defaultProps = {
//     down: {},
//     up: {}
// }

export default KeyListenPure;