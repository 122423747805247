// @ts-check
import React from 'react';
import { connect } from "react-redux";
import { Spin, Card, message, Table, Empty, Switch } from 'antd';
import * as Icons from '@ant-design/icons'
import styles from './outstandings.module.scss';
import Axios from 'axios';
import { PerformanceInput as Pi } from '../PerformanceInput'
import Nt from '../navigatableTable'
import classNames from 'classnames';
import EPC from '../extendedPopConfirm';
import { formatAsCurrency, SetMaxHeightToWindowBottom, exportToCSV, onPaymentDelete, wait } from '../../types/helper';
import StyledBtn from '../styledButton';
import { updateAppStartAndAppDateState } from '../../redux/actions';
import { BooksTitleBar } from '../titleBar';
import EAMSubcompanySelector from "../SubCompanySelector";
import { deletePayment as dp } from '../../apiTalk/apActions';
import { ConsoleSqlOutlined, RetweetOutlined } from '@ant-design/icons';
import isSameDay from 'date-fns/isSameDay';
import { format } from 'date-fns';
const mapStateToProps = state => {
    return {
        theme: state.appState.theme,
        locale: state.appState.locale,
        selectedCompany: state.masterState.cinfo.selectedCompany,
        apiHost: state.appState.apiHost,
        token: state.appState.userState.jwt,
        userName: state.appState.userState.userEmail,
        adminLevel: state.appState.userState.userAdminLevel,
        appDate: state.masterState.date,
        appStartDate: state.masterState.startDate,
        maxDate: state.masterState.maxDate,
        minDate: state.masterState.minDate
    };
}
const mapDispatchtoProps = dispatch => {
    return {
        updateStartAndAppDate: (startDate, appDate) => dispatch(updateAppStartAndAppDateState(startDate, appDate))
    }
}
//Padding in Pixels
const BOTTOM_PADDING = 20;

class outStandingsDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.componentStateFactory();
        this.styles = styles;
        this.bindfunctions();
        this.NavTableRef = React.createRef();
        this.dateModalRef = React.createRef();
        this.subCompanyRef = React.createRef();
        this.billsContextMenu = this.props.adminLevel ? [{
            title: 'Close/Open',
            callback: obj => {
                this.onDeleteBill(obj, this.NavTableRef.current)
            },
            icon: <Icons.DeleteOutlined />,
        },
        {
            title: '(Un)Approve CD',
            callback: async bills => {
                for (let bill of bills) {
                    let ref = this.NavTableRef.current.getCellRef(bill.Key, "loading")?.colInnerRef;
                    let loading = ref?.loadingRef;
                    if (loading?.current)
                        loading?.current.classList.add(styles['visible']);
                    try {
                        for (let payment of bill.payments)
                            await this.approveCD(payment, bill)
                    }
                    catch (er) {
                        console.log(er);
                    }
                    if (loading.current?.classList.contains(this.styles.visible))
                        loading.current.classList.remove(this.styles['visible']);
                }
                this.forceUpdate();
            },
            icon: <Icons.CheckOutlined />
        }] : [];
        this.customerSearchRef = React.createRef();
    }
    bindfunctions() {
        this.setFocus = this.setFocus.bind(this);
        this.editPayment = this.editPayment.bind(this);
        this.handleBillSelection = this.handleBillSelection.bind(this);
        this.onPaymentDelete = onPaymentDelete.bind(this);
        this.onDeleteBill = this.onDeleteBill.bind(this);
    }
    componentStateFactory(_fetching = false, _outstadnings = null, _error = false, _errorMessage = '', _type = 1, _fd = undefined, _scKey = undefined) {
        return {
            data: {
                list: _outstadnings,
                error: _error,
                errorMessage: _errorMessage,
                subCompanyKey: _scKey
            },
            view: {
                loading: _fetching,
                loadingMsg: 'Fetching Your Bills',
                type: _type,
                negativeBills: false,
                defaultSelectedBillKey: undefined,
                manuallyClosedBills: false,
                customerFilter: undefined,
                talliedBills: true,
                filteredList: _fd,
                displayItemType: 1,
                displayBillInfo: {
                    billno: undefined,
                    billdate: undefined,
                    billKey: undefined,
                    billCustomer: undefined,
                    billSubcompany: undefined,
                    payments: undefined,
                    selectedPayment: undefined,
                    billsSortOrder: undefined
                } // check if a deploy is skipped
            }
        };
    }
    editPayment(selectedPayments) {
        if (selectedPayments?.length !== 1)
            return;
        const payment = selectedPayments[0];
        if (!payment)
            return;
        const state = Object.assign({}, this.state);
        this.props.history.push('/view?pno=' + payment.paymentNo, {
            path: this.props.location,
            state: state
        })
    }
    componentDidMount() {
        if (this.props.location?.state) {
            this.setState(this.props.location.state);
            this.props.history.replace({ ...this.props.history.location, state: {} });

        }
        else
            this.setFocus();

    }
    componentWillUnmount() {
        window.onresize = null;
    }
    componentDidUpdate(prevProps, prevState) {
        this.setFocus();
        if ((this.state.data.subCompanyKey !== prevState.data.subCompanyKey && prevState.data.subCompanyKey) || (!this.state.data.list && !this.state.view.loading && this.state.data.subCompanyKey)) {
            this.refreshBills();
            return;
        }
    }
    scLable = "";
    subCompanySelectionChange(key, item) {
        this.scLable = key.key;
        let state = Object.assign({}, this.state.data);
        state.list = null;
        state.subCompanyKey = item.key;
        this.setState({ data: state });
    }
    refreshBills() {
        this.fetchOutstandings();
    }
    setFocus() {
        if (this.state.data.list && this.state.data.list.length >= 1) {
            let nodelist = document.getElementsByClassName(styles.displayWrapper)
            let wrapper = nodelist.length > 0 ? nodelist[0] : undefined;
            if (wrapper) {
                wrapper.focus();
            }
        } else {
            if (this.subCompanyRef.current)
                this.subCompanyRef.current.focus()
        }
    }
    configMessagePopup() {
        message.config({
            top: 10,
            duration: 2,
            maxCount: 1,
        });
    }
    async fetchOutstandings() {
        if (!this.props.selectedCompany) {
            return;
        }
        try {
            let newView = Object.assign({}, this.state.view);
            newView.loading = true;
            newView.loadingMsg = 'Fetching Your Bills';
            this.setState({
                view: newView
            });
            let resp = await Axios.get(`${this.props.apiHost}/collections/outstandings?cinfo=${this.props.selectedCompany.key}&scinfo=${this.state.data.subCompanyKey}`, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            resp.data.responseData.sort((a, b) => {
                if (a.SubCompany.subCompanyName < b.SubCompany.subCompanyName) return -1;
                else if (a.SubCompany.subCompanyName > b.SubCompany.subCompanyName) return 1;
                else if (a.Billdate < b.Billdate) return -1;
                else if (a.Billdate > b.Billdate) return 1;
                else if (a.CollectionBalance + a.CashDiscountTemp_aa > b.CollectionBalance + b.CashDiscountTemp_aa) return -1;
                else if (a.CollectionBalance + a.CashDiscountTemp_aa < b.CollectionBalance + b.CashDiscountTemp_aa) return 1;
                return 0;
            });
            this.processData(resp.data.responseData);
            let state = this.componentStateFactory(false, resp.data.responseData, false, '', 1, undefined, this.state.data.subCompanyKey);
            state.view.customerFilter = this.state.view.customerFilter;
            state.view.manuallyClosedBills = this.state.view.manuallyClosedBills;
            state.view.negativeBills = this.state.view.negativeBills;
            this.setState(state);
            let view = Object.assign({}, this.state.view);
            view.filteredList = this.filterData(this.state.view.negativeBills, this.state.view.manuallyClosedBills, this.state.view.customerFilter)
            this.setState({ view });
        } catch (err) {
            if (err.response && err.response.status === 404) {
                this.setState(this.componentStateFactory(false, null, false, '', 1));
                return;
            }
            this.setState(this.componentStateFactory(false, null, true, err.message, 1));
        }
    }
    processData(data) {
        if (data) {
            for (let bill of data) {
                if (bill.payments && bill.payments.length > 0) {
                    bill.payments.sort((a, b) => {
                        if (a.paymentDate > b.paymentDate) return -1;
                        else if (a.paymentDate < b.paymentDate) return 1;
                        return 0;
                    });
                    bill.CollectionDate1 = bill.payments[0].paymentDate;
                } else
                    bill.CollectionDate1 = bill.CollectionDate;
                bill.Key = `|${bill.Billno}|${bill.Billdate}|`
            }
        }
    }

    async onDeleteBill(items, tableRef) {
        if (!this.props.adminLevel)
            return;
        for (let bill of items) {
            let loading = tableRef?.getCellRef(bill.Key, "loading")?.colInnerRef.loadingRef;
            loading.current?.classList.add(styles.visible);
            try {
                await this.openCloseBill(bill);
            }
            catch {

            }
            if (bill.isExplicitlyClosed)
                tableRef?.strikeRow(bill.Key)
            else
                tableRef?.unStrikeRow(bill.Key)
            loading.current?.classList.remove(styles.visible);
        }
    }
    /**
    * 
    * @param {EPCPaymentReferanceValue} refValue 
    */
    async deletePayment(refValue) {
        try {
            refValue.ref.current.manualStateSet(true);
            try {
                await dp(this.props.selectedCompany.key, refValue.paymentNo, this.props.token);
            } catch (err) {
                throw err;
            }
            let deletedPayment = this.state.data.payments.find(x => x.paymentNo == refValue.paymentNo);
            if (deletedPayment) {
                deletedPayment.status = 0;
            }
            refValue.ref.current.manualStateSet(false);
            //this.setState(this.componentStateFactory(false, this.state.data.payments, false, ''));
        } catch (err) {
            message.error('Error deleting.');
        }
    }

    /**
     * 
     * @param {boolean} nb //Negative balance
     * @param {boolean} mc //Manually closed bills
     * @param {string} [customerName] //customer Name
     */
    filterData(nb, mc, customerName, talliedBills = this.state.view.talliedBills) {
        if (customerName) {
            customerName = customerName.toLowerCase();
        }
        let returnArray = [];
        this.state.data.list.forEach(x => {
            if (!nb) {
                if (x.CollectionBalance + x.CashDiscountTemp_aa < 0)
                    return;
            }
            if (!mc) {
                if (x.isExplicitlyClosed)
                    return;
            }
            if (customerName) {
                if (!x.cusname.toLowerCase().includes(customerName))
                    return;
            }
            if (!talliedBills) {
                if (isSameDay(new Date(x.lastBalanceConfirmation), new Date()))
                    return;
            }
            returnArray.push(x);
        });
        return returnArray;
    }
    getOutstandingsDisplay() {
        if (this.state.view.loading)
            return (<div className={classNames(styles.spinWrapper, styles[this.props.theme.className])}>
                <Spin size='large' />
                <h3 style={{ paddingTop: '2.6rem' }}>{this.state.view.loadingMsg}  ...</h3>
            </div>);
        else
            return (
                <div tabIndex={2} className={classNames(styles.displayWrapper, this.state.view.type === 1 || !this.state.view.filteredList ? null : styles.grid, styles[this.props.theme.className])}>
                    {this.state.view.filteredList ? (this.state.view.type === 1 ? this.getOutstandingsList() : this.getOutstandingsCards()) : null}
                </div>);
    }
    getOutstandingsCards() {
        let cardArr = [];
        if (this.state.view.filteredList) {
            cardArr.push(this.state.view.filteredList.map(x => {
                return (<Card className={classNames(styles.card)} title={x.cusname} key={x.Billno} extra=
                    {
                        this.getOpenCloseButton(x)
                    }>
                    <div className={classNames(styles.wrapper, x.isExplicitlyClosed ? styles.closed : undefined, styles[this.props.theme.className])}>
                        <div className={classNames(styles.statusWrapper, !x.isExplicitlyClosed ? styles.hidden : undefined, styles[this.props.theme.className])}>
                            <h1>Closed Manually</h1>
                        </div>
                        <p>Bill No: {x.Billno} | Date: {format(new Date(x.Billdate), 'dd-MM-yyyy')}</p>
                        <p>Bill Value: {formatAsCurrency(x.TotalAmount, this.props.locale)}</p>
                        <p>Collection : {formatAsCurrency(x.CollectionAmount + x.CollectionAmountTemp, this.props.locale)}</p>
                        <p>Cash Discount: {formatAsCurrency(x.CashDiscountTemp, this.props.locale)}</p>
                        <p>Cash Discount Pending Approval: {formatAsCurrency(x.CashDiscountTemp_aa, this.props.locale)}</p>
                        <p>Balance Amount : {formatAsCurrency(x.CollectionBalance + x.CashDiscountTemp_aa, this.props.locale)}</p>
                        <p>Last Collection On : {x.CollectionDate1 === null ? '' : format(new Date(x.CollectionDate1), 'dd-MM-yyyy')}</p>
                        <p>Company: {x.SubCompany.subCompanyName}</p>
                    </div>

                </Card>);
            }));
        }
        return cardArr;
    }
    getOutstandingsColumns() {
        return [
            {
                name: "tallyCheck",
                title: "",
                dataIndex: "Billno",
                key: "Billno",
                render: (text, record, index, ref) => {
                    ref.tickRef = React.createRef();
                    let className = styles['cobCheck'];
                    if (isSameDay(new Date(record.lastBalanceConfirmation), new Date()))
                        className += (' ' + styles['visible']);
                    return (<span className={className} ref={ref.tickRef}>{<Icons.CheckOutlined />}</span>);
                },
                columnType: "responsive",
                dataType: 'number',
                width: 1,
                minWidth: '3ch',
            },
            {
                title: "Bill No",
                dataIndex: "Billno",
                key: "Billno",
                render: (text, record) => { return (<span>{text}</span>); },
                columnType: "responsive",
                dataType: 'number',
                sorter: true,
                width: 7,
                minWidth: '11ch',
            },
            {
                title: "Date",
                dataIndex: "Billdate",
                key: "Billdate",
                render: (text) => { return (<span>{format(new Date(text), 'dd-MM-yyyy')}</span>); },
                sorter: true,
                dataType: 'date',
                columnType: "responsive",
                width: 5,
                minWidth: '15ch',
            },
            {
                title: "Customer",
                dataIndex: "cusname",
                key: "cusname",
                render: (text) => { return (<span>{text}</span>); },
                dataType: 'string',
                columnType: "responsive",
                width: 15,
                footer: "Total",
                sorter: true,
                minWidth: '20ch',
            },
            {
                title: "Bill Value",
                dataIndex: "TotalAmount",
                key: "TotalAmount",
                render: (text) => { return (<span>{formatAsCurrency(text)}</span>); },
                dataType: 'number',
                columnType: "responsive",
                width: 10,
                minWidth: '17ch',
                align: 'right',
                style: {
                    textAlign: 'right'
                },
                sorter: true,
                footer: () => formatAsCurrency(this.state.view.filteredList?.reduce((acc, val, i, a) => acc + val.TotalAmount, 0), this.props.locale)
            },
            {

                title: "Collection Recieved",
                dataIndex: "null",
                key: "null",
                render: (text, record) => { return (<span>{formatAsCurrency(record.CollectionAmount + record.CollectionAmountTemp)}</span>); },
                dataType: 'number',
                columnType: "responsive",
                sortValue: (record) => record.CollectionAmount + record.CollectionAmountTemp,
                width: 10,
                minWidth: '17ch',
                align: 'right',
                style: {
                    textAlign: 'right'
                },
                sorter: true,
                footer: () => formatAsCurrency(this.state.view.filteredList?.reduce((acc, val, i, a) => acc + val.CollectionAmount + val.CollectionAmountTemp, 0), this.props.locale)
            },
            {

                title: "Cash Discount",
                dataIndex: "CashDiscountTemp",
                key: "CashDiscountTemp",
                render: (text) => { return (<span>{formatAsCurrency(text)}</span>); },
                dataType: 'number',
                columnType: "responsive",
                width: 6,
                minWidth: '14ch',
                align: 'right',
                style: {
                    textAlign: 'right'
                },
                sorter: true,
                footer: () => formatAsCurrency(this.state.view.filteredList?.reduce((acc, val, i, a) => acc + val.CashDiscountTemp, 0), this.props.locale)

            },
            {

                title: "Cash Discount Pending Approval",
                dataIndex: "CashDiscountTemp_aa",
                key: "CashDiscountTemp_aa",
                render: (text) => { return (<span>{formatAsCurrency(text)}</span>); },
                dataType: 'number',
                columnType: "responsive",
                width: 6,
                minWidth: '14ch',
                align: 'right',
                style: {
                    textAlign: 'right'
                },
                sorter: true,
                footer: () =>
                    formatAsCurrency(this.state.view.filteredList?.reduce((acc, val, i, a) => acc + val.CashDiscountTemp_aa, 0), this.props.locale)

            },
            {
                title: "Balance Amount",
                dataIndex: "null",
                key: "null",
                render: (text, record) => { return (<span>{formatAsCurrency(record.CollectionBalance + record.CashDiscountTemp_aa)}</span>); },
                sortValue: (record) => {
                    return record.CollectionBalance + record.CashDiscountTemp_aa
                },
                dataType: 'number',
                columnType: "responsive",
                width: 10,
                minWidth: '17ch',
                align: 'right',
                style: {
                    textAlign: 'right'
                },
                sorter: true,
                footer: () =>
                    formatAsCurrency(this.state.view.filteredList?.reduce((acc, val, i, a) => acc + val.CollectionBalance + val.CashDiscountTemp_aa, 0), this.props.locale)
            },
            {
                title: "Last Collection Date",
                dataIndex: "CollectionDate1",
                key: "CollectionDate1",
                render: (text) => { return (<span>{text === null ? '' : format(new Date(text), 'dd-MM-yyyy')}</span>); },
                sorter: true,
                dataType: 'date',
                columnType: "responsive",
                width: 5,
                minWidth: '15ch',
            },
            {
                title: "Last Balance Confirmation",
                dataIndex: "lastBalanceConfirmation",
                key: "lastBalanceConfirmation",
                render: (text, record) => {
                    return (<span> {text === null ? '' : format(new Date(text), 'dd-MM-yyyy')}</span>);
                },
                sorter: true,
                dataType: 'date',
                columnType: "responsive",
                width: 10,
                minWidth: '15ch',
            },
            {
                name: "loading",
                title: "",
                dataIndex: "lastBalanceConfirmation",
                key: "lastBalanceConfirmation",
                render: (text, record, index, ref) => {
                    ref.loadingRef = React.createRef();
                    return (<div ref={ref.loadingRef} className={styles.apLoading}> {<Icons.Loading3QuartersOutlined spin={true} style={{ fontSize: "1.4rem" }} />}</div>);
                },
                dataType: 'date',
                columnType: "responsive",
                minWidth: '15ch',
            }
        ]
    }

    async openCloseBill(bill) {
        try {
            if (!bill)
                return
            await Axios.get(`${this.props.apiHost}/collections/bills/${bill.isExplicitlyClosed ? 'open' : 'close'}/${this.props.selectedCompany.key}/${bill.Billno}/${bill.Billdate}`, {
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            bill.isExplicitlyClosed = !bill.isExplicitlyClosed;
        } catch (err) {
        }
    }
    async tallyBill(bill) {
        try {
            if (!bill)
                return;
            let pad = (n) => n < 10 ? '0' + n : n;
            let current = new Date();
            let today = `${current.getFullYear()}-${pad(current.getMonth() + 1)}-${pad(current.getDate())}T00:00:00Z`
            await Axios.get(`${this.props.apiHost}/collections/bills/cob/${this.props.selectedCompany.key}/${bill.Billno}/${bill.Billdate}/${today}`, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            bill.lastBalanceConfirmation = today;
        } catch (err) {
            message.error('Something went wrong');
        }
    }
    getPaymentColumns() {
        return [
            {
                title: "Payment No",
                dataIndex: "paymentNo",
                key: "paymentNo",
                render: (text, record) => { return (<span>{text}</span>); },
                columnType: "responsive",
                dataType: 'number',
                sorter: true,
                width: 7,
                minWidth: '11ch',
            },
            {
                title: "Date",
                dataIndex: "paymentDate",
                key: "paymentDate",
                render: (text, record) => { return (<span>{format(new Date(text), 'dd-MM-yyyy')}</span>); },
                sorter: true,
                dataType: 'date',
                sortValue: 'paymentDate',
                columnType: "responsive",
                width: 5,
                minWidth: '12ch',
            },
            {
                title: "Customer",
                dataIndex: "customer.cusname",
                key: "customer.cusname",
                render: (text, record) => { return (<span>{this.state.view.displayBillInfo.billCustomer}</span>); },
                dataType: 'string',
                columnType: "responsive",
                width: 22,
                footer: "Total",
                sorter: true,
                minWidth: '25ch',
            },
            {
                title: "Amount Received",
                dataIndex: "billCollectionAmount",
                key: "billCollectionAmount",
                render: (text, record) => { return (<span>{formatAsCurrency(text)}</span>); },
                dataType: 'number',
                columnType: "responsive",
                width: 9,
                minWidth: '15ch',
                align: 'right',
                style: {
                    textAlign: 'right'
                },
                sorter: true,
                footer: () => formatAsCurrency(this.state.view.displayBillInfo.payments.reduce((acc, val, i, a) => acc + val.billCollectionAmount, 0), this.props.locale)
            },
            {

                title: "Cash Discount",
                dataIndex: "billCollectionDiscount",
                key: "billCollectionDiscount",
                render: (text, record) => { return (<span>{formatAsCurrency(text)}</span>); },
                dataType: 'number',
                columnType: "responsive",
                width: 8,
                minWidth: '14ch',
                align: 'right',
                style: {
                    textAlign: 'right'
                },
                sorter: true,
                footer: () => formatAsCurrency(this.state.view.displayBillInfo.payments.reduce((acc, val, i, a) => acc + val.billCollectionDiscount, 0), this.props.locale)

            },
            {
                title: "Total Amount",
                dataIndex: "billCollectionAmountTotal",
                key: "billCollectionAmountTotal",
                render: (text, record) => { return (<span>{formatAsCurrency(text)}</span>); },
                dataType: 'number',
                columnType: "responsive",
                width: 8,
                minWidth: '15ch',
                align: 'right',
                style: {
                    textAlign: 'right'
                },
                sorter: true,
                footer: () => formatAsCurrency(this.state.view.displayBillInfo.payments.reduce((acc, val, i, a) => acc + val.billCollectionAmountTotal, 0), this.props.locale)
            },
            {
                name: 'da',
                title: "Discount Approval",
                dataIndex: "billCollectionStatus",
                key: "billCollectionStatus",
                render: (text, record, index, ref) => {
                    ref.checkRef = React.createRef();
                    ref.loadingRef = React.createRef();
                    let className = styles['apCheck'];
                    if (record.billCollectionDiscountStatus && record.billCollectionDiscount !== 0)
                        className += (' ' + styles['visible']);
                    return (
                        <span>
                            <div ref={ref.checkRef} className={className}>
                                <Icons.CheckOutlined style={{
                                    fontSize: '1.5em'
                                }} />
                            </div>
                            <div ref={ref.loadingRef} className={styles.apLoading}>
                                <Icons.LoadingOutlined style={{
                                    fontSize: '1.5em'
                                }} />
                            </div>
                        </span>);
                },
                columnType: "responsive",
                width: 10,
                minWidth: '12ch',
                align: 'left',
                dataType: 'string',
                sorter: true
            },
            {
                title: "Status",
                dataIndex: "billCollectionStatus",
                key: "billCollectionStatus",
                // @ts-ignore
                sorter: true,
                dataType: 'string',
                render: (text, record) => {
                    return (<span>{text == 0 ? 'Deleted' : text == 1 ? 'Uploaded' : text == 2 ? 'Ok' : 'Needs Approval'}</span>);
                },
                columnType: "responsive",
                width: 8,
                minWidth: '14ch',
                align: 'left',
            },
            {
                title: "Last Edit by Or Created by",
                dataIndex: "user",
                key: "user",
                render: (text, record) => {
                    return (<span>{
                        record.billCollectionEdituser ? record.billCollectionEdituser : record.billCollectionUser}</span>);
                },
                sorter: true,
                columnType: "responsive",
                width: 12,
                minWidth: '20ch',

            },
            {
                name: "loading",
                title: "",
                dataIndex: "user_clnt",
                key: "user_clnt",
                dataType: 'string',
                // @ts-ignore
                sorter: false,
                render: (text, record, indexOpt, ref) => {
                    let loadingRef = React.createRef();
                    ref.loadingRef = loadingRef;
                    return <StyledBtn ref={loadingRef} theme={this.props.theme.className} mode={'onDark'} borderLess={true} />;
                },
                columnType: "responsive",
            }
        ]
    }
    getActions(x) {
        let link = `/view?pno=${x.paymentNo}`;
        let buttonref = React.createRef();
        return (
            <div className={classNames(styles.actionsButton)}>
                <a href={link}>
                    <StyledBtn icon={(x.status == 0 ? Icons.FolderOpenOutlined : Icons.EditOutlined)} theme={this.props.theme.className} mode='onDark' />
                </a>
                <EPC title='Are you sure?' referanceValue={{ ref: buttonref, paymentNo: x.paymentNo }} onConfirm={this.deletePayment} >
                    <StyledBtn ref={buttonref} icon={Icons.DeleteOutlined} referenceValue={x.paymentNo} disabled={x.status == 0} theme={this.props.theme.className} mode='onDark' />
                </EPC>
            </div>);
    }

    getSpin() {
        let antIcon = <Icons.Loading3QuartersOutlined style={{ fontSize: '5em' }} spin />
        return {
            indicator: antIcon, style: {
                color: this.props.theme.primary1
            }
        };
    }
    tableSummary(data) {
        let billValue = 0;
        let collection = 0;
        let cd = 0;
        let cd_aa = 0;
        let outstanding = 0;
        data.forEach(({
            TotalAmount,
            CollectionAmount,
            CollectionAmountTemp,
            CashDiscountTemp,
            CashDiscountTemp_aa

        }) => {
            billValue += TotalAmount;
            collection += (CollectionAmount + CollectionAmountTemp)
            cd += CashDiscountTemp;
            cd_aa += CashDiscountTemp_aa;
        });
        /*
        return (
            <>
                <div>
                    Total
                        </div>
                <div>
                    {formatAsCurrency(billValue)}
                </div>
                <div>
                    {formatAsCurrency(billValue - collection - cd)}
                </div>
            </>
        );*/
    }
    getOutstandingsList() {
        if (this.state.view.loading)
            return <Table loading={this.getSpin()} />;
        else
            return (
                this.state.view.displayItemType === 1 ?
                    <Nt defaultSelectedBillKey={this.state.view.defaultSelectedBillKey} defaultStrikeControlKey='isExplicitlyClosed' theme={this.props.theme.className} ref={this.NavTableRef} rowKey={'Key'} columns={this.getOutstandingsColumns()} dataSource={this.state.view.filteredList} pagination={false} footer={this.tableSummary} rowHeight={20} onEnter={this.handleBillSelection} onDelete={this.onDeleteBill} contextMenu={this.billsContextMenu} />
                    :
                    <Nt theme={this.props.theme.className} footer ref={this.NavTableRef} rowKey={'paymentNo'} columns={this.getPaymentColumns()} dataSource={this.state.view.displayBillInfo.payments} pagination={false} tabIndex={100} rowHeight={20} onEnter={this.editPayment} onDelete={this.props.adminLevel ? this.onPaymentDelete : undefined} />
            );
    }
    /**
     * 
     * @param {Date} sd 
     * @param {Date} ed 
     */
    handleDateChange(sd, ed) {
        if (!isSameDay(sd, this.props.appStartDate) || !isSameDay(ed, this.props.appDate)) {
            this.props.updateStartAndAppDate(sd, ed);
        }
    }
    changeView() {
        let newState = Object.assign({}, this.state.view);
        newState.loading = true;
        newState.loadingMsg = 'Changing View';
        this.setState({ view: newState }, () => {
            setTimeout(() => {
                let newState = Object.assign({}, this.state.view);
                newState.loading = false;
                newState.type = this.state.view.type === 1 ? 2 : 1;
                this.setState({
                    view: newState
                });
            });
        });
    }
    handleBillSelection(selectedItems) {
        if (selectedItems?.length !== 1)
            return;
        let currentSortOrder = this.NavTableRef?.current?.getSortOrder();
        const bill = selectedItems[0];
        if (bill) {
            let view = Object.assign({}, this.state.view);
            view.loading = true;
            view.loadingMsg = 'Loading';
            this.setState({ view }, () => setTimeout(() => {
                let view = Object.assign({}, this.state.view);
                view.loading = false;
                view.displayItemType = 2;
                view.displayBillInfo = {
                    billno: bill.Billno,
                    billdate: bill.Billdate,
                    billKey: bill.Key,
                    billCustomer: bill.cusname,
                    billSubcompany: bill.SubCompany,
                    payments: bill.payments,
                    billsSortOrder: currentSortOrder
                };
                this.setState({ view });
            }));
        }
    }
    unSelectBill() {
        let view = Object.assign({}, this.state.view);
        view.loadingMsg = 'Loading';
        view.loading = true;
        this.setState({ view }, () => setTimeout(() => {
            let view = Object.assign({}, this.state.view);
            let so = this.state.view.displayBillInfo?.billsSortOrder;
            view.loading = false;
            view.displayItemType = 1;
            view.defaultSelectedBillKey = this.state.view.displayBillInfo.billKey;
            view.displayBillInfo = {
                billCustomer: undefined,
                billdate: undefined,
                billno: undefined,
                billKey: undefined,
                billSubcompany: undefined,
                payments: undefined,
                billsSortOrder: undefined

            };
            this.setState({ view }, () => this.NavTableRef?.current?.setSortOrder(so));
        }));

    }
    /**
     * 
     * @param {Object} payment
     */
    async approveCD(payment, bill) {
        try {
            if (!payment) {
                return payment.billCollectionDiscountStatus;
            }
            if (payment.billCollectionDiscount === 0 || payment.billCollectionStatus === 0) {
                return payment.billCollectionDiscountStatus;
            }

            let status = !payment.billCollectionDiscountStatus;
            let resp = await Axios.get(`${this.props.apiHost}/collections/payments/${this.props.selectedCompany.key}/${payment.paymentNo}/${bill.Billno}/${bill.Billdate}/${status ? 'true' : 'false'}`, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${this.props.token}`
                }
            });
            payment.billCollectionDiscountStatus = status;
            let cd = payment.billCollectionDiscount * (status ? 1 : -1);
            if (bill) {
                bill.CashDiscountTemp_aa -= cd;
                bill.CashDiscountTemp += cd;
            }
            return status;
        } catch (err) {
            return payment.billCollectionDiscountStatus;
        }
    }
    keyPressHandler(e) {
        if (e.key.toLowerCase() === 'v' && e.altKey) {
            e.stopPropagation();
            this.changeView();
        }
        if (e.key.toLowerCase() === 'e' && e.altKey) {
            e.stopPropagation();
            this.export();
        }
        if (e.key.toLowerCase() === 'a' && e.altKey) {
            if (!this.props.adminLevel)
                return;
            e.stopPropagation();
            if (this.state.view.type === 1) {
                if (this.state.view.displayItemType === 2) {
                    if (this.NavTableRef.current) {
                        let selectedItems = this.NavTableRef.current.getSelectedItems;
                        for (let item of selectedItems) {
                            (async () => {
                                let ref = this.NavTableRef.current.getCellRef(item.paymentNo, "da")?.colInnerRef;
                                let loading = ref?.loadingRef;
                                let check = ref?.checkRef;
                                let cn = loading?.current?.classList.contains(this.styles.visible);
                                if (loading?.current && !cn)
                                    loading?.current.classList.add(styles['visible']);
                                try {
                                    let checkcn = check?.current?.classList.contains(this.styles.visible);
                                    if (check?.current && checkcn)
                                        check?.current.classList.remove(this.styles.visible);
                                    let bill = this.state.data.list.find(x => x.Key == this.state.view.displayBillInfo.billKey);
                                    let disApprovalStatus = await this.approveCD(item, bill);
                                    if (check?.current && disApprovalStatus)
                                        check?.current.classList.add(this.styles.visible);
                                }
                                catch (er) {
                                    console.log(er);
                                }
                                if (loading.current?.classList.contains(this.styles.visible))
                                    loading.current.classList.remove(this.styles['visible']);
                            })();
                        }
                    }
                } else if (this.state.view.displayItemType === 1) {
                    if (this.NavTableRef.current) {
                        try {
                            (async () => {
                                let selectedItems = this.NavTableRef.current.getSelectedItems;
                                if (selectedItems) {
                                    let billPromises = new Array(selectedItems?.length);
                                    let i = 0;
                                    for (let bill of selectedItems) {
                                        let prm = (async () => {
                                            let ref = this.NavTableRef.current.getCellRef(bill.Key, "loading")?.colInnerRef;
                                            let loading = ref?.loadingRef;
                                            let cn = loading?.current?.classList.contains(this.styles.visible);
                                            if (loading?.current && !cn) {
                                                loading?.current.classList.add(styles['visible']);
                                            }
                                            try {
                                                for (let payment of bill.payments) {
                                                        await this.approveCD(payment, bill)
                                                }
                                            }
                                            catch (er) {
                                                console.log(er);
                                            }
                                            if (loading.current?.classList.contains(this.styles.visible))
                                                loading.current.classList.remove(this.styles['visible']);
                                        })();
                                        billPromises[i] = prm;
                                        i++;
                                    }
                                    for (let prm of billPromises) {
                                        await prm;
                                    }
                                    if (selectedItems?.length >= 1)
                                        this.NavTableRef?.current?.reRenderSelection();
                                }
                            })();
                        }
                        catch (error) {
                            alert(error);
                        }

                    }
                }
            }
        }
        if (e.key.toLowerCase() === 'f5') {
            e.stopPropagation();
            if (this.subCompanyRef.current)
                this.subCompanyRef.current.DropDownOpen(true);
            e.preventDefault();
        }
        if (e.key.toLowerCase() === 'escape') {
            if (this.state.view.displayItemType === 2) {
                e.stopPropagation();
                this.unSelectBill();
            }
        }
        if (e.key.toLowerCase() === 'f4') {
            if (!this.props.adminLevel)
                return;
            if (this.state.view.displayItemType === 1 && this.NavTableRef.current) {
                e.stopPropagation();
                e.preventDefault();
                if (this.NavTableRef.current) {
                    let selectedItems = this.NavTableRef.current.getSelectedItems;
                    for (let item of selectedItems) {
                        (async () => {
                            let loading = this.NavTableRef.current.getCellRef(item.Key, "loading")?.colInnerRef?.loadingRef;
                            let ref = this.NavTableRef.current.getCellRef(item.Key, "tallyCheck")?.colInnerRef;
                            let check = ref?.tickRef;
                            let cn = loading?.current?.classList.contains(this.styles.visible);
                            if (loading?.current && !cn)
                                loading?.current.classList.add(styles['visible']);
                            try {
                                await this.tallyBill(item);
                                if (isSameDay(new Date(item.lastBalanceConfirmation), new Date()) && check?.current && !check?.current.classList.contains(this.styles.visible))
                                    check?.current.classList.add(this.styles.visible);
                            }
                            catch (er) {
                                console.log(er);
                            }
                            if (loading.current?.classList.contains(this.styles.visible))
                                loading.current.classList.remove(this.styles['visible']);

                        })();
                    }
                }
            }
        }
        if (e.key.toLowerCase() === 'f3') {
            if (this.NavTableRef.current && this.customerSearchRef.current) {
                e.stopPropagation();
                e.preventDefault();
                this.customerSearchRef.current.focus(true);
            }
        }
        if (e.key.toLowerCase() === 'delete') {
            e.preventDefault();
            if (this.state.view.displayItemType === 1 && this.NavTableRef.current) {
                let selectedKey = this.NavTableRef.current.selectedRowKey;
                let row = this.state.data.list.find(x => x.Key === selectedKey);
                if (row) {
                    this.openCloseBill(row.tableRowRef);
                    return;
                }
            }
        }
    }
    getNoData() {
        return (
            <div className={styles.empty}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>);
    }
    setNegativeBillsChange(c) {
        let newState = Object.assign({}, this.state.view);
        newState.loading = true;
        newState.loadingMsg = 'Working on your data';
        newState.negativeBills = c;
        this.setState({
            view: newState
        }, () => setTimeout(() => {
            let newState = Object.assign({}, this.state.view);
            newState.filteredList = this.filterData(c, this.state.view.manuallyClosedBills, this.state.view.customerFilter);
            newState.loading = false;
            this.setState({
                view: newState
            })
        }));
    }
    setManuallyClosedBillsChange(c) {
        let newState = Object.assign({}, this.state.view);
        newState.loading = true;
        newState.loadingMsg = 'Working on your data';
        newState.manuallyClosedBills = c;
        this.setState({
            view: newState
        }, () => setImmediate(() => {
            let newState = Object.assign({}, this.state.view);
            newState.filteredList = this.filterData(this.state.view.negativeBills, c, this.state.view.customerFilter);
            newState.loading = false;
            this.setState({
                view: newState
            })
        }));
    }
    setTalliedBillsChange(c) {
        let newState = Object.assign({}, this.state.view);
        newState.loading = true;
        newState.loadingMsg = 'Working on your data';
        newState.talliedBills = c;
        this.setState({
            view: newState
        }, () => setImmediate(() => {
            let newState = Object.assign({}, this.state.view);
            newState.filteredList = this.filterData(this.state.view.negativeBills, this.state.view.manuallyClosedBills, this.state.view.customerFilter, c);
            newState.loading = false;
            this.setState({
                view: newState
            })
        }));
    }

    CusFilter(text) {
        let view = Object.assign({}, this.state.view);
        view.customerFilter = text;
        view.loading = true;
        view.loadingMsg = "Searching for customer";
        this.setState({ view }, () => setTimeout(() => {
            let view = Object.assign({}, this.state.view);
            view.customerFilter = text;
            view.loading = false;
            view.filteredList = this.filterData(view.negativeBills, view.manuallyClosedBills, text);
            this.setState({ view });
        }));
    }
    getFilterBar() {
        return (
            <div className={classNames(styles.filterBar, styles[this.props.theme.className])}>
                <div className={classNames(styles.filterLabel, styles[this.props.theme.className])}>
                    <div className={classNames(styles.filterLabelInner, styles[this.props.theme.className])}>
                        <Icons.FilterOutlined />
                        <h3>Filter Options</h3>
                    </div>
                </div>
                <div className={classNames(styles.filterInner, styles[this.props.theme.className])}>
                    <div className={classNames(styles.filterItem, styles[this.props.theme.className])}>
                        <h4>Sub Company</h4>
                        <div className={classNames(styles.subcompanySelectInner, styles[this.props.theme.className])}>
                            <EAMSubcompanySelector defaultValue={this.state.data.subCompanyKey} onSelect={(s, i) => this.subCompanySelectionChange(s, i)} ref={this.subCompanyRef} isHeaderHidden={true} />
                        </div>
                    </div>
                    <div className={classNames(styles.filterItem, styles[this.props.theme.className])}>
                        <h4>Negative Balance Bills </h4>
                        <Switch disabled={!this.state.data.list} defaultChecked={this.state.view.negativeBills} onChange={(c, e) => this.setNegativeBillsChange(c)} />
                    </div>
                    <div className={classNames(styles.filterItem, styles[this.props.theme.className])}>
                        <h4>Manually Closed Bills </h4>
                        <Switch disabled={!this.state.data.list} defaultChecked={this.state.view.manuallyClosedBills} onChange={(c, e) => this.setManuallyClosedBillsChange(c)} />
                    </div>
                    <div className={classNames(styles.filterItem, styles[this.props.theme.className])}>
                        <h4>Confirmed Bills</h4>
                        <Switch disabled={!this.state.data.list} defaultChecked={this.state.view.talliedBills} onChange={(c, e) => this.setTalliedBillsChange(c)} />
                    </div>
                    <div className={classNames(styles.filterItem, styles[this.props.theme.className])}>
                        <h4>Customer </h4>
                        <Pi onKeyPress={(e) => { if (this.usedFunctionKeys.find(x => x === e.key)) e.preventDefault(); if (e.key === 'Escape') this.setFocus(); e.stopPropagation(); if (e.key === 'Escape') this.setFocus(); }} readOnly={this.state.view.displayItemType === 2} theme={this.props.theme.className} ref={this.customerSearchRef} width={450} mode={"Search"} enterbutton onEnter={(s) => this.CusFilter(s)} />
                    </div>
                </div>
            </div>
        );
    }
    export() {
        if (!this.state.view.filteredList || this.state.view.displayItemType !== 1)
            return;
        let data = this.state.view.filteredList.map(i => {
            return {
                Billno: i.Billno,
                date: format(new Date(i.Billdate), 'dd-MM-yyyy'),
                Customer: i.cusname,
                BillValue: i.TotalAmount,
                Collection: i.CollectionAmountTemp + i.CollectionAmount,
                CashDiscount: i.CashDiscountTemp,
                CDWithoutApproval: i.CashDiscountTemp_aa,
                Balance: (i.TotalAmount - (i.CollectionAmountTemp + i.CollectionAmount) - (i.CashDiscountTemp)),
                LastCollection: i.CollectionDate1 === null ? '' : format(new Date(i.CollectionDate1), 'dd-MM-yyyy'),
                lastCoB: i.lastBalanceConfirmationtext === null ? '' : format(new Date(i.lastBalanceConfirmationtext), 'dd-MM-yyyy')
            };
        });
        exportToCSV(data, this.scLable + ' - outstandings.xlsx', this.props.selectedCompany.companyName, this.props.appDate, this.scLable + " - Customer Bill wise Outstandings",
            [
                "Bill No",
                "Date",
                "Customer",
                "Bill Value",
                "Collection",
                "CD",
                "CD Pending Approval",
                "Balance",
                "Last Payment",
                "Last CoB"
            ]);
    }
    usedFunctionKeys = ['F3', 'F4', 'F5'];
    /**
     * @type {Array.<import('../titleBar').TilebarShortCut>}
     */
    shortCuts = [
        {
            Key: 'Alt+E',
            ActionName: 'Export',
            Action: () => this.export()
        },
        {
            Key: 'Alt+A',
            ActionName: '(Un)Approve CD',
            Action: () => undefined
        },
        {
            Key: 'Delete',
            ActionName: 'Open/Close Bill',
            Action: () => undefined
        }
    ];
    render() {
        return (
            <div tabIndex={0} className={classNames(styles.root, styles[this.props.theme.className])} onKeyDown={(e) => this.keyPressHandler(e)}>
                <BooksTitleBar shortCuts={this.state.view.displayItemType === 1 ? this.shortCuts : []} theme={this.props.theme.className} titleName="Outstandings Display" titleLeft={`Payments by All Users`}
                    titleRight=
                    {this.props.appStartDate.toDateString() === this.props.appDate.toDateString() ? `Date: ${format(new Date(this.props.appDate), 'dd-MM-yyyy')}` :
                        `Period: ${format(new Date(this.props.appStartDate), 'dd-MM-yyyy')} - ${format(new Date(this.props.appDate), 'dd-MM-yyyy')}`}
                />
                <div tabIndex={1} className={classNames(styles.display, styles[this.props.theme.className])}>
                    {this.getFilterBar()}
                    {this.getOutstandingsDisplay()}
                </div>
            </div>);
    }
}
const OutStandingsDisplay = connect(mapStateToProps, mapDispatchtoProps)(outStandingsDisplay);
export default OutStandingsDisplay;