import React from 'react';
import { Statistic } from 'antd';

class UpdatableStatistic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.initialValue,
            title: this.props.title
        };
        this.updateState = this.updateState.bind(this);
        this.getValue = this.getValue.bind(this);
        this.props.getUpdateStateCb(this.updateState);
        this.props.getValueCb(this.getValue);
    }
    updateState(arg) {
        if (!arg) {
            throw new Error('State is a compulsion');
            if (!arg.title || !arg.value) {
                throw new Error('titlea and value is a compulsion');
            }
        }
        this.setState({
            value: arg.value,
            title: arg.title
        });
    }
    getValue(){
        return this.state.value;
    }
    render() {
        return (< Statistic title={this.state.title} value={this.state.value} />);
    }
}

export default UpdatableStatistic;