export class darkTheme {
    constructor() {
        this._secondryTextColor = '#fff';
        this._backgroundColor = '#292929';
        this._primary1 = '#2e2e2e';
        this._primary2 = '#333333';
        this._primary3='#444444';
        this._primary4='#e6e4e4';
        this._type = 'darkTheme';
    }
    get secondryTextColor() {
        return this._secondryTextColor;
    }
    get backgroundColor() {
        return this._backgroundColor;
    }
    get primary1() {
        return this._primary1;
    }
    get className() {
        return 'darkTheme';
    }
}