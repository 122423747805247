const APIHOST = require('../redux/reducer.js').apiHost;
const Axios = require('axios').default;

export const deletePayment = (
    /**
     * @async
     * @param {string} compKey
     * @param {number} pno
     * @returns {Promise<void>}
     */
    async(compKey, pno, token) => {
        try {
            let resp = await Axios.delete(`${APIHOST}/collections/payment/${compKey}/${pno}`, {
                withCredentials: true,
                responseType: 'json',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return;
        } catch (err) {
            throw err;
        }

    });