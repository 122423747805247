import React from 'react'
import AppHeader from '../appHeader';
import errorIcon from '../../assets/error.png'
import './errorBoundry.css';
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    this.errorInfo = errorInfo;
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <AppHeader skeleton />
          <div className='floater'></div>
          <div className='error'>
            <img className='errorImage' src={errorIcon} />
            <h1>...Ooops</h1>
          </div>
        </div>);

    }

    return this.props.children;
  }
}