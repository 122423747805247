import classNames from 'classnames';
import React from 'react';
import styles from './titleBar.module.css';
/**
 * 
 * @typedef componentState
 * @prop {Array.<TilebarShortCut>} shortCuts
 * @prop {string} titleName
 * @prop {string} titleLeft
 * @prop {string} titleRight
 * @prop {string} theme
 * @extends {React.Component<componentState>}
 */
export class BooksTitleBar extends React.Component {
    constructor(props) {
        super(props);
    }
    getShortCuts() {
        return this.props.shortCuts.map(x => {
            if (!x)
                return;
            return (<div className={classNames(styles.titleShortcutItem, styles[this.props.theme])}>
                <a onClick={() => {
                    if (x.Action)
                        x.Action();
                }}>
                    <span className={classNames(styles.titleShortcutItemKey)}>{x.Key}</span>
                    <span className={classNames(styles.titleShortcutItemKeyColon)}>:</span>
                    <span className={classNames(styles.titleShortcutItemAction)}>{x.ActionName}</span>
                </a>
            </div>);
        });
    }
    render() {
        return (
            <div className={classNames(styles.tittlewrapper)}>
                <div className={classNames(styles.title, styles[this.props.theme])}>
                    <div className={classNames(styles.titlebar, styles[this.props.theme])}>
                        <span className={classNames(styles.titleLeft)}>{this.props.titleLeft}</span>
                        <h2>{this.props.titleName}</h2>
                        <span className={classNames(styles.titleRight)}>{this.props.titleRight}</span>
                    </div>
                    <div className={classNames(styles.titleShortcut, styles[this.props.theme])}>
                        {this.getShortCuts()}
                    </div>
                </div>
            </div>);
    }
}


/**
 * @typedef TilebarShortCut
 * @prop {String} Key
 * @prop {String} ActionName
 * @prop {()=>void} [Action]
 */