import React from 'react';
import styles from './styledButton.module.css';
import classNames from 'classnames';
import { Button } from 'antd';

/**
 * @typedef componentState
 * @prop {string} [value]
 * @prop {string} theme
 * @prop {string} mode
 * @prop {boolean} [disabled]
 * @prop {number} [height]
 * @prop {number|String} [width]
 * @prop {number} [tabIndex]
 * @prop {boolean} [borderLess]
 * @prop {import('react').ForwardRefExoticComponent} [icon]
 * @prop {any} [referenceValue]
 * @prop {()=>void} [onClick]
 * @extends {React.Component<componentState>}
 */
class StyledBtn extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.manualStateSet = this.manualStateSet.bind(this);
        this.state = { loading: false, icon: this.props.icon };
        this.handleHotKey = this.handleHotKey.bind(this);
    }
    handleHotKey(e) {
    }
    async onClick() {
        if (this.props.onClick && !this.props.disabled) {
            this.setState({ loading: true });
            try {
                await this.props.onClick(this.props.referenceValue);
            } finally {
                this.setState({ loading: false });
            }
        }
    }
    /**
    * @param {import('react').ForwardRefExoticComponent} _icon 
    */
    changeIcon(_icon) {
        this.setState({
            icon: _icon
        });
    }
    /**
     * 
     * @param {boolean} loading 
     */
    manualStateSet(loading) {
        this.setState({ loading });
    }
    render() {
        let value = this.props.value;
        return (
            <div className={classNames(styles.styledButton, styles[this.props.theme], styles[this.props.mode], this.props.borderLess ? styles.borderLess : undefined)}>
                <Button style={{
                    height: this.props.height || "100%",
                    width: this.props.width,
                    padding: this.state.loading ? 0 : undefined
                }} tabIndex={this.props.tabIndex} icon={this.state.icon ? <this.state.icon /> : undefined} loading={this.state.loading} onClick={this.onClick} disabled={this.props.disabled} >{value}</Button>
            </div>);
    }
}
export default StyledBtn;
