// @ts-check
import React from 'react';
import styles from './e404.module.css';
import { connect } from 'react-redux';
import classNames from 'classnames';
const mapPropsfromState = state => {
    return {
        theme: state.appState.theme,
    };
};
class e404 extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (<div className={classNames(styles.E404, 'bold-large', styles[this.props.theme.className])}>
            <h4 className={styles.E404h1}>404</h4>
                <div className={classNames(styles.E404div, styles[this.props.theme.className])}>
                    <h4 className={styles.E404h4}>Not Found</h4>
                </div>
        </div>);

    }
}
const E404 = connect(mapPropsfromState)(e404);
export default E404;